export interface Paginated<T> {
  items: T[];
  total: number;
}

interface Composer {
  id: number;
  name: String;
  year: String;
}

export interface Score {
  id: number;
  createdAt: string;
  title: string;
  subtitle: string;
  info: string;
  orch: string;
  premier: string;
  publisher: string;
  len: string;
  popularity: number | null;
  composerNames: string;
  composers: Composer[];
}

export interface User {
  id: number;
  createdAt: string;
  updatedAt: string;
  account: string;
  role: Role;
  name: string;
  phone: string;
  organization: string;
}

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface Form {
  id: number;
  user: User;
  formscores: Formscore[];
  edits: Edit[];
  estimates: Estimate[];
  confirmations: Confirmation[];

  createdAt: string;
  scoreCount: number;
  history: FormHistory[];
  state: FormState;
  newCount: number;
  finished: boolean;
  editDate: string;
  organizationName: string;
  applicantName: string;
  applicantPhone: string;

  businessNumber: string;
  adress: string;
  adressDetail: string;
  managerName: string;
  managerPhone: string;
  wantedDate: string;
}

export interface Edit {
  id: number;
  form: Form;
  type: FormscoreType;
  text: string;
  agreed: boolean;
}

export interface Estimate {
  id: number;
  form: Form;
  type: FormscoreType;
  key: string;
  heavy: boolean;
  contract: boolean;
  signature: string;
}

export interface Confirmation {
  id: number;
  form: Form;
  type: FormscoreType;
  key: string;
}

export enum FormHistory {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  EDITTED = 'EDITTED',
  ESTIMATE_ARRIVED = 'ESTIMATE_ARRIVED',
  CONFIRMATION_ARRIVED = 'CONFIRMATION_ARRIVED',
}

export enum FormState {
  BEFORE_CONTRACT = 'BEFORE_CONTRACT',
  DURING_CONTRACT = 'DURING_CONTRACT',
  FINISHED = 'FINISHED',
  EDITTED = 'EDITTED',
  DRAFT = 'DRAFT',
}

export enum ContractStatus {
  RECEIPT_BEFORE = '계약전',
  RECEIPT_ONGOING = '계약중',
  RECEIPT_AFTER = '완료',
  EDIT_CHECKED = '수정신청서',
}

export interface Formscore {
  id: number;
  countId: number;
  type: FormscoreType;
  savedPage: number;

  form: Form;

  composer: string;
  title: string;
  publisher: string;
  remarks: string;
  length: string;
  licenseType: LicenseType[];

  concertInfo: JSON;
  concertLicense: boolean;
  concertLicenseInfo: JSON;
  streamInfo: JSON;
  paperInfo: JSON;
  publishInfo: JSON;
  filmInfo: JSON;
  editInfo: JSON;
  otherInfo: JSON;
}

export enum FormscoreType {
  SHEET = 'SHEET',
  RENTAL = 'RENTAL',
  SALE = 'SALE',
  RENTALSALE = 'RENTALSALE',
  LICENSING = 'LICENSING',
}

export const formscoreTypeToString = (type: FormscoreType) => {
  switch (type) {
    case FormscoreType.SHEET:
      return '악보';
    case FormscoreType.RENTAL:
      return '대여용 악보';
    case FormscoreType.SALE:
      return '판매용 악보';
    case FormscoreType.RENTALSALE:
      return '판매/대여용 악보';
    case FormscoreType.LICENSING:
      return '라이센스';
  }
};

export enum LicenseType {
  INTERNET = 'INTERNET',
  STAGE = 'STAGE',
  PAPER = 'PAPER',
  PUBLISH = 'PUBLISH',
  FILM = 'FILM',
  ARRANGE = 'ARRANGE',
  OTHER = 'OTHER',
}

export const licenseTypeToRoute = (type: LicenseType) => {
  switch (type) {
    case LicenseType.INTERNET:
      return 'internet';
    case LicenseType.STAGE:
      return 'performance';
    case LicenseType.PAPER:
      return 'thesis';
    case LicenseType.PUBLISH:
      return 'publish';
    case LicenseType.FILM:
      return 'media';
    case LicenseType.ARRANGE:
      return 'arrangement';
    case LicenseType.OTHER:
      return 'others';
  }
};

export interface License {
  id: number;
  concerttype: string;
  licenseQuantity: string;
  seatsQuantity: string;
  isPaid: boolean;
  ticketPrice: number;
  salesRate: string;
  note: string;
  consent: boolean;
  playAt: string;
}

export interface Sheet {
  id: number;
  songwriter: string;
  publisher: string;
  Remarks: string;
  time: string;
  type: string;
  playAt: string;
  playlocation: string;
  conductor: string;
  request: string;
  totalSheet: string;
  totalPlay: string;
  recording: string;
  tvOnAir: string;
  radio: string;
  onlineOnAir: string;
  newSheet: string;
  express: string;
  licenseData: License;
}

export enum AlertType {
  EDITTED = 'EDITTED',
  ESTIMATE = 'ESTIMATE',
  CONFIRMATION = 'CONFIRMATION',
}

export const alertTypeToString = (type: AlertType) => {
  switch (type) {
    case AlertType.EDITTED:
      return '수정된 신청서가 도착하였습니다.';
    case AlertType.ESTIMATE:
      return '새로운 견적서가 도착했습니다.';
    case AlertType.CONFIRMATION:
      return '새로운 주문확인서가 도착했습니다.';
    default:
      throw new Error('Invalid AlertType');
  }
};

export interface Alert {
  id: number;
  form?: Form;
  type: AlertType;
  checked: boolean;
  createdAt: string;
}

export enum BroadcastType {
  NONE = 'NONE',
  LIVE = 'LIVE',
  RECORD = 'RECORD',
}

export enum StreamType {
  NONE = 'NONE',
  LIVE = 'LIVE',
  REPLAY = 'REPLAY',
}

export enum BooleanString {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export enum ConcertType {
  CONCERT = 'CONCERT',
  OPERA = 'OPERA',
  BALLET = 'BALLET',
  MUSICAL = 'MUSICAL',
}

export enum LiveRecordType {
  LIVE = 'LIVE',
  RECORD = 'RECORD',
}

export enum StreamRegion {
  WORLD = 'WORLD',
  KOREA = 'KOREA',
  OTHER = 'OTHER',
}

export const streamRegionToString = (region: StreamRegion) => {
  switch (region) {
    case StreamRegion.WORLD:
      return '전세계';
    case StreamRegion.KOREA:
      return '한국';
    case StreamRegion.OTHER:
      return '그외';
  }
};

export enum StreamPeriod {
  LIVE = 'LIVE',
  DAY = 'DAY',
  MONTH = 'MONTH',
  THREE_MONTH = 'THREE_MONTH',
  YEAR = 'YEAR',
  TWO_YEAR = 'TWO_YEAR',
  FIVE_YEAR = 'FIVE_YEAR',
  TEN_YEAR = 'TEN_YEAR',
}

export const streamPeriodToString = (period: StreamPeriod) => {
  switch (period) {
    case StreamPeriod.LIVE:
      return 'Live';
    case StreamPeriod.DAY:
      return '1일';
    case StreamPeriod.MONTH:
      return '1개월';
    case StreamPeriod.THREE_MONTH:
      return '3개월';
    case StreamPeriod.YEAR:
      return '12개월';
    case StreamPeriod.TWO_YEAR:
      return '2년';
    case StreamPeriod.FIVE_YEAR:
      return '5년';
    case StreamPeriod.TEN_YEAR:
      return '10년';
  }
};

export enum StreamAmount {
  WHOLE = 'WHOLE',
  PART = 'PART',
}

export enum StreamEdit {
  NONE = 'NONE',
  SOME = 'SOME',
  MUCH = 'MUCH',
}

export enum PublishType {
  BOOK = 'BOOK',
  ONLINE = 'ONLINE',
}

export const publishTypeToString = (type: PublishType) => {
  switch (type) {
    case PublishType.BOOK:
      return '책';
    case PublishType.ONLINE:
      return '온라인';
  }
};

export enum EditPurpose {
  CONCERT = 'CONCERT',
  PUBLISH = 'PUBLISH',
  OTHER = 'OTHER',
}
