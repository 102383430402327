import React, { FC } from 'react';
import { range } from '../utils';
import { Icon } from './Icon';

interface FrontPaginationProps {
  page: number;
  count: number;
  onClick: (page: number) => void;
  className?: string;
}

interface PaginationButtonProps {
  page?: number;
  onClick?: () => void;
  current?: boolean;
}

const PaginationButton: FC<PaginationButtonProps> = ({
  page = 0,
  onClick = () => {},
  current = false,
}) => {
  return (
    <button className="relative">
      {current ? (
        <>
          <div className="absolute h-0.5 w-full bg-gray-900" />
          <p className="wh-10 grid place-items-end justify-center">{page}</p>
        </>
      ) : (
        <p
          className="text-gray-500 wh-10 grid place-items-end justify-center"
          onClick={onClick}
        >
          {page ? page : '···'}
        </p>
      )}
    </button>
  );
};

export const FrontPagination: FC<FrontPaginationProps> = ({
  page,
  count,
  onClick,
  className = '',
}) => {
  var list;
  if (count <= 7) list = range(count, 1);
  else if (page <= 2 || page > count - 2)
    list = [1, 2, 3, 0, count - 2, count - 1, count];
  else if (page === 3) list = [1, 2, 3, 4, 0, count - 1, count];
  else if (page === count - 2)
    list = [1, 2, 0, count - 3, count - 2, count - 1, count];
  else list = [1, 0, page - 1, page, page + 1, 0, count];
  return (
    <div className={`flex justify-between ${className}`}>
      <button
        className={`flex space-x-2 pt-4 w-24 ${
          page === 1
            ? 'text-gray-400 border-gray-400'
            : 'text-black border-black'
        }`}
        onClick={() => page !== 1 && onClick(page - 1)}
      >
        <Icon.ArrowLeft />
        <p>Previous</p>
      </button>

      <div className="flex space-x-4 text-center">
        {list.map((i, idx) => (
          <PaginationButton
            page={i}
            onClick={() => i && onClick(i)}
            current={i === page}
            key={idx}
          />
        ))}
      </div>

      <button
        className={`flex space-x-2 pt-4 w-24 justify-end ${
          page === count
            ? 'text-gray-400 border-gray-400'
            : 'text-black border-black'
        }`}
        onClick={() => page !== count && onClick(page + 1)}
      >
        <p>Next</p>
        <Icon.ArrowRight />
      </button>
    </div>
  );
};
