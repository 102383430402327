import React from 'react';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';

export const SheetDetail = () => {
  return (
    <div className="max-w-screen-md">
      <AdminH1>악보정보</AdminH1>

      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:px-8">
          <TextField label="작곡가" />
          <TextField label="출판사" />
          <TextField label="곡명" />
          <TextField label="에디터및 arranger" />
          <TextField label="작품길이" />
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="취소하기"
            to={`/admin/sheet`}
            className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
          />
          <Button
            text="저장"
            className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
          />
        </div>
      </Card>
    </div>
  );
};
