import React, {
  Children,
  cloneElement,
  forwardRef,
  InputHTMLAttributes,
  isValidElement,
} from 'react';
import { useId } from 'react-id-generator';
import { Label } from './Label';
import { Radio } from './Radio';

export interface RadioGroupProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
  labelClassname?: string;
  helper?: string;
  setName?: boolean;
}

/**
 * @example
 * <RadioGroup onChange={(e) => console.log(e.target.value)}>
 *   <Radio label="Radio 1" value="1" />
 *   <Radio label="Radio 2" value="2" />
 *   <Radio label="Radio 3" value="3" />
 * </RadioGroup>
 */
export const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  (
    {
      label,
      labelClassname,
      helper,
      setName = true,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const [name] = useId(1, 'radiogroup');

    return (
      <div className="space-y-1">
        <Label text={label} className={labelClassname} />

        <div className={`${className}`}>
          {Children.map(children, (child) =>
            isValidElement(child) && child.type === Radio
              ? cloneElement(child, setName ? { name, ...props } : props)
              : child
          )}
        </div>
        {helper && <p className="text-sm text-error">{helper}</p>}
      </div>
    );
  }
);
