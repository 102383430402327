import React, { forwardRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Checkbox, CheckboxProps } from './Checkbox';

export interface CheckboxUseFormProps extends CheckboxProps {
  value: any;
  hookForm: UseFormReturn<any>;
  name: string;
}

export const CheckboxUseForm = forwardRef<
  HTMLInputElement,
  CheckboxUseFormProps
>(({ value, hookForm: { watch, setValue }, name, ...props }, ref) => {
  const field = watch(name);
  return (
    <Checkbox
      checked={field.includes(value)}
      onChange={(e) => {
        if (e.target.checked)
          setValue(name, field.concat([value]), { shouldValidate: true });
        else
          setValue(
            name,
            field.filter((v: any) => v !== value),
            { shouldValidate: true }
          );
      }}
      {...props}
      ref={ref}
    />
  );
});
