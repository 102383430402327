import React, { useCallback, useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { BottomBar } from '../../components/BottomBar';
import { Button } from '../../components/Button';
import { CheckboxUseForm } from '../../components/CheckboxUseForm';
import { FormTitle } from '../../components/FormTitle';
import { H5 } from '../../components/H5';
import { Label } from '../../components/Label';
import { SearchSongPopUp } from '../../components/SearchSongPopUp';
import { SongPopUp } from '../../components/SongPopUp';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import {
  Formscore,
  FormState,
  LicenseType,
  licenseTypeToRoute,
  Score,
} from '../../types';

interface FormValues {
  composer: string;
  title: string;
  publisher: string;
  remarks: string;
  length: string;
  licenseType: LicenseType[];
}

export const LicensePage = () => {
  const { push } = useHistory();
  const params: any = useParams();
  const [songPopUp, setSongPopUp] = useState(false);
  const [searchPopUp, setSearchPopUp] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [countId, setCountId] = useState<number>();
  const { data, isError } = useQuery<Formscore>(
    `formscores/${params.scoreId}`,
    fetcher
  );

  const form = useForm<FormValues>({ defaultValues: { licenseType: [] } });
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    clearErrors,
  } = form;
  const licenseType = watch('licenseType');
  useEffect(() => {
    register('licenseType', {
      validate: () => {
        const licenseType = watch('licenseType');
        if (licenseType.length > 2) return '2개까지만 선택해주세요.';
        if (licenseType.length === 0) return '1개 이상 선택해주세요.';
        return true;
      },
    });
  }, [register, watch]);

  const setFormscore = useCallback(
    (data: Formscore) => {
      const keys: (keyof FormValues)[] = [
        'composer',
        'title',
        'publisher',
        'remarks',
        'length',
      ];
      keys.forEach((key: keyof FormValues) => setValue(key, data[key]));
      setValue('licenseType', data.licenseType ?? []);
    },
    [setValue]
  );

  const copyFormscore = useCallback(
    (id: number) =>
      api
        .put('/formscores/copy', { to: params.scoreId, from: id })
        .then(({ data }) => {
          setFormscore(data);
          clearErrors();
        }),
    [setFormscore, params, clearErrors]
  );

  useEffect(() => {
    if (data) {
      if (data.form.id !== +params.formId) setRedirect('/form-status');
      else if (data.form.state !== FormState.DRAFT)
        setRedirect(`/forms/${params.formId}`);
      else {
        setFormscore(data);
        setCountId(data.countId);
      }
    }
  }, [data, setFormscore, params.formId]);

  useEffect(() => {
    if (isError) setRedirect('/form-status');
  }, [isError]);

  const submitData = (data: any) =>
    api.patch(`/formscores/${params.scoreId}`, data);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <SongPopUp
        open={songPopUp}
        onSelect={copyFormscore}
        onClose={() => setSongPopUp(false)}
      />

      <SearchSongPopUp
        open={searchPopUp}
        onClose={() => setSearchPopUp(false)}
        onChoose={(score: Score) => {
          setValue(
            'composer',
            score.composers.map((composer) => composer.name).join(', ')
          );
          setValue('title', score.title);
          setValue('publisher', score.publisher);
          setValue('length', score.len);
          clearErrors();
        }}
      />

      <div className="max-w-container white-card my-10">
        <FormTitle count={countId} type="라이센스" />

        <div className="flex justify-between pb-6">
          <div>
            <H5>사용허가 받으실 정보를 입력해주세요!</H5>
            <p className="text-12 text-brand-1">
              직접 입력을 하거나 우측의 검색하기 버튼으로 원하는 곡을
              검색해보세요.
            </p>
          </div>

          <div className="space-x-4">
            <Button
              onClick={() => setSongPopUp(true)}
              text="이전 신청 곡 불러오기"
              className="filled-gray-800"
            />
            <Button
              onClick={() => setSearchPopUp(true)}
              text="곡 검색하기"
              className="outlined-gray-800"
            />
          </div>
        </div>

        <form className="space-y-6">
          <TextField
            label="작곡가 (필수)"
            placeholder="작곡가를 입력해주세요."
            helper={errors?.composer?.message}
            {...register('composer', { required: '작곡가를 입력해주세요.' })}
          />
          <TextField
            label="곡명 (필수)"
            placeholder="곡명을 입력해주세요."
            helper={errors?.title?.message}
            {...register('title', { required: '곡명을 입력해주세요.' })}
          />
          <TextField
            label="출판사 및 권리사 / Editor 및 Arranger"
            placeholder="출판사 / Editor 및 Arranger을 입력해주세요."
            helper={errors?.publisher?.message}
            {...register('publisher')}
          />
          <div className="grid grid-cols-2 gap-x-8">
            <TextField
              label="Remarks"
              placeholder="버전, 작곡년도, 타입등을 입력해주세요."
              helper={errors?.remarks?.message}
              {...register('remarks')}
            />
            <TextField
              label="작품 길이"
              placeholder="작품 길이를 입력해주세요."
              helper={errors?.length?.message}
              {...register('length')}
            />
          </div>

          <div className="space-y-2 pb-24">
            <Label
              text="라이센스 타입선택 (2개까지 가능)"
              className="font-bold text-18"
            />
            <div className="flex flex-wrap space-x-8">
              {[
                ['인터넷 스트리밍: Youtube, Naver TV 등', LicenseType.INTERNET],
                ['무대공연', LicenseType.STAGE],
                ['논문 및 학술지', LicenseType.PAPER],
                ['출판', LicenseType.PUBLISH],
                ['광고 및 영화', LicenseType.FILM],
                ['편곡', LicenseType.ARRANGE],
                ['기타', LicenseType.OTHER],
              ].map(([label, type]) => (
                <CheckboxUseForm
                  key={type}
                  label={label}
                  value={type}
                  hookForm={form}
                  name="licenseType"
                />
              ))}
            </div>
            {errors.licenseType && (
              <p className="text-sm text-error">
                {(errors.licenseType as unknown as FieldError).message}
              </p>
            )}
          </div>
        </form>
      </div>

      <BottomBar
        title="STEP 1. 악보선택"
        progress={`${
          licenseType.length === 0
            ? '100%'
            : licenseType.length === 1
            ? '50%'
            : '33%'
        }`}
        onClickNext={handleSubmit((data) => {
          submitData({
            ...data,
            savedPage: licenseType.length === 0 ? 4 : 2,
          }).then(() =>
            push(
              licenseType.length === 0
                ? `/create/${params.formId}/form-list`
                : `/create/${params.formId}/form/${
                    params.scoreId
                  }/type/license/${licenseTypeToRoute(licenseType[0])}`
            )
          );
        })}
        onClickPrevious={() => {
          submitData({ ...getValues(), savedPage: 0 }).then(() =>
            push(`/create/${params.formId}/form/${params.scoreId}/type`)
          );
        }}
      />
    </>
  );
};
