import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Button } from '../components/Button';
import { H2 } from '../components/H2';
import { PhoneNumber } from '../components/PhoneNumber';
import { TextField } from '../components/TextField';
import { api } from '../plugins/axios';

interface FormValues {
  name: string;
  phone: string;
  organization: string;
}

export const SignupPage = () => {
  const { push } = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { phone: '--' } });

  useEffect(() => {
    register('phone', {
      required: '핸드폰 번호를 입력해주세요.',
      pattern: /\d{3}-\d{4}-\d{4}/,
    });
  }, [register]);

  const setPhone = useCallback(
    (v: string) => {
      setValue('phone', v, { shouldValidate: true });
    },
    [setValue]
  );
  const phone = watch('phone');

  return (
    <>
      <div className="bg-gray-100 h-screen-5 pt-20">
        <div className="max-w-container bg-white rounded-md">
          <div className="max-w-slim py-6">
            <H2 className="text-center">회원신청</H2>

            <form
              className="p-4 flex flex-col space-y-6"
              onSubmit={handleSubmit((data) => {
                api.post('/auth/request/', data);
                push('/signup/success');
              })}
            >
              <TextField
                label="*회원명 (단체명)"
                placeholder="회원명 또는 단체명을 입력해주세요."
                helper={errors.name?.message}
                {...register('name', {
                  required: '회원명 또는 단체명을 입력해주세요.',
                })}
              />

              <PhoneNumber
                label="*신청자 핸드폰 번호"
                value={phone}
                setValue={setPhone}
                helper={
                  errors.phone?.message === ''
                    ? '올바른 형식을 입력해주세요'
                    : errors.phone?.message
                }
              />

              <TextField
                label="*소속 단체명 / 학교명"
                placeholder="소속 단체명 혹은 학교명을 입력해주세요."
                helper={errors.organization?.message}
                {...register('organization', {
                  required: '소속 단체명 혹은 학교명을 입력해주세요.',
                })}
              />

              <div className="flex flex-col pt-14">
                <Button
                  type="submit"
                  text="회원 신청하기"
                  className="filled-brand-1"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
