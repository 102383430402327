import React, { forwardRef, InputHTMLAttributes, useState } from 'react';
import { Icon } from './Icon';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch?: (keyword: string) => void;
  defaultValue?: string;
}

export const Search = forwardRef<HTMLInputElement, SearchProps>(
  (
    { children, className, onSearch = () => {}, defaultValue = '', ...props },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue);

    return (
      <form
        className={` w-full border flex items-center px-6 h-10 rounded-md bg-white focus-within:border-brand-1 ${className}`}
        onSubmit={(e) => {
          e.preventDefault();
          onSearch(value);
        }}
      >
        <input
          className="flex-1 text-sm placeholder-gray-400"
          value={value}
          ref={ref}
          onChange={(e) => setValue(e.target.value)}
          {...props}
        />
        <button className="flex justify-center items-center wh-10">
          <Icon.Search
            className="text-gray-500 stroke-current"
            onClick={() => onSearch(value)}
          />
        </button>
      </form>
    );
  }
);
