import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { LoginPage } from './LoginPage';
import { HomePage } from './HomePage';
import { SignupPage } from './SignupPage';
import { TopNavBar } from '../components/TopNavBar';
import { SignupSuccessPage } from './SignupSuccessPage';
import { FormStatusPage } from './Form Status/FormStatusPage';
import { FormInfoPage } from './Form/FormInfoPage';
import { FormListPage } from './Form/FormListPage';
import { ChooseTypePage } from './Form/ChooseTypePage';
import { FormDetailPage } from './Form Status/FormDetailPage';
import { ScoreOnePage } from './Score/ScoreOnePage';
import { ScoreTwoPage } from './Score/ScoreTwoPage';
import { ScoreThreePage } from './Score/ScoreThreePage';
import { LicensePage } from './License/LicensePage';
import { LicenseInternetPage } from './License/LicenseInternetPage';
import { LicenseThesisPage } from './License/LicenseThesisPage';
import { LicensePublishPage } from './License/LicensePublishPage';
import { LicenseMediaPage } from './License/LicenseMediaPage';
import { LicenseArrangementPage } from './License/LicenseArrangementPage';
import { LicenseOthersPage } from './License/LicenseOthersPage';
import { LicensePerformancePage } from './License/LicensePerformancePage';
import { ChannelTokButton } from '../components/ChannelTokButton';
import { AuthRoute } from '../components/AuthRoute';
import { FormShippingPage } from './Form/FormShippingPage';

export const Router = () => {
  return (
    <>
      <ChannelTokButton />

      <div className="min-h-screen flex flex-col">
        <TopNavBar />
        <main className={`flex-1`}>
          <Switch>
            <AuthRoute
              path="/create/:formId/form/:scoreId/type/license/performance"
              component={LicensePerformancePage}
            />
            <AuthRoute
              path="/create/:formId/form/:scoreId/type/license/others"
              component={LicenseOthersPage}
            />
            <AuthRoute
              path="/create/:formId/form/:scoreId/type/license/arrangement"
              component={LicenseArrangementPage}
            />
            <AuthRoute
              path="/create/:formId/form/:scoreId/type/license/media"
              component={LicenseMediaPage}
            />
            <AuthRoute
              path="/create/:formId/form/:scoreId/type/license/publish"
              component={LicensePublishPage}
            />
            <AuthRoute
              path="/create/:formId/form/:scoreId/type/license/thesis"
              component={LicenseThesisPage}
            />
            <AuthRoute
              path="/create/:formId/form/:scoreId/type/license/internet"
              component={LicenseInternetPage}
            />
            <AuthRoute
              path="/create/:formId/form/:scoreId/type/license"
              component={LicensePage}
            />

            <AuthRoute
              path="/create/:formId/form/:scoreId/type/score/3"
              component={ScoreThreePage}
            />
            <AuthRoute
              path="/create/:formId/form/:scoreId/type/score/2"
              component={ScoreTwoPage}
            />
            <AuthRoute
              path="/create/:formId/form/:scoreId/type/score/1"
              component={ScoreOnePage}
            />
            <AuthRoute
              path="/create/:formId/form/:scoreId/type"
              component={ChooseTypePage}
            />
            <AuthRoute path="/form/:id" component={FormDetailPage} />
            <AuthRoute path="/create/:id/form-list" component={FormListPage} />
            <AuthRoute path="/create/:id/form-info" component={FormInfoPage} />
            <AuthRoute
              path="/create/:id/shipping"
              component={FormShippingPage}
            />
            <AuthRoute path="/form-status" component={FormStatusPage} />
            <Route path="/signup/success" component={SignupSuccessPage} />
            <AuthRoute path="/signup" component={SignupPage} guestOnly />
            <Route path="/login" component={LoginPage} />

            <Route path="/" component={HomePage} exact />
            <Redirect from="/" to="/" />
          </Switch>
        </main>

        {/* {(pathname.startsWith('/login') ||
          pathname.startsWith('/signup') ||
          pathname.startsWith('/signup/success')) && <Footer />} */}
      </div>
    </>
  );
};
