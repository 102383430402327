import React, { useEffect } from 'react';
import { H3 } from './H3';
import { Icon } from './Icon';
import { Confirmation, FormscoreType } from '../types';
import { useQuery } from 'react-query';
import { fetcher, useQueriesConsistent } from '../plugins/react-query';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { Pdf } from './Pdf';
import { api } from '../plugins/axios';

const types = [
  { text: '대여용 악보 주문확인서', type: FormscoreType.RENTAL },
  { text: '판매용 악보 주문확인서', type: FormscoreType.SALE },
  { text: '라이센스 주문확인서', type: FormscoreType.LICENSING },
];

export const ConfirmationSheet = () => {
  const params: any = useParams();
  const { data: confirmationData } = useQuery<Confirmation[]>(
    `confirmations/form/${params.id}`,
    fetcher
  );
  const confirmations = confirmationData ?? [];
  const urlData = useQueriesConsistent<string>(
    confirmations.map(
      (confirmation) => `/s3/presignedUrl?key=${confirmation.key}`
    )
  );
  const urls = urlData.map(({ data }) => data ?? '');

  useEffect(() => {
    api.patch(`forms/${params.id}`, { newCount: 0 });
  }, [params.id]);

  const filterConfirmation = (
    confirmations: Confirmation[],
    urls: string[],
    type: FormscoreType
  ) => {
    const idx = confirmations.findIndex(
      (confirmation) => confirmation.type === type
    );
    return { confirmation: confirmations[idx], url: urls[idx] };
  };

  return (
    <>
      <div className="grid place-content-end pt-6">
        <button
          className="flex items-center bg-gray-100 px-8 py-4 rounded-md space-x-2 mb-4"
          onClick={() => urls.forEach((url) => saveAs(url))}
        >
          <span>전체 주문확인서 PDF파일 다운</span> <Icon.Download />
        </button>
      </div>

      {types.map((type) => {
        const { confirmation, url } = filterConfirmation(
          confirmations,
          urls,
          type.type
        );
        return confirmation
          ? [
              <H3 key={`${type.text} h3`} className="mt-6">
                {type.text}
              </H3>,
              <Pdf key={`${type.text} pdf`} file={url} className="w-full" />,
            ]
          : null;
      })}
    </>
  );
};
