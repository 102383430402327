import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useParams } from 'react-router-dom';
import { FormTitle } from '../../components/FormTitle';
import { H5 } from '../../components/H5';
import { TextArea } from '../../components/TextArea';
import { api } from '../../plugins/axios';
import { Formscore, LicenseType } from '../../types';
import { LicenseBottomBar, useLicenseData } from './licenseComponents';

interface FormValues {
  remarks: string;
}

export const LicenseOthersPage = () => {
  const params: any = useParams();
  const form = useForm<FormValues>();
  const {
    register,
    setValue,
    formState: { errors },
  } = form;

  const submitData = (data: any, savedPage: number) =>
    api.patch(`/formscores/${params.scoreId}`, {
      otherInfo: JSON.stringify(data),
      savedPage,
    });

  const setValues = useCallback(
    (data: Formscore) => {
      const json = data.otherInfo;
      if (json) {
        const obj = JSON.parse(json as any);
        setValue('remarks', obj.remarks);
      }
    },
    [setValue]
  );
  const { redirect, countId, licenseType } = useLicenseData(setValues);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <div className="max-w-container white-card mt-10 mb-20">
        <FormTitle count={countId} type="라이센스" />

        <form className="space-y-4">
          <H5 className="pb-6">기타정보를 입력해주세요!</H5>

          <TextArea
            label="기타사항"
            placeholder="기타사항을 입력해주세요."
            className="h-56"
            helper={errors.remarks?.message}
            {...register('remarks')}
          />
        </form>
      </div>

      <LicenseBottomBar
        form={form}
        type={LicenseType.OTHER}
        licenseType={licenseType}
        title="기타"
        submitData={submitData}
      />
    </>
  );
};
