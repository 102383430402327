import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { ConfirmationSheet } from '../../components/ConfirmationSheet';
import { EstimateSheet } from '../../components/EstimateSheet';
import { Footer } from '../../components/Footer';
import { FormCard } from '../../components/FormCard';
import { H3 } from '../../components/H3';
import { H5 } from '../../components/H5';
import { PopUp } from '../../components/PopUp';
import { Section } from '../../components/Section';
import { Tab } from '../../components/Tab';
import { TextArea } from '../../components/TextArea';
import { api } from '../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Edit, Form, FormHistory, Formscore, FormscoreType } from '../../types';

export const FormDetailPage = () => {
  const params: any = useParams();
  const { data, isFetched } = useQuery<Form>(`/forms/${params.id}`, fetcher);
  const [tab1, setTab1] = useState(0);
  const [tab2, setTab2] = useState(0);
  const [popup, setPopup] = useState(false);
  const [showedPopup, setShowedPopup] = useState(false);

  const tab1s = ['악보', '라이센스'];
  if (isFetched) {
    if (data!.history.includes(FormHistory.ESTIMATE_ARRIVED))
      tab1s.push('견적서');
    if (data!.history.includes(FormHistory.CONFIRMATION_ARRIVED))
      tab1s.push('주문확인서');
  }
  const tab2s = ['판매', '대여'];

  const sale = data
    ? data.formscores.filter((v) =>
        [FormscoreType.SALE, FormscoreType.RENTALSALE].includes(v.type)
      )
    : [];
  const rental = data
    ? data.formscores.filter((v) =>
        [FormscoreType.RENTAL, FormscoreType.RENTALSALE].includes(v.type)
      )
    : [];
  const license = data
    ? data.formscores.filter((v) => v.type === FormscoreType.LICENSING)
    : [];
  const edits = data?.edits ?? [];

  useEffect(() => {
    if (
      data?.edits &&
      !showedPopup &&
      data.edits.some((edit) => !edit.agreed)
    ) {
      setPopup(true);
      setShowedPopup(true);
    }
  }, [data, showedPopup]);

  return (
    <>
      {popup && (
        <PopUp
          title={
            <>
              수정된 사항 확인 후<br />
              동의하기를 진행해주세요!
            </>
          }
          description={
            <>
              하단의 동의하기 버튼을 눌러주세요.
              <br />
              수정된 내용을 바탕으로 견적서 작성이 진행됩니다.
            </>
          }
          button1="확인하러 가기"
          onClick1={() => setPopup(false)}
          onClose={() => setPopup(false)}
        />
      )}
      <div className="bg-gray-100 min-h-screen-17">
        <div className="max-w-container flex pt-8 space-x-4">
          <Section className="w-1/4 space-y-4">
            <Section className=" white-card p-5  space-y-2">
              {edits.length > 0 && (
                <div className="border rounded-full w-max text-12 p-2 border-red-600 text-red-600">
                  수정된 신청서
                </div>
              )}

              <H5>
                신청일{' '}
                {data?.createdAt &&
                  utcToLocalFormat(data?.createdAt, MomentFormat.YYYYMMDDdd)}
              </H5>

              <div className="pb-4">
                <span className="text-12">
                  총 작성 악보수
                  <span className="font-bold pl-4">
                    {data ? data.formscores.length : 0}
                  </span>
                </span>
              </div>

              <InfoItem title="판매용 악보" count={sale.length} />
              <InfoItem title="대여용 악보" count={rental.length} />
              <InfoItem title="라이센스" count={license.length} />
            </Section>
          </Section>

          <Section className="white-card w-3/4 p-5">
            <div className="flex space-x-4 border-b -mx-5 px-5">
              {tab1s.map((t, idx) => (
                <Tab
                  key={t}
                  title={t}
                  selected={idx === tab1}
                  onClick={() => setTab1(idx)}
                />
              ))}
            </div>

            {tab1 === 0 && (
              <div className="flex space-x-4 mt-1">
                {tab2s.map((t, idx) => (
                  <Tab
                    key={t}
                    title={t}
                    selected={tab2 === idx}
                    onClick={() => setTab2(idx)}
                  />
                ))}
              </div>
            )}

            {tab1 === 0 &&
              (tab2 === 0 ? (
                <FormDetailSection
                  type="판매용"
                  edit={edits.find((v) => v.type === FormscoreType.SALE)}
                  formscores={sale}
                />
              ) : (
                <FormDetailSection
                  type="대여용"
                  edit={edits.find((v) => v.type === FormscoreType.RENTAL)}
                  formscores={rental}
                />
              ))}
            {tab1 === 1 && (
              <FormDetailSection
                type="라이센스용"
                edit={edits.find((v) => v.type === FormscoreType.LICENSING)}
                formscores={license}
              />
            )}
            {tab1 === 2 && <EstimateSheet />}
            {tab1 === 3 && <ConfirmationSheet />}
          </Section>
        </div>
      </div>
      <Footer />
    </>
  );
};

interface InfoItemProps {
  title: string;
  count: number;
}

const InfoItem: FC<InfoItemProps> = ({ title, count }) => {
  return (
    <div className="text-14 flex justify-between">
      <p className="">{title}</p>
      <p className="font-bold">{count}</p>
    </div>
  );
};

export interface FormDetailSectionProps {
  type: string;
  edit?: Edit;
  formscores: Formscore[];
  admin?: boolean;
  edittable?: boolean;
  onEdit?: (v: string) => void | Promise<void>;
}

export const FormDetailSection: FC<FormDetailSectionProps> = ({
  type,
  edit,
  formscores,
  admin = false,
  edittable = false,
  onEdit,
}) => {
  const [agree, setAgree] = useState(false);
  const [editText, setEditText] = useState('');

  useEffect(() => {
    setEditText(edit ? edit.text : '');
    setAgree(edit !== undefined && edit.agreed);
  }, [edit]);

  return (
    <>
      <div className="flex space-x-10 mt-6 border-b pb-4">
        <span className="text-gray-500 text-15">
          신청타입 <H3 className="text-gray-800 mt-1">{type}</H3>
        </span>
        <span className="text-gray-500 text-15">
          신청한 악보 수
          <H3 className="text-gray-800 mt-1">{formscores.length}개</H3>
        </span>
      </div>

      {!admin && edit && (
        <div className="bg-red-50 rounded-md p-5 mt-4 space-y-3 flex flex-col">
          <H5 className="text-red-500">수정사항</H5>
          <p className="text-gray-500">{edit.text}</p>

          <Button
            onClick={() => {
              setAgree(true);
              api.patch(`edits/${edit.id}`, { agreed: true });
            }}
            text={agree ? '동의완료' : '동의하기'}
            className={`self-end ${
              agree ? 'disabled filled-gray-300' : 'filled-brand-1'
            } `}
          />
        </div>
      )}

      {formscores.length === 0 ? (
        <div className="w-full flex flex-col items-center h-72 justify-center">
          <p className="text-xl">😢</p>
          <p>해당 타입으로 신청하신 악보가 없습니다.</p>
        </div>
      ) : (
        formscores.map((formscore) => (
          <FormCard key={formscore.id} formscore={formscore} />
        ))
      )}

      {admin && (
        <div className="space-y-5 mt-4 flex flex-col">
          <TextArea
            label="수정사항"
            labelClassname="font-bold text-lg"
            placeholder={
              edittable ? '신청서의 수정된 사항을 입력해주세요.' : ''
            }
            className="h-44"
            value={editText}
            readOnly={!edittable}
            onChange={(e) => setEditText(e.target.value)}
          />
          {edittable && (
            <Button
              text="수정사항 전달하기"
              className="filled-brand-1 self-end"
              disabled={(edit && edit.text === editText) || !editText}
              onClick={() => onEdit?.(editText)}
            />
          )}
        </div>
      )}
    </>
  );
};
