import { parse, stringify } from 'qs';
import { RefObject, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { api } from './plugins/axios';
import { tokenState } from './plugins/ridge';

export const useAuth = () => {
  const [token, setToken] = tokenState.use();
  const authenticated = token !== null;
  const signup = (data: any) =>
    api
      .post('/auth/signup', data)
      .then(({ data: { token } }) => setToken(token));
  const login = (data: any) =>
    api
      .post('/auth/login', data)
      .then(({ data: { token } }) => setToken(token));
  const logout = () => tokenState.reset();
  return { token, authenticated, signup, login, logout };
};

export function useQueryString(queryObject: any = {}) {
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });
  const searchObject = {
    page: '1',
    limit: '10',
    sort: { id: 'DESC' },
    ...parsed,
    ...queryObject,
  };
  return stringify(searchObject, { addQueryPrefix: true, encode: false });
}

export const useWidth = (ref: RefObject<HTMLElement>) => {
  const [width, setWidth] = useState(300);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    const handler = (t: any) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (ref.current) setWidth(ref.current.getBoundingClientRect().width);
        timeout = null;
      }, 100);
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [ref]);

  useEffect(() => {
    if (ref.current) setWidth(ref.current.getBoundingClientRect().width);
  }, [ref]);

  return width;
};
