import React, { FC, useState } from 'react';
import { H4 } from './H4';
import { Icon } from './Icon';
import { ReactComponent as UploadGraphic } from '../assets/svg/upload-graphic.svg';
import { Button } from './Button';
import { QueryObserverResult } from 'react-query';
import { Estimate } from '../types';
import { api } from '../plugins/axios';
import axios from 'axios';
import { Pdf } from './Pdf';

interface UploadPopUpProps {
  estimateId: number;
  open: boolean;
  onClose: () => void;
  onDone: () => void;
  refetch: () => Promise<QueryObserverResult<Estimate[], unknown>>;
}
export const UploadPopUp: FC<UploadPopUpProps> = ({
  estimateId,
  open,
  onClose,
  onDone,
  refetch,
}) => {
  const [file, setFile] = useState<File>();

  if (!open) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <div className="relative w-3/4 min-h-screen-15 flex flex-col items-center  bg-white rounded-xl overflow-hidden p-6 space-y-4">
        <button className="absolute right-5 top-5" onClick={onClose}>
          <Icon.X />
        </button>

        <H4 className="text-center">수기 서명된 견적서 업로드</H4>

        <div className="bg-gray-100 grid place-content-center w-3/4 h-screen-15 rounded-md cursor-pointer relative overflow-y-scroll overscroll-contain">
          {file ? (
            <Pdf file={file} className="absolute left-0 right-0" />
          ) : (
            <UploadGraphic />
          )}
          <label className="z-50 inset-0 absolute cursor-pointer" htmlFor="pdf">
            <input
              id="pdf"
              type="file"
              accept="application/pdf"
              className="sr-only"
              onChange={(e) => {
                const f = e.target.files?.item(0);
                if (!f || !e.target.validity.valid) return;
                if (f.size >= 10485760) alert('파일 크기가 너무 큽니다.');
                else setFile(f);
              }}
            />
          </label>
        </div>

        <Button
          disabled={!file}
          className="filled-brand-1 px-12"
          text="계약하기"
          onClick={async () => {
            const { data: post } = await api.get(
              `/s3/presignedPost?filename=${file!.name}`
            );
            const formData = new FormData();
            Object.entries(post.fields).forEach(([name, value]) =>
              formData.append(name, value as any)
            );
            formData.append('file', file!);

            await axios.post(post.url, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
            await api.patch(`/estimates/${estimateId}`, {
              contract: true,
              signature: post.fields.key,
            });
            refetch();
            onDone();
          }}
        />
      </div>
    </div>
  );
};
