import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { BackButton } from '../../components/BackButton';
import { BottomBar } from '../../components/BottomBar';
import { PhoneNumber } from '../../components/PhoneNumber';
import { PopUp } from '../../components/PopUp';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Form, FormHistory, FormState } from '../../types';

interface FormValues {
  businessNumber: string;
  adress: string;
  adressDetail: string;
  managerName: string;
  managerPhone: string;
  wantedDate: string;
}

export const FormShippingPage = () => {
  const { push } = useHistory();
  const params: any = useParams();
  const { data, isError } = useQuery<Form>(`forms/${params.id}`, fetcher);
  const [redirect, setRedirect] = useState('');
  const [popup, setPopup] = useState(false);

  const form = useForm<FormValues>({ defaultValues: { managerPhone: '--' } });
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = form;

  useEffect(() => {
    register('managerPhone', {
      required: '핸드폰 번호를 입력해주세요.',
      pattern: /\d{3}-\d{4}-\d{4}/,
    });
  }, [register]);

  useEffect(() => {
    if (isError) setRedirect('/form-status');
  }, [isError]);
  useEffect(() => {
    if (data) {
      if (data.state !== FormState.DRAFT) setRedirect(`/form/${params.id}`);
      if (data.formscores.some((formscore) => formscore.savedPage < 4))
        setRedirect(`/create/${params.id}/form-list`);
    }
  }, [data, params]);

  const setPhone = useCallback(
    (v: string) => setValue('managerPhone', v, { shouldValidate: true }),
    [setValue]
  );
  const phone = watch('managerPhone');

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      {popup && (
        <PopUp
          title="견적서 요청이 완료되었습니다!"
          description={
            <>
              신청 내용은 에서 확인하실 수 있으며,
              <br />
              관리자 확인 후 알림으로 안내드릴 예정입니다.
            </>
          }
          button1="신청서 확인하러가기"
          button2="메인으로"
          onClick1={() => push(`/form/${params.id}`)}
          onClick2={() => push('/form-status')}
          onClose={() => push('/form-status')}
        />
      )}
      <div className="max-w-container white-card mt-10 mb-20">
        <BackButton
          to={`/create/${params.id}/form-list`}
          label="배송 및 추가정보"
          className="font-bold"
        />
        <form className="space-y-6">
          <TextField
            label="*사업자 번호 (개인이 아닐 시 필수적으로 기입하셔야 합니다.)"
            labelClassname="font-bold"
            placeholder="사업자 번호를 입력해주세요."
            helper={errors.businessNumber?.message}
            {...register('businessNumber')}
          />

          <div className="space-y-2">
            <TextField
              label="*배송 주소"
              labelClassname="font-bold"
              placeholder="주소를 입력해주세요."
              helper={errors.adress?.message}
              {...register('adress', {
                required: '주소를 입력해주세요.',
              })}
            />
            <TextField
              placeholder="상세주소를 입력해주세요."
              helper={errors.adressDetail?.message}
              {...register('adressDetail', {
                required: '상세주소를 입력해주세요.',
              })}
            />
          </div>
          <TextField
            label="*담당자명"
            labelClassname="font-bold"
            placeholder="담당자 성함"
            helper={errors.managerName?.message}
            {...register('managerName', {
              required: '담당자명을 입력해주세요.',
            })}
          />
          <PhoneNumber
            label="*신청자 핸드폰 번호"
            labelClassName="font-bold"
            value={phone}
            setValue={setPhone}
            helper={
              errors.managerPhone?.message === ''
                ? '올바른 형식을 입력해주세요'
                : errors.managerPhone?.message
            }
          />
          <TextField
            label="*배송희망일자"
            labelClassname="font-bold"
            placeholder="배송희망일자를 기입해주세요."
            helper={errors.wantedDate?.message}
            {...register('wantedDate', {
              required: '배송희망일자를 선택해주세요.',
            })}
          />
        </form>
      </div>

      <BottomBar
        nextText="견적서 요청하기"
        onClickNext={handleSubmit((data) =>
          api
            .patch(`/forms/${params.id}/`, {
              state: FormState.BEFORE_CONTRACT,
              history: [FormHistory.SUBMITTED],
              ...data,
            })
            .then(() => setPopup(true))
        )}
      />
    </>
  );
};
