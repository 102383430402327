import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { PhoneNumber } from '../../../components/PhoneNumber';
import { PopUp } from '../../../components/PopUp';
import { TextField } from '../../../components/TextField';
import { api } from '../../../plugins/axios';
import { AdminH1 } from '../../components/AdminH1';

interface FormValues {
  name: string;
  phone: string;
  organization: string;
  account: string;
  password: string;
}

export const UserAdd = () => {
  const { push } = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    setError,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { phone: '--' } });
  const [popup, setPopup] = useState(false);

  const phone = watch('phone');
  useEffect(() => {
    register('phone', {
      pattern: {
        value: /\d{3}-\d{4}-\d{4}/,
        message: '올바른 형식을 입력해주세요',
      },
    });
  }, [register]);
  const setPhone = useCallback(
    (v: string) => setValue('phone', v, { shouldValidate: true }),
    [setValue]
  );

  return (
    <>
      {popup && (
        <PopUp
          title="아이디와 비밀번호를 전송하시겠습니까?"
          description="전송시 해당 계정으로 로그인이 가능합니다."
          button1="전송하기"
          button2="이전으로"
          onClick1={() => {
            api.post(`admin/users/create`, getValues());
            push('/admin/users');
          }}
          onClick2={() => setPopup(false)}
          onClose={() => setPopup(false)}
        />
      )}
      <div className="max-w-screen-md">
        <AdminH1>회원추가</AdminH1>

        <Card>
          <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6  md:px-8">
            <TextField
              label="회원명 (단체명)"
              helper={errors.name?.message}
              {...register('name', { required: '이름을 입력해주세요' })}
            />
            <PhoneNumber
              label="핸드폰 번호"
              value={phone}
              setValue={setPhone}
              helper={errors.phone?.message}
            />
            <TextField
              label="소속 단체명 / 학교명"
              type="email"
              helper={errors.organization?.message}
              {...register('organization', {
                required: '소속 단체명 / 학교명을 입력해주세요',
              })}
            />
            <TextField
              label="아이디"
              helper={errors.account?.message}
              {...register('account', { required: '아이디를 입력해주세요' })}
            />
            <TextField
              label="비밀번호"
              helper={errors.password?.message}
              {...register('password', { required: '비밀번호를 입력해주세요' })}
            />
          </div>

          <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
            <Button
              text="Cancel"
              to={`/admin/users`}
              className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
            />
            <Button
              text="Save"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={handleSubmit(async () => {
                const { data } = await api.get(
                  `/admin/users/check/phone?phone=${phone}`
                );
                if (!data)
                  setError('phone', {
                    type: 'manual',
                    message: '이미 사용된 전화번호입니다.',
                  });
                else setPopup(true);
              })}
            />
          </div>
        </Card>
      </div>
    </>
  );
};
