import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import { Formscore } from '../types';
import { Button } from './Button';
import { H5 } from './H5';
import { Icon } from './Icon';
interface SongPopUpProps {
  open: boolean;
  onClose: () => void;
  onSelect?: (id: number) => void;
}

export const SongPopUp: FC<SongPopUpProps> = ({ open, onClose, onSelect }) => {
  const { data } = useQuery<Formscore[]>('formscores', fetcher);

  if (!open) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <div className="w-3/4 h-5/6 flex flex-col bg-white rounded-xl p-6 space-y-4">
        <div className="flex items-center justify-between space-x-4">
          <H5>신청한 곡 목록</H5>
          <button onClick={onClose}>
            <Icon.X />
          </button>
        </div>

        <div className="grid grid-cols-5 gap-6">
          {[
            '신청한 작곡가 정보',
            '신청한 곡 정보',
            '신청한 출판사 정보 ',
            '신청한 작품길이 정보',
          ].map((header, idx) => (
            <p key={idx} className="text-12 text-gray-600">
              {header}
            </p>
          ))}
        </div>

        <div className="flex-grow overflow-auto">
          {data ? (
            data
              .filter((item) => item.title)
              .map((item) => (
                <div
                  key={item.id}
                  className="hover:bg-gray-50 py-6 grid grid-cols-5 gap-6 items-center"
                >
                  <p className="truncate text-14">{item.composer}</p>
                  <p className="truncate text-14">{item.title}</p>
                  <p className="truncate text-14">{item.publisher}</p>
                  <p className="truncate text-14">{item.length}</p>
                  <Button
                    text="선택하기"
                    className="filled-gray-900"
                    onClick={() => {
                      onSelect?.(item.id);
                      onClose();
                    }}
                  />
                </div>
              ))
          ) : (
            <p className="text-gray-600 text-14 self-center">
              이전에 신청한 곡이 없습니다.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
