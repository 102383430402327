import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { PopUp } from '../../../components/PopUp';
import { Section } from '../../../components/Section';
import { Tab } from '../../../components/Tab';
import { FormDetailSection } from '../../../pages/Form Status/FormDetailPage';
import { api } from '../../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { fetcher } from '../../../plugins/react-query';
import {
  AlertType,
  Edit,
  Form,
  FormHistory,
  FormscoreType,
  FormState,
} from '../../../types';
import { ConfirmationSheet } from '../../components/ConfirmationSheet';
import { EstimateSheet } from '../../components/EstimateSheet';
import { TableItem } from '../../components/TableItem';

export const FormDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [tab1, setTab1] = useState(0);
  const [tab2, setTab2] = useState(0);
  const [popup, setPopup] = useState('');
  const [editCallback, setEditCallback] = useState<() => Promise<void>>();

  const { data, isFetched, refetch } = useQuery<Form>(
    `/admin/forms/detail/${id}`,
    fetcher
  );
  const sale = data
    ? data.formscores.filter((v) =>
        [FormscoreType.SALE, FormscoreType.RENTALSALE].includes(v.type)
      )
    : [];
  const rental = data
    ? data.formscores.filter((v) =>
        [FormscoreType.RENTAL, FormscoreType.RENTALSALE].includes(v.type)
      )
    : [];
  const license = data
    ? data.formscores.filter((v) => v.type === FormscoreType.LICENSING)
    : [];

  const edits = data?.edits ?? [];
  const saleEdit = edits.find((v) => v.type === FormscoreType.SALE);
  const rentalEdit = edits.find((v) => v.type === FormscoreType.RENTAL);
  const licenseEdit = edits.find((v) => v.type === FormscoreType.LICENSING);

  const onEdit =
    (edit: Edit | undefined, type: FormscoreType) => (v: string) => {
      setPopup('edit');
      setEditCallback(() => async () => {
        setPopup('');
        if (edit)
          await api.patch(`/admin/edits/${edit.id}`, {
            text: v,
            agreed: false,
          });
        else
          await api.post('/admin/edits', {
            text: v,
            type,
            agreed: false,
            formId: +id,
          });
        refetch();
      });
    };

  const tab1s = ['악보', '라이센스'];
  if (isFetched) {
    const edits = data!.edits;
    if (edits.every((edit) => edit.agreed)) {
      tab1s.push('견적서');
      if (
        data!.history.includes(FormHistory.ESTIMATE_ARRIVED) &&
        (data!.history.includes(FormHistory.CONFIRMATION_ARRIVED) ||
          data!.state === FormState.DURING_CONTRACT)
      )
        tab1s.push('주문확인서');
    }
  }
  const tab2s = ['판매', '대여'];

  const edittable =
    data && !data.history.includes(FormHistory.ESTIMATE_ARRIVED);

  return (
    <>
      {popup === 'edit' && (
        <PopUp
          title={`${data?.user.name}님에게 수정사항을 전달하시겠어요?`}
          description={
            <>
              {data?.user.name}님이 수정사항 동의여부를
              <br />
              확인 할 예정입니다.
            </>
          }
          button1="진행하기"
          button2="취소"
          onClick1={editCallback ?? (() => {})}
          onClick2={() => {
            setPopup('');
            setEditCallback(undefined);
          }}
          onClose={() => {
            setPopup('');
            setEditCallback(undefined);
          }}
        />
      )}

      {popup === 'estimate' && (
        <PopUp
          title={`${data?.user.name}님에게 견적서를 전송하시겠어요?`}
          description={
            <>{data?.user.name}님이 견적서를 확인 후 계약이 진행됩니다.</>
          }
          button1="진행하기"
          button2="취소"
          onClick1={async () => {
            setPopup('');
            await api.patch(`/admin/forms/${id}`, {
              state: FormState.DURING_CONTRACT,
              history: [...data!.history, FormHistory.ESTIMATE_ARRIVED],
              newCount: data!.estimates.length,
            });
            await api.post(`/admin/alerts`, {
              formId: id,
              type: AlertType.ESTIMATE,
              checked: false,
            });
            await refetch();
          }}
          onClick2={() => setPopup('')}
          onClose={() => setPopup('')}
        />
      )}

      {popup === 'finish' && (
        <PopUp
          title="신청서를 완료하시겠어요?"
          description={<>주문확인서를 전송하지 않고 신청서가 완료됩니다.</>}
          button1="진행하기"
          button2="취소"
          onClick1={async () => {
            setPopup('');
            await api.patch(`/admin/forms/${id}`, {
              state: FormState.FINISHED,
            });
            await refetch();
          }}
          onClick2={() => setPopup('')}
          onClose={() => setPopup('')}
        />
      )}

      {popup === 'confirmation' && (
        <PopUp
          title={`${data?.user.name}님에게 주문확인서를 전송하시겠어요?`}
          button1="진행하기"
          button2="취소"
          onClick1={async () => {
            setPopup('');
            await api.patch(`/admin/forms/${id}`, {
              state: FormState.FINISHED,
              history: [...data!.history, FormHistory.CONFIRMATION_ARRIVED],
              newCount: 1,
            });
            await api.post(`/admin/alerts`, {
              formId: id,
              type: AlertType.CONFIRMATION,
              checked: false,
            });
            await refetch();
          }}
          onClick2={() => setPopup('')}
          onClose={() => setPopup('')}
        />
      )}

      <div>
        <div className="flex justify-between items-center">
          <Link to="/admin/form">
            <Icon.ArrowLeft />
          </Link>
          {data &&
            data.newCount === 0 &&
            ([FormState.BEFORE_CONTRACT, FormState.EDITTED].includes(
              data.state
            ) ? (
              <Button
                text="전체 견적서 발송하기"
                className="filled-brand-1"
                onClick={() => {
                  if (data.estimates.length > 0) setPopup('estimate');
                  else alert('견적서를 업로드해주세요.');
                }}
              />
            ) : data.state === FormState.DURING_CONTRACT ? (
              <div className="flex space-x-2">
                <Button
                  text="전체 주문확인서 발송하기"
                  className="filled-brand-1"
                  onClick={() => {
                    if (data.confirmations.length > 0) setPopup('confirmation');
                    else alert('주문확인서를 업로드해주세요.');
                  }}
                />
                <Button
                  text="신청서 완료하기"
                  className="filled-black"
                  onClick={() => setPopup('finish')}
                />
              </div>
            ) : null)}
        </div>

        <div className="p-5 bg-gray-50 rounded-md w-full mt-5">
          <div className="flex items-center space-x-4">
            <h4 className="text-lg font-semibold">
              {data?.user?.name} 고객 신청정보
            </h4>
            {data?.state === FormState.BEFORE_CONTRACT ||
            data?.state === FormState.EDITTED ? (
              <div className="bg-green-100 text-green-600 px-3 py-1.5 text-sm rounded-full">
                계약전
              </div>
            ) : data?.state === FormState.DURING_CONTRACT ? (
              <div className="bg-blue-100 text-blue-600 px-3 py-1.5 text-sm rounded-full">
                계약중
              </div>
            ) : data?.state === FormState.FINISHED ? (
              <div className="bg-gray-100 text-gray-600 px-3 py-1.5 text-sm rounded-full">
                완료
              </div>
            ) : null}
            {data &&
              data?.estimates.some(
                (estimate) => estimate.contract === false
              ) && (
                <div className="bg-red-600 text-sm text-red-100 px-3 py-1.5 rounded-full">
                  계약서 미동의
                </div>
              )}
          </div>
          <div className="flex space-x-10 mt-5">
            <div className="space-y-2">
              <TableItem
                label="신청일시"
                content={
                  data
                    ? utcToLocalFormat(
                        data?.createdAt,
                        MomentFormat.YYYYMMDDHmm
                      )
                    : ''
                }
              />
              <TableItem
                label="회원명(단체명)"
                content={data ? data.user.name : ''}
              />
              <TableItem
                label="신청악보수"
                content={`${data ? data.scoreCount : '-'}개`}
              />
            </div>
            <div className="space-y-2">
              <TableItem
                label="신청자 핸드폰 번호"
                content={data ? data.user.phone : ''}
              />
              <TableItem
                label="연주 단체명"
                content={data ? data.organizationName : ''}
              />
            </div>
          </div>
        </div>

        <Section className="white-card w-3/4 p-5">
          <div className="flex space-x-4 border-b -mx-5 px-5">
            {tab1s.map((t, idx) => (
              <Tab
                key={t}
                title={t}
                selected={idx === tab1}
                onClick={() => setTab1(idx)}
              />
            ))}
          </div>

          {tab1 === 0 && (
            <div className="flex space-x-4 mt-1">
              {tab2s.map((t, idx) => (
                <Tab
                  key={t}
                  title={t}
                  selected={tab2 === idx}
                  onClick={() => setTab2(idx)}
                />
              ))}
            </div>
          )}

          {tab1 === 0 &&
            (tab2 === 0 ? (
              <FormDetailSection
                type="판매용"
                edit={saleEdit}
                formscores={sale}
                admin
                edittable={edittable}
                onEdit={onEdit(saleEdit, FormscoreType.SALE)}
              />
            ) : (
              <FormDetailSection
                type="대여용"
                edit={edits.find((v) => v.type === FormscoreType.RENTAL)}
                formscores={rental}
                admin
                edittable={edittable}
                onEdit={onEdit(rentalEdit, FormscoreType.RENTAL)}
              />
            ))}
          {tab1 === 1 && (
            <FormDetailSection
              type="라이센스용"
              edit={edits.find((v) => v.type === FormscoreType.LICENSING)}
              formscores={license}
              admin
              edittable={edittable}
              onEdit={onEdit(licenseEdit, FormscoreType.LICENSING)}
            />
          )}
          {tab1 === 2 && data && (
            <EstimateSheet
              estimates={data.estimates}
              refetch={refetch}
              uploadable={!data.history.includes(FormHistory.ESTIMATE_ARRIVED)}
            />
          )}
          {tab1 === 3 && data && (
            <ConfirmationSheet
              confirmations={data.confirmations}
              refetch={refetch}
              uploadable={data.state !== FormState.FINISHED}
            />
          )}
        </Section>
      </div>
    </>
  );
};
