import React, { FC, useState } from 'react';
import { Button } from '../../components/Button';
import { FormStatusCard } from '../../components/FormStatusCard';
import { FrontPagination } from '../../components/FrontPagination';
import { Section } from '../../components/Section';
import { Form } from '../../types';

interface FormStatusCardListProps {
  data: Form[];
}

export const FormStatusCardList: FC<FormStatusCardListProps> = ({ data }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(1);
  const dataNew = data.filter((form) => form.newCount > 0);
  const tabOnNew = tabIndex === 1;
  const paginatedData = (tabOnNew ? dataNew : data).slice(
    page * 10 - 10,
    page * 10
  );

  return (
    <div className="space-y-4">
      <Section className="flex space-x-6">
        <button
          onClick={() => setTabIndex(0)}
          className={`${tabIndex === 0 ? 'font-bold' : ''}`}
        >
          전체
        </button>
        <button
          onClick={() => setTabIndex(1)}
          className={`${tabIndex === 1 ? 'font-bold' : ''}`}
        >
          확인필요
        </button>
      </Section>

      <div className="border border-gray-50 -mx-6" />

      {paginatedData.length !== 0 ? (
        paginatedData.map((form) => (
          <FormStatusCard data={form} key={form.id} />
        ))
      ) : (
        <div className="grid place-content-center space-y-4">
          <span className="text-center text-48">📝</span>
          <p className="">
            {tabOnNew ? (
              <>확인이 필요한 신청서가 없습니다.</>
            ) : (
              <>아직 작성하거나 신청한 신청서가 없습니다.</>
            )}
          </p>

          <Button
            to="/create/new/form-info"
            text="+새로운 신청서 추가하기"
            className="outlined-brand-1"
          />
        </div>
      )}

      {paginatedData.length !== 0 && (
        <FrontPagination
          page={page}
          count={Math.ceil((tabOnNew ? dataNew : data).length / 10)}
          onClick={(page) => setPage(page)}
        />
      )}
    </div>
  );
};
