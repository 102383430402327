import React from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Tab } from '../../../components/Tab';
import { UserList } from './UserList';
import { UserApplyList } from './UserApplyList';
import { useQuery } from 'react-query';
import { Paginated, User } from '../../../types';
import { fetcher } from '../../../plugins/react-query';

export const UserMain = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { data } = useQuery<Paginated<User>>(
    'admin/users?page=1&limit=1&filter[role]=REQUEST&sort[updatedAt]=DESC',
    fetcher
  );

  return (
    <>
      <div className="flex space-x-5 border-b">
        <Tab
          title="회원리스트"
          selected={pathname === '/admin/users'}
          onClick={() => push('/admin/users')}
        />
        <Tab
          title="회원신청"
          selected={pathname === '/admin/users/apply'}
          onClick={() => push('/admin/users/apply')}
          showNew={data !== undefined && data.total > 0}
        />
      </div>
      <div className="flex justify-end">
        <Button
          text="회원추가"
          className="filled-black"
          onClick={() => push('/admin/users/add')}
        />
      </div>

      <Switch>
        <Route path="/admin/users/apply" component={UserApplyList} />
        <Route path="/admin/users" component={UserList} />
      </Switch>
    </>
  );
};
