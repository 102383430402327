import React from 'react';

interface FormSideTabProps {
  active: boolean;
  onClick: () => void;
  title: string;
}

export const FormSideTab: React.FC<FormSideTabProps> = ({
  active,
  onClick,
  title,
}) => {
  return (
    <div
      className={`cursor-pointer ${
        active ? 'font-semibold text-black' : 'text-gray-500'
      }`}
      onClick={onClick}
    >
      {title}
    </div>
  );
};
