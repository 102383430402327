import React, {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Radio } from './Radio';
import { RadioGroup, RadioGroupProps } from './RadioGroup';

export interface RadioGroupUseFormProps extends RadioGroupProps {
  hookForm: UseFormReturn<any>;
  name: string;
  rules?: any;
}

export const RadioGroupUseForm = forwardRef<
  HTMLInputElement,
  RadioGroupUseFormProps
>(({ hookForm, name, rules, children, ...props }, ref) => {
  const {
    register,
    formState: { errors },
  } = hookForm;
  return (
    <RadioGroup helper={errors?.[name]?.message} {...props} setName={false}>
      {Children.map(children, (child) =>
        isValidElement(child) && child.type === Radio
          ? cloneElement(child, register(name, rules))
          : child
      )}
    </RadioGroup>
  );
});
