import React from 'react';

interface TableItemProps {
  label: string;
  content: string;
}

export const TableItem: React.FC<TableItemProps> = ({ label, content }) => {
  return (
    <div className="flex">
      <p className="w-36 text-gray-700">{label}</p>
      <p>{content}</p>
    </div>
  );
};
