import React, { useEffect, useState } from 'react';
import { H4 } from '../../components/H4';
import MusicScoreImg from '../../assets/img/music-score.png';
import { H5 } from '../../components/H5';
import { Section } from '../../components/Section';
import { ReactComponent as CheckIcon } from '../../assets/svg/check-circle.svg';
import { BottomBar } from '../../components/BottomBar';
import { Redirect, useHistory, useParams } from 'react-router';
import { api } from '../../plugins/axios';
import { Formscore, FormscoreType, FormState } from '../../types';
import { useQuery } from 'react-query';
import { fetcher } from '../../plugins/react-query';

export const ChooseTypePage = () => {
  const [sheetMusic, setSheetMusic] = useState(false);
  const [licensing, setLicensing] = useState(false);
  const [redirect, setRedirect] = useState('');
  const params: any = useParams();
  const { push } = useHistory();

  const { data, isError } = useQuery<Formscore>(
    `formscores/${params.scoreId}`,
    fetcher,
    { enabled: params.scoreId !== 'new' }
  );

  useEffect(() => {
    if (data) {
      if (data.form.id !== +params.formId) setRedirect('/form-status');
      else if (data.form.state !== FormState.DRAFT)
        setRedirect(`/form/${params.formId}`);
      else if (data.type === FormscoreType.LICENSING) {
        setSheetMusic(false);
        setLicensing(true);
      } else {
        setSheetMusic(true);
        setLicensing(false);
      }
    }
  }, [data, params.formId]);

  useEffect(() => {
    if (isError) setRedirect('/form-status');
  }, [isError]);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <div className="max-w-container white-card my-10 ">
        <H4 className="text-center py-10">무엇이 필요하신가요?</H4>

        <Section className="grid grid-cols-2 pt-4 pb-20">
          <div
            onClick={() => {
              setSheetMusic(true);
              setLicensing(false);
            }}
            className="relative cursor-pointer grid place-content-center"
          >
            <img src={MusicScoreImg} alt="" className="shadow-md rounded-lg" />
            <div className="absolute inset-0">
              <div className="w-full h-full flex flex-col space-y-2 justify-center items-center text-center">
                {sheetMusic && <CheckIcon />}

                <H5 className={`${sheetMusic ? 'text-brand-1' : ''}`}>
                  Sheet Music <br /> 악보
                </H5>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setLicensing(true);
              setSheetMusic(false);
            }}
            className="relative cursor-pointer grid place-content-center"
          >
            <img src={MusicScoreImg} alt="" className="shadow-md rounded-lg" />
            <div className="absolute inset-0">
              <div className="w-full h-full flex flex-col space-y-2 justify-center items-center text-center">
                {licensing && <CheckIcon />}
                <H5 className={`${licensing ? 'text-brand-1' : ''}`}>
                  Licensing
                  <br /> 사용허가
                </H5>
              </div>
            </div>
          </div>
        </Section>
      </div>
      <BottomBar
        nextDisabled={!licensing && !sheetMusic}
        previousText="이전으로"
        previousPage={`/create/${params.formId}/form-list`}
        nextPage={
          licensing
            ? '/create/:id/form/:id/type/license'
            : '/create/:id/form/:id/type/score/1'
        }
        onClickNext={() =>
          (data || params.scoreId === 'new') &&
          (params.scoreId === 'new'
            ? api.post(`formscores`, {
                type: sheetMusic
                  ? FormscoreType.SHEET
                  : FormscoreType.LICENSING,
                formId: params.formId,
                savedPage: 1,
              })
            : api.patch(`formscores/${params.scoreId}`, {
                type: sheetMusic
                  ? [
                      FormscoreType.RENTAL,
                      FormscoreType.SALE,
                      FormscoreType.RENTALSALE,
                    ].includes(data!.type)
                    ? undefined
                    : FormscoreType.SHEET
                  : FormscoreType.LICENSING,
                savedPage: 1,
              })
          ).then(({ data }) =>
            push(
              sheetMusic
                ? `/create/${params.formId}/form/${data.id}/type/score/1`
                : `/create/${params.formId}/form/${data.id}/type/license`
            )
          )
        }
      />
    </>
  );
};
