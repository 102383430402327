import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Search } from '../../../components/Search';
import { Table } from '../../../components/Table';
import { Tab } from '../../../components/Tab';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { fetcher } from '../../../plugins/react-query';
import {
  Form,
  formscoreTypeToString,
  FormState,
  Paginated,
} from '../../../types';
import { FormSideTab } from '../../components/FormSideTab';

const tabItem = [
  { name: '전체', query: 'all' },
  { name: '계약전', query: 'before' },
  { name: '계약중', query: 'during' },
  { name: '계약완료', query: 'finished' },
  { name: '수정신청서', query: 'editted' },
];

export const FormList = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const page = Number(params.get('page') ?? '1');
  const limit = Number(params.get('limit') ?? '10');

  const [username, setUsername] = useState('');
  const [tab, setTab] = useState('all');
  const { data } = useQuery<Paginated<Form>>(
    `/admin/forms/search?username=${username}&state=${tab}&page=${page}&limit=${limit}`,
    fetcher
  );
  const forms = data?.items ?? [];

  return (
    <>
      <div className="flex justify-end">
        <div className="ml-auto w-72">
          <Search onSearch={setUsername} />
        </div>
      </div>
      <div className="flex space-x-5">
        <div className="w-48 border rounded-md p-5">
          <div className="pb-5 border-b text-lg font-medium">계약 진행상황</div>
          <div className="pt-5 space-y-3">
            {tabItem.map((item) => (
              <FormSideTab
                key={item.query}
                title={item.name}
                onClick={() => setTab(item.query)}
                active={tab === item.query}
              />
            ))}
          </div>
        </div>
        <div className="flex-1 overflow-y-scroll scrollbar-hidden h-screen-15">
          <Card>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>ID</Table.Th>
                  <Table.Th>생성일</Table.Th>
                  <Table.Th>회원명(단체명)</Table.Th>
                  <Table.Th>신청곡</Table.Th>
                  <Table.Th>신청서 타입</Table.Th>
                  <Table.Th>계약 진행사항</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {forms.map((form) => (
                  <Table.Row key={form.id}>
                    <Table.Td>{form.id}</Table.Td>
                    <Table.Td>
                      {utcToLocalFormat(
                        form.createdAt.toLocaleString(),
                        MomentFormat.YYYYMMDDHmm
                      )}
                    </Table.Td>
                    <Table.Td>{form.user.name}</Table.Td>
                    <Table.Td>
                      {form.formscores.map((formscore) => (
                        <p key={formscore.id}>{formscore.title}</p>
                      ))}
                    </Table.Td>
                    <Table.Td>
                      {form.formscores.map((formscore) => (
                        <p key={formscore.id}>
                          {formscoreTypeToString(formscore.type)}
                        </p>
                      ))}
                    </Table.Td>
                    {form.state === FormState.DURING_CONTRACT ? (
                      <Table.Td className="text-blue-500">계약중</Table.Td>
                    ) : form.state === FormState.FINISHED ? (
                      <Table.Td className="text-black">완료</Table.Td>
                    ) : (
                      <Table.Td className="text-green-500">계약전</Table.Td>
                    )}
                    <Table.Td className="text-right">
                      <Link
                        className="font-normal text-blue-500"
                        to={`/admin/form/${form.id}/sheet`}
                      >
                        상세보기
                      </Link>
                    </Table.Td>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <Pagination>
              <Pagination.Label
                count={forms.length ?? 0}
                total={data?.total ?? 0}
              />
              <Pagination.Nav basePath="/admin/form" total={data?.total ?? 0} />
            </Pagination>
          </Card>
        </div>
      </div>
    </>
  );
};
