import { FC } from 'react';
import { useHistory } from 'react-router';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { Form, FormHistory, FormState } from '../types';
import { H5 } from './H5';
import { Icon } from './Icon';
import { Section } from './Section';

function statusTypeToText(type: FormHistory) {
  switch (type) {
    case FormHistory.EDITTED:
      return '수정된 신청서';
    case FormHistory.SUBMITTED:
      return '신청서 제출 완료';
    case FormHistory.DRAFT:
      return '임시 저장';
    case FormHistory.ESTIMATE_ARRIVED:
      return '견적서 도착';
    case FormHistory.CONFIRMATION_ARRIVED:
      return '주문확인서도착';
    default:
      throw new Error('Invalid status type');
  }
}

function statusTypeToClassName(type: FormHistory) {
  switch (type) {
    case FormHistory.EDITTED:
      return 'border-red-600 text-red-600';
    case FormHistory.SUBMITTED:
      return 'border-purple-600 text-purple-600';
    case FormHistory.DRAFT:
      return 'border-gray-900 text-gray-900';
    case FormHistory.ESTIMATE_ARRIVED:
      return 'border-blue-500 text-blue-500';
    case FormHistory.CONFIRMATION_ARRIVED:
      return 'border-green-700 text-green-700';
    default:
      throw new Error('Invalid status type');
  }
}

interface HistoryListProps {
  data: FormHistory[];
}

const HistoryList: FC<HistoryListProps> = ({ data }) => {
  return (
    <Section className="pt-4 flex ">
      {data.map((history, i) => {
        const isLast = i === data.length - 1;
        return (
          <div key={i} className="flex items-center">
            <div
              className={`border ${
                isLast
                  ? statusTypeToClassName(history)
                  : 'border-gray-200 text-gray-200'
              } rounded-full w-max text-12 p-2`}
            >
              {statusTypeToText(history)}
            </div>
            <p className="text-center mx-2 text-gray-200">{!isLast && '>'}</p>
          </div>
        );
      })}
    </Section>
  );
};

interface FormStatusCardProps {
  data: Form;
}

export const FormStatusCard: FC<FormStatusCardProps> = ({ data }) => {
  const { push } = useHistory();
  const isNew = data.newCount > 0;
  return (
    <div
      onClick={() =>
        data.state === FormState.DRAFT
          ? push(`/create/${data.id}/form-list`)
          : push(`/form/${data.id}`)
      }
      className="cursor-pointer bg-gray-50 rounded-md p-4 divide-y space-y-4"
    >
      <Section className="flex justify-between items-center">
        <Section className="">
          <div className="flex space-x-5 divide-x items-center">
            <div className="">
              <span className="text-13 text-gray-500">작성일</span>
              <H5>
                {utcToLocalFormat(data.createdAt, MomentFormat.YYYYMMDDdd)}
              </H5>
            </div>

            {data.editDate && (
              <div className="pl-5">
                <span className="text-13 text-gray-500">최신 수정일</span>
                <H5>
                  {utcToLocalFormat(data.editDate, MomentFormat.YYYYMMDDdd)}
                </H5>
              </div>
            )}
          </div>
          <div className="text-12 pt-2">
            총 작성 악보수
            <span className="font-bold pl-4">{data.scoreCount}</span>
          </div>
        </Section>
        {isNew && (
          <div className="bg-brand-1 text-white wh-5 rounded-full text-12 grid place-content-center ml-4">
            N
          </div>
        )}
        <div className="flex-grow" />
        <button className="">
          <Icon.ChevronRight />
        </button>
      </Section>
      <HistoryList
        data={data.history.length === 0 ? [FormHistory.DRAFT] : data.history}
      />
    </div>
  );
};
