import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { Redirect, useHistory, useParams } from 'react-router';
import { BackButton } from '../../components/BackButton';
import { Button } from '../../components/Button';
import { Dropdown } from '../../components/Dropdown';
import { H4 } from '../../components/H4';
import { Icon } from '../../components/Icon';
import { Label } from '../../components/Label';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { BottomBar } from '../../components/BottomBar';
import {
  Form,
  Formscore,
  FormscoreType,
  formscoreTypeToString,
  FormState,
  licenseTypeToRoute,
} from '../../types';
import { PopUp } from '../../components/PopUp';

export const FormListPage = () => {
  const params: any = useParams();
  const { push } = useHistory();
  const { data, refetch, isError } = useQuery<Form>(
    `forms/${params.id}`,
    fetcher
  );
  const [deleteId, setDeleteId] = useState<number | null>(null);

  return isError ? (
    <Redirect to="/form-status" />
  ) : data && data?.state !== FormState.DRAFT ? (
    <Redirect to={`/form/${params.id}`} />
  ) : (
    <>
      {deleteId && (
        <PopUp
          title="작성한 신청서를 삭제하시겠어요?"
          description="삭제시 작성된 내용이 모두 삭제됩니다."
          button1="삭제하기"
          button2="돌아가기"
          onClick1={async () => {
            await api.delete(`/formscores/${deleteId}`);
            await refetch();
            setDeleteId(null);
          }}
          onClick2={() => setDeleteId(null)}
          onClose={() => setDeleteId(null)}
        />
      )}
      <div className="bg-gray-100 min-h-screen-4.5 py-20">
        <div className="max-w-container white-card mb-10">
          <BackButton
            to={`/create/${params.id}/form-info`}
            label="신청자정보 수정"
          />

          <H4 className="my-4">신청서 작성 현황</H4>

          <div className="grid grid-cols-4 gap-4">
            <AddCard formId={data?.id} scoreCount={data?.scoreCount} />

            {data &&
              data.formscores.map((formscore) => (
                <FormCard
                  key={formscore.id}
                  data={formscore}
                  formId={+params.id}
                  onDelete={(formscoreId: number) => setDeleteId(formscoreId)}
                />
              ))}
          </div>
        </div>
        <BottomBar
          nextText="제출하기"
          onClickNext={() => {
            if (data) {
              if (data.formscores.length === 0) alert('곡을 신청해주세요.');
              else if (
                data.formscores.some((formscore) => formscore.savedPage < 4)
              )
                alert('작성이 완료되지 않은 곡이 있습니다.');
              else push(`/create/${data.id}/shipping`);
            }
          }}
        />
      </div>
    </>
  );
};

interface AddCardProps {
  formId?: number;
  scoreCount?: number;
}

const AddCard: FC<AddCardProps> = ({ formId, scoreCount }) => {
  const { push } = useHistory();
  return (
    <div className="flex flex-col items-center justify-center bg-gray-50 rounded-md p-5 space-y-2">
      <button
        onClick={() => {
          if (formId && scoreCount !== undefined) {
            if (scoreCount === 10) alert('이미 10개의 곡을 신청했습니다.');
            else push(`/create/${formId}/form/new/type`);
          }
        }}
        className="bg-brand-1 rounded-full wh-12 text-white text-32"
      >
        +
      </button>
      <Label className="font-bold">신청하기</Label>
      <p className="text-12 text-red-500">(곡 10개까지 추가가능)</p>
    </div>
  );
};

interface FormCardProps {
  data: Formscore;
  formId: number;
  onDelete: (formscoreId: number) => void;
}

const FormCard: FC<FormCardProps> = ({ data, formId, onDelete }) => {
  const { push } = useHistory();

  return (
    <div className="flex flex-col bg-gray-50 rounded-md p-5 space-y-3 text-14">
      <div className="flex">
        <span className="w-16">작곡가</span>
        <span className="flex-1 font-bold truncate">{data.composer}</span>
      </div>
      <div className="flex">
        <span className="w-16">제목</span>
        <span className="flex-1 font-bold truncate">{data.title}</span>
      </div>
      <div className="flex">
        <span className="w-16">곡길이</span>
        <span className="flex-1 font-bold truncate">{data.length}</span>
      </div>

      <div className="border-b -mx-5" />

      <div className="flex">
        <span className="w-16">신청타입</span>
        <span className="flex-1 font-bold truncate text-brand-1">
          {formscoreTypeToString(data.type)}
          {data.savedPage < 4 && '*'}
        </span>

        <Dropdown>
          <Icon.MoreVertical className="wh-5 cursor-pointer" />
          <Dropdown.View className="border rounded-md p-2 flex flex-col">
            <Button
              text="삭제"
              className=""
              onClick={() => onDelete(data.id)}
            />
            <Button
              text="수정"
              className=""
              onClick={() => {
                if (data.savedPage === 0 || data.savedPage === 4)
                  push(`/create/${formId}/form/${data.id}/type`);
                else if (data.type !== FormscoreType.LICENSING)
                  push(
                    `/create/${formId}/form/${data.id}/type/score/${data.savedPage}`
                  );
                else if (data.savedPage === 1)
                  push(`/create/${formId}/form/${data.id}/type/license`);
                else
                  push(
                    `/create/${formId}/form/${
                      data.id
                    }/type/license/${licenseTypeToRoute(
                      data.licenseType[data.savedPage - 2]
                    )}`
                  );
              }}
            />
          </Dropdown.View>
        </Dropdown>
      </div>
    </div>
  );
};
