import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { BottomBar } from '../../components/BottomBar';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { FormTitle } from '../../components/FormTitle';
import { H5 } from '../../components/H5';
import { SearchSongPopUp } from '../../components/SearchSongPopUp';
import { SongPopUp } from '../../components/SongPopUp';
import { TextField } from '../../components/TextField';
import { Formscore, FormscoreType, FormState, Score } from '../../types';
import { api } from '../../plugins/axios';
import { useQuery } from 'react-query';
import { fetcher } from '../../plugins/react-query';

interface FormValues {
  composer: string;
  title: string;
  publisher: string;
  remarks: string;
  length: string;
  type: FormscoreType;
}

export const ScoreOnePage = () => {
  const { push } = useHistory();
  const params: any = useParams();
  const [forSale, setForSale] = useState<boolean>(false);
  const [forRental, setForRental] = useState<boolean>(false);
  const [songPopUp, setSongPopUp] = useState(false);
  const [searchPopUp, setSearchPopUp] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [countId, setCountId] = useState<number>();
  const { data, isError } = useQuery<Formscore>(
    `formscores/${params.scoreId}`,
    fetcher
  );

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm<FormValues>();
  useEffect(() => {
    setValue(
      'type',
      forSale
        ? forRental
          ? FormscoreType.RENTALSALE
          : FormscoreType.SALE
        : forRental
        ? FormscoreType.RENTAL
        : FormscoreType.SHEET
    );
  }, [forSale, forRental, setValue]);

  useEffect(() => {
    register('type', {
      validate: (v) =>
        [
          FormscoreType.SALE,
          FormscoreType.RENTAL,
          FormscoreType.RENTALSALE,
        ].includes(v) || '판매용과 대여용 중 최소 하나를 선택해주세요.',
    });
  }, [register]);

  const setFormscore = useCallback(
    (data: Formscore) => {
      setValue('composer', data.composer);
      setValue('title', data.title);
      setValue('publisher', data.publisher);
      setValue('remarks', data.remarks);
      setValue('length', data.length);
      setForSale(
        [FormscoreType.SALE, FormscoreType.RENTALSALE].includes(data.type)
      );
      setForRental(
        [FormscoreType.RENTAL, FormscoreType.RENTALSALE].includes(data.type)
      );
    },
    [setValue]
  );

  const copyFormscore = useCallback(
    (id: number) =>
      api
        .put('/formscores/copy', { to: params.scoreId, from: id })
        .then(({ data }) => {
          setFormscore(data);
          clearErrors();
        }),
    [setFormscore, params, clearErrors]
  );

  useEffect(() => {
    if (data) {
      if (data.form.id !== +params.formId) setRedirect('/form-status');
      else if (data.form.state !== FormState.DRAFT)
        setRedirect(`/forms/${params.formId}`);
      else {
        setFormscore(data);
        setCountId(data.countId);
      }
    }
  }, [data, params.formId, setFormscore]);

  useEffect(() => {
    if (isError) setRedirect('/form-status');
  }, [isError]);

  const submitData = (data: any) =>
    api.patch(`/formscores/${params.scoreId}`, data);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <SongPopUp
        open={songPopUp}
        onSelect={copyFormscore}
        onClose={() => setSongPopUp(false)}
      />
      <SearchSongPopUp
        open={searchPopUp}
        onClose={() => setSearchPopUp(false)}
        onChoose={(score: Score) => {
          setValue(
            'composer',
            score.composers.map((composer) => composer.name).join(', ')
          );
          setValue('title', score.title);
          setValue('publisher', score.publisher);
          setValue('length', score.len);
          clearErrors();
        }}
      />
      <div className="max-w-container white-card my-10 mb-20">
        <FormTitle count={countId} type="악보" />

        <div className="flex justify-between pb-6">
          <div>
            <H5>신청하실 악보를 입력해주세요!</H5>
            <p className="text-12 text-brand-1">
              직접 입력을 하거나 우측의 검색하기 버튼으로 원하는 곡을
              검색해보세요.
            </p>
          </div>

          <div className="space-x-4">
            <Button
              onClick={() => setSongPopUp(true)}
              text="이전 신청 곡 불러오기"
              className="filled-gray-800"
            />
            <Button
              onClick={() => setSearchPopUp(true)}
              text="곡 검색하기"
              className="outlined-gray-800"
            />
          </div>
        </div>

        <form className="space-y-6">
          <TextField
            label="작곡가 (필수)"
            placeholder="작곡가를 입력해주세요."
            helper={errors?.composer?.message}
            {...register('composer', { required: '작곡가를 입력해주세요.' })}
          />
          <TextField
            label="곡명 (필수)"
            placeholder="곡명을 입력해주세요."
            helper={errors?.title?.message}
            {...register('title', { required: '곡명을 입력해주세요.' })}
          />
          <TextField
            label="출판사 및 권리사 / Editor 및 Arranger"
            placeholder="출판사 / Editor 및 Arranger을 입력해주세요."
            helper={errors?.publisher?.message}
            {...register('publisher')}
          />
          <div className="grid grid-cols-2 gap-x-8">
            <TextField
              label="Remarks"
              placeholder="버전, 작곡년도, 타입등을 입력해주세요."
              helper={errors?.remarks?.message}
              {...register('remarks')}
            />
            <TextField
              label="작품 길이"
              placeholder="작품 길이를 입력해주세요."
              helper={errors?.length?.message}
              {...register('length')}
            />
          </div>

          <div>
            <div className="flex space-x-8">
              <Checkbox
                label="판매용 악보"
                checked={forSale}
                onChange={() => setForSale((forSale) => !forSale)}
              />
              <Checkbox
                label="대여용 악보"
                checked={forRental}
                onChange={() => setForRental((forRental) => !forRental)}
              />
            </div>
            <p className="text-sm text-error">{errors.type?.message}</p>
          </div>
        </form>
      </div>

      <BottomBar
        title="STEP 1. 악보선택"
        progress={`${forRental ? '33%' : forSale ? '100%' : ''}`}
        onClickPrevious={() => {
          submitData({ ...getValues(), savedPage: 0 }).then(() =>
            push(`/create/${params.formId}/form/${params.scoreId}/type`)
          );
        }}
        onClickNext={handleSubmit((data) =>
          submitData({ ...data, savedPage: forRental ? 2 : 4 }).then(() =>
            push(
              forRental
                ? `/create/${params.formId}/form/${params.scoreId}/type/score/2`
                : `/create/${params.formId}/form-list`
            )
          )
        )}
      />
    </>
  );
};
