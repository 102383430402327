import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { Button } from '../components/Button';
import { H2 } from '../components/H2';
import { TextField } from '../components/TextField';
import { useAuth } from '../hooks';
import { api } from '../plugins/axios';
import { Role } from '../types';

interface FormValues {
  account: string;
  password: string;
}

export const LoginPage = () => {
  const { login, authenticated } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    if (authenticated) {
      api
        .get('/users/me')
        .then(({ data, status }) => {
          setRedirect(
            status === 200 && data.role === Role.ADMIN ? '/admin' : '/'
          );
        })
        .catch(() => setRedirect('/'));
    }
  }, [authenticated]);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <div className="bg-gray-100 h-screen-5 pt-20">
        <div className="bg-white max-w-container rounded-md">
          <div className="max-w-slim py-6">
            <H2 className="text-center">로그인</H2>

            <form
              className="p-4 flex flex-col space-y-4"
              onSubmit={handleSubmit((data) =>
                login(data).catch(() => alert('로그인에 실패했습니다.'))
              )}
            >
              <TextField
                label="아이디"
                placeholder="아이디를 입력해주세요."
                helper={errors.account?.message}
                {...register('account', { required: '아이디를 입력해주세요.' })}
              />

              <TextField
                label="비밀번호"
                type="password"
                placeholder="비밀번호를 입력해주세요."
                helper={errors.password?.message}
                {...register('password', {
                  required: '비밀번호를 입력해주세요',
                })}
              />

              <div className="flex flex-col space-y-3 pt-14">
                <Button text="로그인하기" className="filled-gray-800" />
                <Button
                  to="/signup"
                  text="회원 신청하기"
                  className="filled-brand-1"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
