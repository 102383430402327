import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useParams } from 'react-router-dom';
import { CheckboxUseForm } from '../../components/CheckboxUseForm';
import { FormTitle } from '../../components/FormTitle';
import { H5 } from '../../components/H5';
import { Label } from '../../components/Label';
import { LicenseBottomBar, useLicenseData } from './licenseComponents';
import { Radio } from '../../components/Radio';
import { RadioGroupUseForm } from '../../components/RadioGroupUseForm';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import {
  BooleanString,
  Formscore,
  LicenseType,
  StreamAmount,
  StreamEdit,
  StreamPeriod,
  streamPeriodToString,
  StreamRegion,
  streamRegionToString,
} from '../../types';

interface FormValues {
  region: StreamRegion[];
  media: string;
  period: StreamPeriod[];
  amount: StreamAmount[];
  amountDetail: string;
  edit: StreamEdit[];
  editSomeDetail: string;
  editMuchDetail: string;
  paid: BooleanString;
  paidMethod: string;
  remarks: string;
  noEdit: BooleanString;
}

export const LicenseInternetPage = () => {
  const params: any = useParams();
  const form = useForm<FormValues>({
    defaultValues: { region: [], period: [], amount: [], edit: [] },
  });
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const [amount, edit, paid] = watch(['amount', 'edit', 'paid']);

  useEffect(() => {
    register('region');
    register('period');
    register('amount');
    register('edit');
  }, [register]);

  const submitData = (data: any, savedPage: number) =>
    api.patch(`/formscores/${params.scoreId}`, {
      streamInfo: JSON.stringify(data),
      savedPage,
    });

  const setValues = useCallback(
    (data: Formscore) => {
      const json = data.streamInfo;
      if (json) {
        const obj = JSON.parse(json as any);
        const keys: (keyof FormValues)[] = [
          'media',
          'amountDetail',
          'editSomeDetail',
          'editMuchDetail',
          'paid',
          'paidMethod',
          'remarks',
          'noEdit',
        ];
        keys.forEach((key: keyof FormValues) => setValue(key, obj[key]));
        setValue('region', obj.region ?? []);
        setValue('period', obj.period ?? []);
        setValue('amount', obj.amount ?? []);
        setValue('edit', obj.edit ?? []);
      }
    },
    [setValue]
  );
  const { redirect, countId, licenseType } = useLicenseData(setValues);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <div className="max-w-container white-card mt-10 mb-28">
        <FormTitle count={countId} type="라이센스" />

        <form className="space-y-6">
          <H5 className="pb-6">
            인터넷 스트리밍 (Youtube, Naver TV, 등) 정보를 입력해주세요!
          </H5>

          <div className="space-y-2">
            <Label text="송출지역" className="font-bold" />
            <div className="flex flex-wrap space-x-8">
              {[StreamRegion.WORLD, StreamRegion.KOREA, StreamRegion.OTHER].map(
                (v) => (
                  <CheckboxUseForm
                    key={v}
                    label={streamRegionToString(v)}
                    value={v}
                    hookForm={form}
                    name="region"
                  />
                )
              )}
            </div>
          </div>

          <TextField
            label="사용매체"
            labelClassname="font-bold"
            placeholder="사용매체를 입력해주세요."
            helper={errors.media?.message}
            {...register('media', {
              required: '사용매체를 입력해주세요.',
            })}
          />

          <div className="space-y-2">
            <Label text="게재기간 (중복선택 가능)" className="font-bold" />
            <div className="flex flex-wrap space-x-8">
              {[
                StreamPeriod.LIVE,
                StreamPeriod.DAY,
                StreamPeriod.MONTH,
                StreamPeriod.THREE_MONTH,
                StreamPeriod.YEAR,
                StreamPeriod.TWO_YEAR,
                StreamPeriod.FIVE_YEAR,
                StreamPeriod.TEN_YEAR,
              ].map((v) => (
                <CheckboxUseForm
                  key={v}
                  label={streamPeriodToString(v)}
                  value={v}
                  hookForm={form}
                  name="period"
                />
              ))}
            </div>
          </div>

          <div className="space-y-2">
            <Label text="사용분량 (중복선택 가능)" className="font-bold" />
            <div className="space-y-2">
              <CheckboxUseForm
                label="곡전체 사용"
                value={StreamAmount.WHOLE}
                hookForm={form}
                name="amount"
              />

              <div className="space-y-2">
                <CheckboxUseForm
                  label="일부사용"
                  value={StreamAmount.PART}
                  hookForm={form}
                  name="amount"
                />
                {amount.includes(StreamAmount.PART) && (
                  <TextField
                    placeholder="사용부분을 입력해주세요."
                    className="w-1/2"
                    helper={errors.amountDetail?.message}
                    {...register('amountDetail')}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <Label text="편집여부 (중복선택 가능)" className="font-bold" />
            <div className="space-y-2">
              <CheckboxUseForm
                label="영상편집 거의 없음"
                value={StreamEdit.NONE}
                hookForm={form}
                name="edit"
              />

              <div className="space-y-2">
                <CheckboxUseForm
                  label="영상편집 다소 포함 (원곡은 변형되지 않으나 자막, 춤, 나레이션 등 새로운 요소가 가미됨)"
                  value={StreamEdit.SOME}
                  hookForm={form}
                  name="edit"
                />
                {edit.includes(StreamEdit.SOME) && (
                  <TextField
                    placeholder="편집방향을 입력해주세요. "
                    className="w-1/2"
                    helper={errors.editSomeDetail?.message}
                    {...register('editSomeDetail')}
                  />
                )}
              </div>

              <div className="space-y-2">
                <CheckboxUseForm
                  label="영상편집 많음 (곡의 변형, 다양한 이펙트 등 영상효과 삽입)"
                  value={StreamEdit.MUCH}
                  hookForm={form}
                  name="edit"
                />
                {edit.includes(StreamEdit.MUCH) && (
                  <TextField
                    placeholder="편집방향를 입력해주세요."
                    className="w-1/2"
                    helper={errors.editMuchDetail?.message}
                    {...register('editMuchDetail')}
                  />
                )}
              </div>
            </div>

            <RadioGroupUseForm
              label="스트리밍 타입선택 (하나만 선택 가능)"
              labelClassname="font-bold"
              className="space-y-2"
              hookForm={form}
              name="paid"
              rules={{ required: '스트리밍 타입을 선택해주세요.' }}
            >
              <Radio label="무료스트리밍" value={BooleanString.FALSE} />
              <Radio
                label="유료스트리밍(유료 회원에게만 제공되는 콘텐츠일 경우)"
                value={BooleanString.TRUE}
              />
              {paid === BooleanString.TRUE && (
                <TextField
                  placeholder="유료스트리밍 경우 과금방식에 대해 입력해주세요."
                  className="w-1/2"
                  helper={errors.paidMethod?.message}
                  {...register('paidMethod')}
                />
              )}
            </RadioGroupUseForm>

            <TextArea
              label="추가사항"
              labelClassname="font-bold"
              placeholder="추가사항을 입력해주세요."
              helper={errors.remarks?.message}
              {...register('remarks')}
            />

            <RadioGroupUseForm
              label="원곡을 그대로 사용함을 확인합니다. 개작 또는 편곡이 있을 경우, 편곡허가신청을 먼저 진행하여 주시기 바랍니다."
              labelClassname="font-bold text-18"
              className="flex items-center space-x-4"
              hookForm={form}
              name="noEdit"
              rules={{ required: '동의 혹은 비동의를 선택해주세요.' }}
            >
              <Radio label="동의" value={BooleanString.TRUE} />
              <Radio label="비동의" value={BooleanString.FALSE} />
            </RadioGroupUseForm>
          </div>
        </form>
      </div>

      <LicenseBottomBar
        form={form}
        type={LicenseType.INTERNET}
        licenseType={licenseType}
        title="인터넷스트리밍"
        submitData={submitData}
      />
    </>
  );
};
