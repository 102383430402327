import React, { FC, useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { BottomBar } from '../../components/BottomBar';
import { Button } from '../../components/Button';
import { FormTitle } from '../../components/FormTitle';
import { H5 } from '../../components/H5';
import { Radio } from '../../components/Radio';
import { RadioGroupUseForm } from '../../components/RadioGroupUseForm';
import { Section } from '../../components/Section';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import {
  BooleanString,
  ConcertType,
  Formscore,
  FormState,
  LiveRecordType,
} from '../../types';

interface FormValues {
  concertLicense: boolean;
  type: ConcertType;
  copyrightCount: string;
  seatsCount: string;
  paid: BooleanString;
  price: string;
  sales: string;
  artDirector: string;
  director: string;
  description: string;
  choreographer: string;
  synopsis: string;
  language: string;
  cast: string;
  liveRecord: LiveRecordType;
  live: string;
  record: string;
  duration: string;
  totalDuration: string;
  company: string;
  otherMusic: string;
  remarks: string;
  noEdit: BooleanString;
}

export const ScoreThreePage = () => {
  const { push } = useHistory();
  const params: any = useParams();
  const form = useForm<FormValues>();
  const { setValue, handleSubmit, getValues, watch, register } = form;
  const [redirect, setRedirect] = useState('');
  const [countId, setCountId] = useState<number>();
  const [concertLicense, type] = watch(['concertLicense', 'type']);
  const { data, isError } = useQuery<Formscore>(
    `formscores/${params.scoreId}`,
    fetcher
  );

  useEffect(() => {
    register('concertLicense');
  });

  const submitData = (data: any, savedPage: number) => {
    const { concertLicense, ...concertLicenseInfo } = data;
    return api.patch(`/formscores/${params.scoreId}`, {
      concertLicense,
      concertLicenseInfo: JSON.stringify(concertLicenseInfo),
      savedPage,
    });
  };

  useEffect(() => {
    if (data) {
      if (data.form.id !== +params.formId) setRedirect('/form-status');
      else if (data.form.state !== FormState.DRAFT)
        setRedirect(`/forms/${params.formId}`);
      else {
        setCountId(data.countId);
        if (data.concertLicense !== null)
          setValue('concertLicense', data.concertLicense);
        const json = data.concertLicenseInfo;
        if (json) {
          const obj = JSON.parse(json as any);
          const keys: (keyof FormValues)[] = [
            'type',
            'copyrightCount',
            'seatsCount',
            'paid',
            'price',
            'sales',
            'artDirector',
            'director',
            'description',
            'choreographer',
            'synopsis',
            'language',
            'cast',
            'liveRecord',
            'live',
            'record',
            'duration',
            'totalDuration',
            'company',
            'otherMusic',
            'remarks',
            'noEdit',
          ];
          keys.forEach((key: keyof FormValues) => setValue(key, obj[key]));
        }
      }
    }
  }, [data, params, setValue]);

  useEffect(() => {
    if (isError) setRedirect('/form-status');
  }, [isError]);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <div className="max-w-container white-card mt-10 mb-28">
        <FormTitle count={countId} type="악보" />

        <H5 className="">무대 공연을 입력해주세요!</H5>

        <form className="space-y-4">
          <div className="flex space-x-4 items-center pt-6">
            <div className="">
              <H5>무대공연 라이센스가 필요하신가요?</H5>

              <span className="text-14 text-gray-600">
                (오페라, 발레 등 Stage works일 경우에는 반드시 무대 공연허가를
                사전에 득해야 합니다.)
              </span>
            </div>

            <div className="space-x-3">
              <Button
                type="button"
                onClick={() => setValue('concertLicense', true)}
                text="예"
                className={`${
                  concertLicense === true ? 'filled-gray-800' : 'bg-gray-100'
                } `}
              />
              <Button
                type="button"
                onClick={() => setValue('concertLicense', false)}
                text="아니요"
                className={`${
                  concertLicense === false ? 'filled-gray-800' : 'bg-gray-100'
                } `}
              />
            </div>
          </div>

          {concertLicense && (
            <>
              <RadioGroupUseForm
                label="Concert / Stage Works"
                labelClassname="font-bold text-18"
                className="flex items-center space-x-4"
                name="type"
                hookForm={form}
                rules={{
                  validate: (v: any) =>
                    !concertLicense ||
                    v !== undefined ||
                    '무대공연 종류를 선택해주세요.',
                }}
              >
                <Radio label="Concert" value={ConcertType.CONCERT} />
                <Radio label="Opera" value={ConcertType.OPERA} />
                <Radio label="Ballet" value={ConcertType.BALLET} />
                <Radio
                  label="Musical and Theatrical"
                  value={ConcertType.MUSICAL}
                />
              </RadioGroupUseForm>

              {type === ConcertType.CONCERT && <ConcertForm form={form} />}
              {type === ConcertType.BALLET && <BalletForm form={form} />}
              {type === ConcertType.OPERA && <OperaForm form={form} />}
              {type === ConcertType.MUSICAL && <MusicalForm form={form} />}
            </>
          )}
        </form>
      </div>

      <BottomBar
        title="STEP 3. 무대공연"
        progress={`100%`}
        onClickNext={handleSubmit((data) =>
          submitData(data, 4).then(() =>
            push(`/create/${params.formId}/form-list`)
          )
        )}
        nextDisabled={concertLicense === undefined}
        onClickPrevious={() => {
          submitData(getValues(), 2).then(() =>
            push(`/create/${params.formId}/form/${params.scoreId}/type/score/2`)
          );
        }}
      />
    </>
  );
};

interface FormProps {
  form: UseFormReturn<FormValues>;
}

export const ConcertForm: FC<FormProps> = ({ form }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = form;
  const paid = watch('paid');

  return (
    <>
      <Section className="space-y-4">
        <TextField
          label="공연 전체 프로그램 저작권 보호 곡 수"
          placeholder="공연 전체 프로그램 저작권 보호 곡 수"
          helper={errors.copyrightCount?.message}
          {...register('copyrightCount', {
            required: ' 곡 수를 입력해주세요.',
          })}
        />
        <TextField
          label="전체 좌석수 
(티켓오픈 좌석수: 해당공연장의 전체 좌석수를 기재(해당공연장 홈페이지상의 대관정보에 명시된 좌석수 / 입장객수가 아닌 설치된 좌석수를 의미합니다. )"
          placeholder="전체 좌석수를 입력해주세요."
          helper={errors.seatsCount?.message}
          {...register('seatsCount', {
            required: '전체 좌석수를 입력해주세요.',
          })}
        />

        <RadioGroupUseForm
          className="font-bold flex items-center space-x-4"
          hookForm={form}
          name="paid"
          rules={{
            required: '유료 여부를 선택해주세요.',
          }}
        >
          <Radio label="무료공연" value={BooleanString.FALSE} />
          <Radio label="유료공연" value={BooleanString.TRUE} />
        </RadioGroupUseForm>

        {paid === BooleanString.TRUE && (
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-8">
              <TextField
                label="티켓판매가"
                placeholder="티켓판매가를 입력해주세요."
                helper={errors.price?.message}
                {...register('price', {
                  required: '티켓판매가를 입력해주세요.',
                })}
              />
              <TextField
                label="예상 티켓 판매율 %"
                placeholder="예상 티켓 판매율을 %로 입력해주세요."
                helper={errors.sales?.message}
                {...register('sales', {
                  required: '예상 티켓 판매율을 %로 입력해주세요.',
                })}
              />
            </div>

            <TextField
              label="특이사항"
              placeholder="특이사항을 입력해주세요."
              helper={errors.remarks?.message}
              {...register('remarks')}
            />

            <RadioGroupUseForm
              label="원곡을 그대로 사용함을 확인합니다. 개작 또는 편곡이 있을 경우, 편곡허가신청을 먼저 진행하여 주시기 바랍니다"
              labelClassname="font-bold text-18"
              className="flex items-center space-x-4"
              hookForm={form}
              name="checked"
              rules={{
                validate: (v: any) =>
                  paid !== BooleanString.TRUE ||
                  v !== undefined ||
                  '동의 혹은 비동의를 선택해주세요.',
              }}
            >
              <Radio label="동의" value={BooleanString.TRUE} />
              <Radio label="비동의" value={BooleanString.FALSE} />
            </RadioGroupUseForm>
          </div>
        )}
      </Section>
    </>
  );
};

export const OperaForm: FC<FormProps> = ({ form }) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <>
      <Section className="space-y-6">
        <TextField
          label="예술감독이 있을경우 이름을 입력해주세요. "
          placeholder="예술감독이 있을경우 이름을 입력해주세요."
          helper={errors.artDirector?.message}
          {...register('artDirector')}
        />
        <TextField
          label="Director"
          placeholder="Director를 입력해주세요."
          helper={errors.director?.message}
          {...register('director')}
        />
        <TextField
          label="Performance Description"
          placeholder="Performance Description를 입력해주세요."
          helper={errors.description?.message}
          {...register('description')}
        />
        <TextField
          label="실연 연주단체(지휘자명 포함)"
          placeholder="실연 연주단체(지휘자명 포함)를 입력해주세요."
          helper={errors.live?.message}
          {...register('live')}
        />
        <TextField
          label="Language / Translation 공연시 사용되는 언어"
          placeholder="(예 : 영어 / 한국어 자막제공)"
          helper={errors.language?.message}
          {...register('language', {
            required: '공연시 사용되는 언어를 입력해주세요.',
          })}
        />
        <TextArea
          label="Principal Cast"
          placeholder="주역캐스팅 정보를 입력해주세요."
          helper={errors.cast?.message}
          {...register('cast')}
        />
        <TextField
          label="Total duration of Performance (분)"
          placeholder="Total duration of Performance (분)를 입력해주세요."
          helper={errors.duration?.message}
          {...register('duration', {
            required: 'Total duration of Performance를 입력해주세요.',
          })}
        />
        <TextField
          label="Total duration of Performance (인터미션포함) (분)"
          placeholder="Total duration of Performance (인터미션포함) (분)를 입력해주세요."
          helper={errors.totalDuration?.message}
          {...register('totalDuration', {
            required: 'Total duration of Performance를 입력해주세요.',
          })}
        />
        <TextField
          label="Details of any other music used in the same Performance including full running order 
          (공연전체 프로그램과 사용되는 음악전체를 기입하여 주세요.)"
          placeholder="Details of other music을 기입하여 주세요."
          helper={errors.otherMusic?.message}
          {...register('otherMusic')}
        />
        <TextField
          label="공연 전체 프로그램 저작권 보호 곡 수"
          placeholder="공연 전체 프로그램 저작권 보호 곡 수를 입력해주세요."
          helper={errors.copyrightCount?.message}
          {...register('copyrightCount', {
            required: '공연 전체 프로그램 저작권 보호 곡 수를 입력해주세요.',
          })}
        />
        <TextArea
          label="기타사항"
          placeholder="기타사항을 입력해주세요."
          helper={errors.remarks?.message}
          {...register('remarks')}
        />

        <RadioGroupUseForm
          label="원곡을 그대로 사용함을 확인합니다. 개작 또는 편곡이 있을 경우, 편곡허가신청을 먼저 진행하여 주시기 바랍니다"
          labelClassname="font-bold text-18"
          className="flex items-center space-x-4"
          hookForm={form}
          name="noEdit"
          rules={{
            required: '동의 혹은 비동의를 선택해주세요.',
          }}
        >
          <Radio label="동의" value={BooleanString.TRUE} />
          <Radio label="비동의" value={BooleanString.FALSE} />
        </RadioGroupUseForm>
      </Section>
    </>
  );
};

export const BalletForm: FC<FormProps> = ({ form }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = form;
  const liveRecord = watch('liveRecord');

  return (
    <>
      <Section className="space-y-6">
        <TextField
          label="예술감독이 있을경우 이름을 입력해주세요. "
          placeholder="예술감독이 있을경우 이름을 입력해주세요."
          helper={errors.artDirector?.message}
          {...register('artDirector')}
        />
        <TextField
          label="Director"
          placeholder="Director를 입력해주세요."
          helper={errors.director?.message}
          {...register('director')}
        />
        <TextField
          label="Performance Description"
          placeholder="Performance Description를 입력해주세요."
          helper={errors.description?.message}
          {...register('description')}
        />
        <TextField
          label="The name of the choreographer"
          placeholder="The name of the choreographer를 입력해주세요."
          helper={errors.choreographer?.message}
          {...register('choreographer')}
        />
        <TextField
          label="Dance Theme/Synopsis"
          placeholder="Dance Theme/Synopsis를 입력해주세요."
          helper={errors.synopsis?.message}
          {...register('synopsis')}
        />
        <RadioGroupUseForm
          label="실연 또는 레코딩 음원사용"
          labelClassname="font-bold text-18"
          className="flex items-center space-x-4"
          hookForm={form}
          name="liveRecord"
          rules={{ required: '실연 또는 레코딩 음원을 선택해주세요.' }}
        >
          <Radio label="실연" value={LiveRecordType.LIVE} />
          <Radio label="레코딩 음원" value={LiveRecordType.RECORD} />
        </RadioGroupUseForm>
        {liveRecord === LiveRecordType.LIVE && (
          <TextField
            label="실연 연주단체(지휘자명 포함)"
            placeholder="실연 연주단체(지휘자명 포함)를 입력해주세요."
            helper={errors.live?.message}
            {...register('live')}
          />
        )}
        {liveRecord === LiveRecordType.RECORD && (
          <TextField
            label="레코딩 음원 정보(레이블, 연주자명 등)"
            placeholder="레코딩 음원 정보(레이블, 연주자명 등)를 입력해주세요."
            helper={errors.record?.message}
            {...register('record')}
          />
        )}
        <TextField
          label="Total duration of Performance (분)"
          placeholder="Total duration of Performance (분)를 입력해주세요."
          helper={errors.duration?.message}
          {...register('duration', {
            required: 'Total duration of Performance를 입력해주세요.',
          })}
        />
        <TextField
          label="Total duration of Performance (인터미션포함) (분)"
          placeholder="Total duration of Performance (인터미션포함) (분)를 입력해주세요."
          helper={errors.totalDuration?.message}
          {...register('totalDuration', {
            required: 'Total duration of Performance를 입력해주세요.',
          })}
        />
        <TextField
          label="Details of any other music used in the same Performance including full running order 
          (공연전체 프로그램과 사용되는 음악전체를 기입하여 주세요.)"
          placeholder="Details of other music을 기입하여 주세요."
          helper={errors.otherMusic?.message}
          {...register('otherMusic')}
        />
        <TextField
          label="공연 전체 프로그램 저작권 보호 곡 수"
          placeholder="공연 전체 프로그램 저작권 보호 곡 수를 입력해주세요."
          helper={errors.copyrightCount?.message}
          {...register('copyrightCount', {
            required: '공연 전체 프로그램 저작권 보호 곡 수를 입력해주세요.',
          })}
        />
        <TextField
          label="Information about Company (공식 사이트가 있을 경우 기입)"
          placeholder="Information about Company (공식 사이트가 있을 경우 기입)를 입력해주세요."
          helper={errors.company?.message}
          {...register('company')}
        />
        <TextArea
          label="기타사항"
          placeholder="기타사항을 입력해주세요."
          helper={errors.remarks?.message}
          {...register('remarks')}
        />

        <RadioGroupUseForm
          label="원곡을 그대로 사용함을 확인합니다. 개작 또는 편곡이 있을 경우, 편곡허가신청을 먼저 진행하여 주시기 바랍니다"
          labelClassname="font-bold text-18"
          className="flex items-center space-x-4"
          hookForm={form}
          name="noEdit"
          rules={{
            required: '동의 혹은 비동의를 선택해주세요.',
          }}
        >
          <Radio label="동의" value={BooleanString.TRUE} />
          <Radio label="비동의" value={BooleanString.FALSE} />
        </RadioGroupUseForm>
      </Section>
    </>
  );
};

export const MusicalForm: FC<FormProps> = ({ form }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = form;
  const liveRecord = watch('liveRecord');

  return (
    <>
      <Section className="space-y-6">
        <TextField
          label="공연 전체 프로그램 저작권 보호 곡 수"
          placeholder="공연 전체 프로그램 저작권 보호 곡 수를 입력해주세요."
          helper={errors.copyrightCount?.message}
          {...register('copyrightCount', {
            required: '공연 전체 프로그램 저작권 보호 곡 수를 입력해주세요.',
          })}
        />
        <TextField
          label="예술감독이 있을경우 이름을 입력해주세요. "
          placeholder="예술감독이 있을경우 이름을 입력해주세요."
          helper={errors.artDirector?.message}
          {...register('artDirector')}
        />
        <TextField
          label="Director"
          placeholder="Director를 입력해주세요."
          helper={errors.director?.message}
          {...register('director')}
        />
        <TextField
          label="Performance Description"
          placeholder="Performance Description를 입력해주세요."
          helper={errors.description?.message}
          {...register('description')}
        />
        <TextField
          label="Language / Translation 공연시 사용되는 언어"
          placeholder="(예 : 영어 / 한국어 자막제공)"
          helper={errors.language?.message}
          {...register('language', {
            required: '공연시 사용되는 언어를 입력해주세요.',
          })}
        />
        <TextArea
          label="Principal Cast"
          placeholder="주역캐스팅 정보를 입력해주세요."
          helper={errors.cast?.message}
          {...register('cast')}
        />
        <RadioGroupUseForm
          label="실연 또는 레코딩 음원사용"
          labelClassname="font-bold text-18"
          className="flex items-center space-x-4"
          hookForm={form}
          name="liveRecord"
          rules={{ required: '실연 또는 레코딩 음원을 선택해주세요.' }}
        >
          <Radio label="실연" value={LiveRecordType.LIVE} />
          <Radio label="레코딩 음원" value={LiveRecordType.RECORD} />
        </RadioGroupUseForm>
        {liveRecord === LiveRecordType.LIVE && (
          <TextField
            label="실연 연주단체(지휘자명 포함)"
            placeholder="실연 연주단체(지휘자명 포함)를 입력해주세요."
            helper={errors.live?.message}
            {...register('live')}
          />
        )}
        {liveRecord === LiveRecordType.RECORD && (
          <TextField
            label="레코딩 음원 정보(레이블, 연주자명 등)"
            placeholder="레코딩 음원 정보(레이블, 연주자명 등)를 입력해주세요."
            helper={errors.record?.message}
            {...register('record')}
          />
        )}
        <TextField
          label="Total duration of Performance (분)"
          placeholder="Total duration of Performance (분)를 입력해주세요."
          helper={errors.duration?.message}
          {...register('duration', {
            required: 'Total duration of Performance를 입력해주세요.',
          })}
        />
        <TextField
          label="Total duration of Performance (인터미션포함) (분)"
          placeholder="Total duration of Performance (인터미션포함) (분)를 입력해주세요."
          helper={errors.totalDuration?.message}
          {...register('totalDuration', {
            required: 'Total duration of Performance를 입력해주세요.',
          })}
        />
        <TextField
          label="Details of any other music used in the same Performance including full running order 
          (공연전체 프로그램과 사용되는 음악전체를 기입하여 주세요.)"
          placeholder="Details of other music을 기입하여 주세요."
          helper={errors.otherMusic?.message}
          {...register('otherMusic')}
        />
        <TextField
          label="Information about Company (공식 사이트가 있을 경우 기입)"
          placeholder="Information about Company (공식 사이트가 있을 경우 기입)를 입력해주세요."
          helper={errors.company?.message}
          {...register('company')}
        />
        <TextArea
          label="기타사항"
          placeholder="기타사항을 입력해주세요."
          helper={errors.remarks?.message}
          {...register('remarks')}
        />

        <RadioGroupUseForm
          label="원곡을 그대로 사용함을 확인합니다. 개작 또는 편곡이 있을 경우, 편곡허가신청을 먼저 진행하여 주시기 바랍니다"
          labelClassname="font-bold text-18"
          className="flex items-center space-x-4"
          hookForm={form}
          name="noEdit"
          rules={{
            required: '동의 혹은 비동의를 선택해주세요.',
          }}
        >
          <Radio label="동의" value={BooleanString.TRUE} />
          <Radio label="비동의" value={BooleanString.FALSE} />
        </RadioGroupUseForm>
      </Section>
    </>
  );
};
