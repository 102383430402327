import { Section } from '../components/Section';
import { Table } from '../components/Table';
import { Button } from '../components/Button';
import { range } from '../utils';
import { ReactComponent as MusicIcon } from '../assets/icons/icon-music.svg';
import { ButtonHTMLAttributes } from 'react';
import { Score } from '../types';

interface ScoreTableProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  scores: Score[];
  onChoose?: (score: Score) => void;
}

export const ScoreTable = ({
  scores,
  className = '',
  onChoose,
  ...props
}: ScoreTableProps) => {
  const tables = [];
  for (let i = 0; i < scores.length; i++) {
    if (i === 0 || scores[i - 1].composerNames !== scores[i].composerNames)
      tables.push([scores[i]]);
    else tables[tables.length - 1].push(scores[i]);
  }
  return (
    <div className={`flex flex-col ${className}`}>
      {tables.map((scoreList) => (
        <Section className="flex flex-col space-y-0">
          <div className="flex flex-col">
            {scoreList[0].composers.map((composer, idx) => (
              <div key={idx} className="pb-2 text-18 flex space-x-2">
                <span className="font-bold pl-4">{composer.name}</span>
              </div>
            ))}
          </div>
          <Table className="mt-0 table-fixed">
            <Table.Head>
              <Table.Row className="">
                <Table.Th className="pl-5 text-12 truncate font-normal text-gray-600">
                  곡 정보
                </Table.Th>
                <Table.Th className="text-12 truncate font-normal text-gray-600">
                  출판사
                </Table.Th>
                <Table.Th className="text-12 truncate font-normal text-gray-600">
                  작품길이
                </Table.Th>
                <Table.Th></Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {scoreList.map((item: Score) => (
                <Table.Row className="group">
                  <Table.Td className="w-2/3 pl-5 group-hover:bg-gray-100 rounded-l-md whitespace-normal break-words">
                    <div className="flex pb-2">
                      {range(item.popularity ? item.popularity : 0).map((i) => (
                        <MusicIcon
                          className="wh-3 text-gray-900 fill-current"
                          key={i}
                        />
                      ))}
                    </div>
                    <div className="flex space-x-3 items-center">
                      <p className="whitespace-pre">{item.title}</p>
                      <p className="text-12 text-gray-700">{item.subtitle}</p>
                    </div>
                    <p className="text-gray-600 text-12 pt-3 pb-1">
                      {item.info}
                    </p>
                    <p className="text-gray-600 text-12">{item.orch}</p>
                    <p className="text-gray-600 text-12">{item.premier}</p>
                  </Table.Td>

                  <Table.Td className="group-hover:bg-gray-100">
                    <p className="break-words whitespace-normal w-16 text-12">
                      {item.publisher}
                    </p>
                  </Table.Td>

                  <Table.Td className="text-12 group-hover:bg-gray-100">
                    {item.len}
                  </Table.Td>

                  <Table.Td className="pl-12 pr-5 group-hover:bg-gray-100 rounded-r-md">
                    <Button
                      {...props}
                      text="선택하기"
                      className="group-hover:bg-gray-800 text-12 bg-gray-400 text-white active:ring-4 ring-gray-500 ring-inset h-8"
                      onClick={() => onChoose?.(item)}
                    />
                  </Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Section>
      ))}
    </div>
  );
};
