import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import { useAuth } from '../hooks';
import { api } from '../plugins/axios';
import { Alert, alertTypeToString } from '../types';
import { Button } from './Button';
import { Dropdown } from './Dropdown';
import { H5 } from './H5';
import { Icon } from './Icon';

const auth = true;
export const TopNavBar = () => {
  const { push } = useHistory();
  const { authenticated, logout } = useAuth();
  const { pathname } = useLocation();
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const alertTimeToString = (data: string) => {
    const createdAt = new Date(data);
    const now = new Date();
    const diff = now.valueOf() - createdAt.valueOf();
    const m = Math.floor(diff / 1000 / 60);
    if (m < 60) return `${m}분 전`;
    const h = Math.floor(m / 60);
    if (h < 24) return `${h}시간 전`;
    const d = Math.floor(h / 24);
    return `${d}일 전`;
  };

  return (
    <div className="max-w-container flex justify-between items-center h-18">
      <Button to="/" className="px-0">
        <Logo />
      </Button>

      {auth ? (
        <>
          <div className="flex items-center space-x-4">
            {authenticated ? (
              <>
                <Button
                  to="/create/new/form-info"
                  text="+새로운 신청서 추가하기"
                  className="outlined-brand-1"
                />
                {pathname.startsWith('/form-status') ? (
                  <Button
                    onClick={() => {
                      push('/');
                      logout();
                    }}
                    text="로그아웃"
                    className="filled-brand-1"
                  />
                ) : (
                  <Button
                    to="/form-status"
                    text="신청정보 보러가기"
                    className="filled-brand-1"
                  />
                )}
                <Dropdown
                  onOpen={() =>
                    api.post('alerts/view').then(({ data }) => setAlerts(data))
                  }
                >
                  <Button>
                    <Icon.Bell />
                  </Button>
                  <Dropdown.View className="right-0 w-64">
                    <div className="flex justify-between p-4 ">
                      <H5>알림</H5>

                      <Icon.X className="cursor-pointer" />
                    </div>

                    {alerts.length > 0 ? (
                      alerts.map((alert) => (
                        <div
                          key={alert.id}
                          onClick={() => push(`/form/${alert?.form?.id}`)}
                          className="hover:bg-gray-100 text-14 flex flex-col p-4 cursor-pointer"
                        >
                          <span
                            className={
                              alert.checked ? 'text-gray-500' : 'text-black'
                            }
                          >
                            {alertTypeToString(alert.type)}
                          </span>
                          <span
                            className={
                              alert.checked ? 'text-gray-500' : 'text-black'
                            }
                          >
                            {alertTimeToString(alert.createdAt)}
                          </span>
                        </div>
                      ))
                    ) : (
                      <p className="p-4">도착한 알림이 없습니다.</p>
                    )}
                  </Dropdown.View>
                </Dropdown>
              </>
            ) : pathname.startsWith('/login') ||
              pathname.startsWith('/signup') ? (
              <></>
            ) : (
              <>
                <Button to="/login" text="로그인" className="filled-brand-1" />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <Button to="/login" text="로그인" className="filled-brand-1 px-8" />
        </>
      )}
    </div>
  );
};
