import React, { FC, HTMLAttributes } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from './Icon';

interface BackButtonProps extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  to?: string;
}

export const BackButton: FC<BackButtonProps> = ({
  label,
  to,
  className = '',
}) => {
  const { push, goBack } = useHistory();
  return (
    <>
      <div className="border-b pb-4 -mx-4 px-4 mb-4">
        <button
          onClick={() => (to ? push(to) : goBack())}
          className="flex space-x-2 items-center"
        >
          <Icon.ChevronLeft className="wh-5" />
          <span className={`text-14 ${className}`}>{label}</span>
        </button>
      </div>
    </>
  );
};
