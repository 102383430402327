import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import { Paginated, Score } from '../types';
import { FrontPagination } from './FrontPagination';
import { Icon } from './Icon';
import { ScoreTable } from './ScoreTable';
import { Search } from './Search';

interface SearchSongPopUpProps {
  open: boolean;
  onClose: () => void;
  onChoose?: (data: Score) => void;
}
export const SearchSongPopUp: FC<SearchSongPopUpProps> = ({
  open,
  onClose,
  onChoose,
}) => {
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');

  const { data } = useQuery<Paginated<Score>>(
    `scores/?page=${page}&keyword=${keyword}&popularity=11111`,
    fetcher
  );

  if (!open) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <div className="w-3/4 h-5/6 flex flex-col bg-white rounded-xl overflow-hidden py-6 space-y-4">
        <div className="flex space-x-4 px-6">
          <Search
            placeholder="원하는 곡을 검색해보세요"
            onSearch={setKeyword}
          />

          <button
            onClick={() => {
              setKeyword('');
              onClose();
            }}
          >
            <Icon.X />
          </button>
        </div>

        {data && data.total ? (
          <>
            <ScoreTable
              scores={data.items}
              className="flex-grow overflow-auto"
              onChoose={(score: Score) => {
                onChoose?.(score);
                setKeyword('');
                onClose();
              }}
            />
            <FrontPagination
              page={page}
              count={data ? Math.ceil(data.total / 10) : 1}
              onClick={setPage}
              className="px-6 pt-2"
            />
          </>
        ) : (
          <div className="flex-grow flex">
            <p className="justify-self-center self-center text-gray-600 w-full text-center">
              {keyword
                ? '일치하는 검색 결과가 없습니다'
                : '키워드를 입력해주세요'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
