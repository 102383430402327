import React, { FC, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useId } from 'react-id-generator';
import { useParams } from 'react-router-dom';
import { Radio } from '../../components/Radio';
import { RadioGroup } from '../../components/RadioGroup';
import { Tab } from '../../components/Tab';
import { api } from '../../plugins/axios';
import { Estimate, FormscoreType } from '../../types';
import { Pdf } from '../../components/Pdf';
import { useQuery } from 'react-query';
import { fetcher } from '../../plugins/react-query';

export interface EstimateSheetProps {
  estimates: Estimate[];
  refetch: () => Promise<any>;
  uploadable: boolean;
}

export const EstimateSheet: FC<EstimateSheetProps> = ({
  estimates,
  refetch,
  uploadable,
}) => {
  const params: any = useParams();
  const [tab, setTab] = useState(0);
  const [heavy, setHeavy] = useState(false);
  const [id] = useId(1, 'pdf');

  const tabs = useMemo(
    () => [
      { text: '대여', type: FormscoreType.RENTAL },
      { text: '판매', type: FormscoreType.SALE },
      { text: '라이센스', type: FormscoreType.LICENSING },
    ],
    []
  );

  const estimate = useMemo(
    () => estimates.find((estimate) => estimate.type === tabs[tab].type),
    [estimates, tabs, tab]
  );

  const { data: url } = useQuery<string>(
    `/s3/presignedUrl?key=${estimate?.signature ?? estimate?.key}`,
    fetcher,
    { enabled: estimate !== undefined }
  );

  useEffect(() => {
    if (estimate) setHeavy(estimate.heavy);
  }, [estimate]);

  const onUpload = async (file: File) => {
    const { data: post } = await api.get(
      `/s3/presignedPost?filename=${file!.name}`
    );
    const formData = new FormData();
    Object.entries(post.fields).forEach(([name, value]) =>
      formData.append(name, value as any)
    );
    formData.append('file', file!);

    await axios.post(post.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (estimate)
      await api.patch(`/admin/estimates/${estimate.id}`, {
        key: post.fields.key,
      });
    else
      await api.post(`/admin/estimates`, {
        key: post.fields.key,
        heavy,
        contract: null,
        formId: params.id,
        type: tabs[tab].type,
      });
    refetch();
  };

  return (
    <div>
      <div className="flex space-x-5 mb-5">
        {tabs.map(({ text }, idx) => (
          <Tab
            key={text}
            title={text}
            selected={tab === idx}
            onClick={() => setTab(idx)}
          />
        ))}
      </div>
      <div className="space-y-5">
        <div className="flex items-center space-x-3">
          <p className="text-lg font-semibold">견적서</p>
          {estimate?.contract ? (
            <div className="bg-blue-50 text-sm text-blue-600 px-3 py-1.5 rounded-full font-bold">
              계약서 동의
            </div>
          ) : estimate?.contract === false ? (
            <div className="bg-red-50 text-sm text-red-500 px-3 py-1.5 rounded-full font-bold">
              계약서 미동의
            </div>
          ) : (
            <></>
          )}
        </div>
        {uploadable && (
          <>
            <div className="flex flex-col space-y-2">
              <label
                className="outlined-black rounded-md h-10 px-4 text-sm flex items-center w-max cursor-pointer"
                htmlFor={id}
              >
                견적서 파일업로드
                <input
                  id={id}
                  type="file"
                  accept="application/pdf"
                  className="sr-only"
                  onChange={(e) => {
                    const file = e.target.files?.item(0);
                    if (!file || !e.target.validity.valid) return;
                    if (file.size >= 10485760)
                      alert('파일 크기가 너무 큽니다.');
                    onUpload(file);
                  }}
                />
              </label>
            </div>
            <div className="flex flex-col space-y-2">
              <label className="text-sm ">수기사인 여부</label>
              <RadioGroup className="flex space-x-6">
                <Radio
                  label="아니오"
                  checked={!heavy}
                  onChange={() => {
                    setHeavy(false);
                    if (estimate)
                      api.patch(`/admin/estimates/${estimate.id}`, {
                        heavy: false,
                      });
                  }}
                />
                <Radio
                  label="예"
                  checked={heavy}
                  onChange={() => {
                    setHeavy(true);
                    if (estimate)
                      api.patch(`/admin/estimates/${estimate.id}`, {
                        heavy: true,
                      });
                  }}
                />
              </RadioGroup>
            </div>
          </>
        )}
        {estimate && <Pdf file={url} />}
      </div>
    </div>
  );
};
