import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useParams } from 'react-router-dom';
import { FormTitle } from '../../components/FormTitle';
import { H5 } from '../../components/H5';
import { Radio } from '../../components/Radio';
import { RadioGroupUseForm } from '../../components/RadioGroupUseForm';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import {
  BooleanString,
  EditPurpose,
  Formscore,
  LicenseType,
} from '../../types';
import { LicenseBottomBar, useLicenseData } from './licenseComponents';

interface FormValues {
  editor: string;
  description: string;
  purpose: EditPurpose;
  purposeDescription: string;
  agree: BooleanString;
}

export const LicenseArrangementPage = () => {
  const params: any = useParams();
  const form = useForm<FormValues>();
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = form;
  const purpose = watch('purpose');

  const submitData = (data: any, savedPage: number) =>
    api.patch(`/formscores/${params.scoreId}`, {
      editInfo: JSON.stringify(data),
      savedPage,
    });

  const setValues = useCallback(
    (data: Formscore) => {
      const json = data.editInfo;
      if (json) {
        const obj = JSON.parse(json as any);
        const keys: (keyof FormValues)[] = [
          'editor',
          'description',
          'purpose',
          'purposeDescription',
          'agree',
        ];
        keys.forEach((key: keyof FormValues) => setValue(key, obj[key]));
      }
    },
    [setValue]
  );
  const { redirect, countId, licenseType } = useLicenseData(setValues);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <div className="max-w-container white-card mt-10 mb-20">
        <FormTitle count={countId} type="라이센스" />

        <form className="space-y-4">
          <H5 className="pb-6">편곡 정보를 입력해주세요!</H5>

          <TextField
            label="편곡자명"
            placeholder="편곡자명을 입력해주세요."
            helper={errors.editor?.message}
            {...register('editor', {
              required: '편곡자명을 입력해주세요.',
            })}
          />
          <TextField
            label="편곡에 관한 설명"
            placeholder="편곡에 관한 설명을 입력해주세요."
            helper={errors.description?.message}
            {...register('description')}
          />

          <RadioGroupUseForm
            label="편곡용도"
            labelClassname="font-bold"
            className="space-y-4"
            hookForm={form}
            name="purpose"
            rules={{ required: '편곡용도를 선택해주세요.' }}
          >
            <Radio label="공연용" value={EditPurpose.CONCERT} />
            <Radio label="출판용" value={EditPurpose.PUBLISH} />

            <Radio label="기타" value={EditPurpose.OTHER} />
            {purpose === EditPurpose.OTHER && (
              <TextArea
                placeholder="편곡용도를 입력해주세요."
                helper={errors.purposeDescription?.message}
                {...register('purposeDescription')}
              />
            )}
          </RadioGroupUseForm>

          <RadioGroupUseForm
            label="편곡은 저작인격권에 직결되어, 승인절차가 까다롭고 시간이 많이 소요됩니다. 또한 신청하더라도, 저작자가 동의하지 않으면 편곡이 허가되지 않을 수 있습니다. 동의하십니까? (비동의시 신청불가)"
            labelClassname="font-bold text-18"
            className="flex items-center space-x-4"
            hookForm={form}
            name="agree"
            rules={{
              validate: (v: any) =>
                v === BooleanString.TRUE || '비동의시 신청하실 수 없습니다.',
            }}
          >
            <Radio label="동의" value={BooleanString.TRUE} />
            <Radio label="비동의" value={BooleanString.FALSE} />
          </RadioGroupUseForm>
        </form>
      </div>

      <LicenseBottomBar
        form={form}
        type={LicenseType.ARRANGE}
        licenseType={licenseType}
        title="편곡"
        submitData={submitData}
      />
    </>
  );
};
