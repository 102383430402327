import React from 'react';
import { Button } from '../components/Button';
import { H2 } from '../components/H2';
import { H5 } from '../components/H5';

export const SignupSuccessPage = () => {
  return (
    <div className="bg-gray-100 h-screen-5 pt-20">
      <div className="bg-white max-w-container rounded-md">
        <div className="max-w-slim  text-center space-y-8 py-20 ">
          <H2 className="">회원신청이 완료되었습니다!</H2>

          <div className="font-normal">
            관리자가 확인 후 입력한 핸드폰 번호로 <br />
            가입여부를 안내해드리겠습니다.
          </div>

          <Button
            to="/"
            text="메인으로 돌아가기"
            className="filled-gray-800 px-8"
          />
        </div>
      </div>
    </div>
  );
};
