import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { PhoneNumber } from '../../../components/PhoneNumber';
import { TextField } from '../../../components/TextField';
import { api } from '../../../plugins/axios';
import { fetcher } from '../../../plugins/react-query';
import { User } from '../../../types';
import { AdminH1 } from '../../components/AdminH1';

interface FormValues {
  name: string;
  organization: string;
  phone: string;
  account: string;
}

export const UserEdit = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { phone: '--' } });
  const { data, isFetched } = useQuery<User>(`/admin/users/${id}`, fetcher);

  const phone = watch('phone');
  useEffect(() => {
    register('phone', {
      pattern: {
        value: /\d{3}-\d{4}-\d{4}/,
        message: '올바른 형식을 입력해주세요',
      },
    });
  }, [register]);
  const setPhone = useCallback(
    (v: string) => setValue('phone', v, { shouldValidate: true }),
    [setValue]
  );

  useEffect(() => {
    if (isFetched && data) {
      const keys: (keyof FormValues)[] = [
        'name',
        'organization',
        'phone',
        'account',
      ];
      keys.forEach((key) => setValue(key, data[key]));
    }
  }, [setValue, data, isFetched]);

  return (
    <div className="max-w-screen-md">
      <AdminH1>회원정보</AdminH1>

      <Card>
        <form className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:px-8">
          <TextField
            label="회원명"
            helper={errors.name?.message}
            {...register('name', { required: '이름을 입력해주세요' })}
          />
          <TextField
            label="소속 단체명 / 학교명"
            helper={errors.organization?.message}
            {...register('organization', {
              required: '소속 단체명 / 학교명을 입력해주세요',
            })}
          />
          <PhoneNumber
            label="연락처"
            value={phone}
            setValue={setPhone}
            helper={errors.phone?.message}
          />
          <TextField
            label="계정"
            type="account"
            helper={errors.account?.message}
            {...register('account', { required: '계정을 입력해주세요' })}
          />
        </form>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="취소하기"
            to={`/admin/users`}
            className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
          />
          <Button
            text="저장"
            className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
            onClick={handleSubmit(async (data) => {
              await api.patch(`/admin/users/${id}`, data);
              push(`/admin/users`);
            })}
          />
        </div>
      </Card>
    </div>
  );
};
