import React, { FC } from 'react';
import { H5 } from './H5';

interface FormTitleProps {
  count?: number;
  type: string;
}

export const FormTitle: FC<FormTitleProps> = ({ count, type }) => {
  return (
    <>
      <H5 className="text-14 pb-8">
        NO.{count} 신청 / <span className="text-brand-1">{type}</span>
      </H5>
    </>
  );
};
