import React, { useCallback, useEffect } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { Redirect, useParams } from 'react-router-dom';
import { CheckboxUseForm } from '../../components/CheckboxUseForm';
import { FormTitle } from '../../components/FormTitle';
import { H5 } from '../../components/H5';
import { Label } from '../../components/Label';
import { Radio } from '../../components/Radio';
import { RadioGroupUseForm } from '../../components/RadioGroupUseForm';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import {
  BooleanString,
  Formscore,
  LicenseType,
  PublishType,
  publishTypeToString,
} from '../../types';
import { LicenseBottomBar, useLicenseData } from './licenseComponents';

interface FormValues {
  title: string;
  type: PublishType[];
  region: string;
  totalScores: string;
  copyrightCount: string;
  price: string;
  date: string;
  remarks: string;
  part: BooleanString;
  partDetail: string;
  noEdit: BooleanString;
}

export const LicensePublishPage = () => {
  const params: any = useParams();
  const form = useForm<FormValues>({ defaultValues: { type: [] } });
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = form;
  const part = watch('part');

  useEffect(() => {
    register('type', {
      validate: () => {
        const type = watch('type');
        return type.length > 0 || '발매형태를 입력해주세요.';
      },
    });
  }, [register, watch]);

  const submitData = (data: any, savedPage: number) =>
    api.patch(`/formscores/${params.scoreId}`, {
      publishInfo: JSON.stringify(data),
      savedPage,
    });

  const setValues = useCallback(
    (data: Formscore) => {
      const json = data.publishInfo;
      if (json) {
        const obj = JSON.parse(json as any);
        const keys: (keyof FormValues)[] = [
          'title',
          'region',
          'totalScores',
          'copyrightCount',
          'price',
          'date',
          'remarks',
          'part',
          'partDetail',
          'noEdit',
        ];
        keys.forEach((key: keyof FormValues) => setValue(key, obj[key]));
        setValue('type', obj.type ?? []);
      }
    },
    [setValue]
  );
  const { redirect, countId, licenseType } = useLicenseData(setValues);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <div className="max-w-container white-card mt-10 mb-20">
        <FormTitle count={countId} type="라이센스" />

        <form className="space-y-6">
          <H5 className="pb-6">출판정보를 입력해주세요!</H5>

          <TextField
            label="도서명"
            placeholder="도서명을 입력해주세요."
            helper={errors.title?.message}
            {...register('title', {
              required: '도서명을 입력해주세요.',
            })}
          />

          <div className="space-y-2">
            <Label text="발매형태 (택 1 또는 중복 선택가능)" />
            <div className="flex flex-wrap space-x-8">
              {[PublishType.BOOK, PublishType.ONLINE].map((v) => (
                <CheckboxUseForm
                  key={v}
                  label={publishTypeToString(v)}
                  value={v}
                  hookForm={form}
                  name="type"
                />
              ))}
            </div>
            {errors.type && (
              <p className="text-sm text-error">
                {(errors.type as unknown as FieldErrors).message}
              </p>
            )}
          </div>

          <TextField
            label="발매영역 (대한민국, 미국, 또는 전세계 등 영역기입, 온라인일 경우 전세계로 간주)"
            placeholder="발매영역을 입력해주세요."
            helper={errors.region?.message}
            {...register('region', {
              required: '발매영역을 입력해주세요.',
            })}
          />

          <div className="grid grid-cols-2 gap-4">
            <TextField
              label="총 수록곡"
              placeholder="총 수록곡을 입력해주세요."
              helper={errors.totalScores?.message}
              {...register('totalScores', {
                required: '총 수록곡을 입력해주세요.',
              })}
            />
            <TextField
              label="총 수록곡 중 저작권 보호곡 수"
              placeholder="총 수록곡 중 저작권 보호곡 수를  입력해주세요."
              helper={errors.copyrightCount?.message}
              {...register('copyrightCount', {
                required: '총 수록곡 중 저작권 보호곡 수를  입력해주세요.',
              })}
            />
            <TextField
              label="정가"
              placeholder="정가를 입력해주세요."
              helper={errors.price?.message}
              {...register('price', {
                required: '정가를 입력해주세요.',
              })}
            />
            <TextField
              label="발행일"
              placeholder="발행일을 입력해주세요."
              helper={errors.date?.message}
              {...register('date', {
                required: '발행일을 입력해주세요.',
              })}
            />
          </div>

          <TextArea
            label="특이사항"
            placeholder="특이사항을 입력해주세요."
            helper={errors.remarks?.message}
            {...register('remarks')}
          />

          <RadioGroupUseForm
            label="곡 부분사용 여부"
            labelClassname="font-bold"
            className="space-y-2"
            hookForm={form}
            name="part"
            rules={{ required: '부분사용 여부를 선택해주세요.' }}
          >
            <Radio label="곡전체사용" value={BooleanString.FALSE} />
            <Radio label="일부사용" value={BooleanString.TRUE} />
            {part === BooleanString.TRUE && (
              <TextField
                placeholder="일부사용시 어떤부분인지, 몇마디인지 입력해주세요."
                helper={errors.partDetail?.message}
                {...register('partDetail')}
              />
            )}
          </RadioGroupUseForm>

          <RadioGroupUseForm
            label="원곡을 그대로 사용함을 확인합니다. 개작 또는 편곡이 있을 경우, 편곡허가신청을 먼저 진행하여 주시기 바랍니다."
            labelClassname="font-bold text-18"
            className="flex items-center space-x-4"
            hookForm={form}
            name="noEdit"
            rules={{ required: '동의 혹은 비동의를 선택해주세요.' }}
          >
            <Radio label="동의" value={BooleanString.TRUE} />
            <Radio label="비동의" value={BooleanString.FALSE} />
          </RadioGroupUseForm>
        </form>
      </div>

      <LicenseBottomBar
        form={form}
        type={LicenseType.PUBLISH}
        licenseType={licenseType}
        title="출판"
        submitData={submitData}
      />
    </>
  );
};
