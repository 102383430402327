import React, { FC, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Icon } from './Icon';
import { useWidth } from '../hooks';

export interface PdfProps {
  file: any;
  className?: string;
}

export const Pdf: FC<PdfProps> = ({ file, className = '' }) => {
  const [page, setPage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>();
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const width = useWidth(ref);

  useEffect(() => console.log(file), [file]);

  return (
    <div className={`flex flex-col items-center ${className}`} ref={ref}>
      <div style={{ width, height }} className="overflow-hidden">
        <Document
          file={file}
          className="w-full max-w-full"
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages);
            setPage(1);
          }}
        >
          <Page
            pageNumber={page}
            width={width}
            loading={<div style={{ width, height }}>Loading page...</div>}
            onLoadSuccess={(page) => {
              setHeight(page.height);
            }}
          />
        </Document>
      </div>

      <div className="flex space-x-2 items-center">
        <button
          className={`rounded-l py-1 px-1 ${
            page === 1 ? 'text-gray-300' : 'hover:bg-gray-100'
          }`}
          onClick={() =>
            setPage((page) =>
              page === undefined || page <= 1 ? page : page - 1
            )
          }
        >
          <Icon.ChevronLeft className="wh-6" />
        </button>
        <p className="text-center">
          {numPages ? `${page} / ${numPages}` : '-- / --'}
        </p>
        <button
          className={`rounded-r py-1 px-1 ${
            page === numPages ? 'text-gray-300' : 'hover:bg-gray-100'
          }`}
          onClick={() =>
            setPage((page) =>
              page === undefined || numPages === undefined || page >= numPages
                ? page
                : page + 1
            )
          }
        >
          <Icon.ChevronRight className="wh-6" />
        </button>
      </div>
    </div>
  );
};
