import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ChannelTokButton = () => {
  const { pathname } = useLocation();
  const channel = useCallback(() => {
    // @ts-ignore: Unreachable code error
    var w = window;
    // @ts-ignore: Unreachable code error
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})(
        'ChannelIO script included twice.'
      );
    }
    // @ts-ignore: Unreachable code error
    var ch = function () {
      // @ts-ignore: Unreachable code error
      ch.c(arguments);
    };
    // @ts-ignore: Unreachable code error
    ch.q = [];
    // @ts-ignore: Unreachable code error
    ch.c = function (args) {
      // @ts-ignore: Unreachable code error
      ch.q.push(args);
    };
    // @ts-ignore: Unreachable code error
    w.ChannelIO = ch;
    function l() {
      // @ts-ignore: Unreachable code error
      if (w.ChannelIOInitialized) {
        return;
      }
      // @ts-ignore: Unreachable code error
      w.ChannelIOInitialized = true;
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      var x = document.getElementsByTagName('script')[0];
      // @ts-ignore: Unreachable code error
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === 'complete') {
      l();
      // @ts-ignore: Unreachable code error
    } else if (window.attachEvent) {
      // @ts-ignore: Unreachable code error
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  }, []);
  const chan = useCallback(() => {
    // @ts-ignore: Unreachable code error
    ChannelIO('boot', {
      pluginKey: 'f6193cf4-c0de-4ade-9733-0072da20fb65',
    });
  }, []);

  useEffect(() => {
    channel();
    chan();
  }, [channel, chan]);

  const setDisplay = useCallback(() => {
    const el = document.getElementById('ch-plugin');
    if (el)
      el.style.display = pathname.startsWith('/create') ? 'none' : 'block';
  }, [pathname]);

  useEffect(setDisplay, [setDisplay]);

  useEffect(() => {
    const timeout = setTimeout(setDisplay, 100);
    return () => clearTimeout(timeout);
  }, [setDisplay]);

  return null;
};
