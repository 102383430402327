import React, { ButtonHTMLAttributes, FC } from 'react';

interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  selected: boolean;
  showNew?: boolean;
}

export const Tab: FC<TabProps> = ({ title, selected, showNew, ...props }) => {
  return (
    <button
      className={`pt-2 flex flex-col items-center space-y-1 ${
        selected ? '' : ''
      } `}
      {...props}
    >
      <div
        className={`flex items-center ${
          selected ? 'text-brand-1 font-semibold' : 'text-gray-400'
        }`}
      >
        <p>{title}</p>
        {showNew && (
          <div
            className={`ml-1 flex items-center justify-center text-white rounded-full wh-5 ${
              selected ? 'bg-brand-1' : 'bg-gray-400'
            }`}
          >
            <p className="text-sm">N</p>
          </div>
        )}
      </div>
      {selected ? (
        <div className="w-full px-4 border-2 border-brand-1" />
      ) : (
        <div className="" />
      )}
    </button>
  );
};
