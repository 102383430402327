import React, { FC, InputHTMLAttributes } from 'react';
import { useId } from 'react-id-generator';
import { Label } from './Label';
import { TextField } from './TextField';

export interface PhoneNumberProps
  extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  setValue?: (v: string) => void;
  label?: string;
  helper?: string;
  labelClassName?: string;
}

export const PhoneNumber: FC<PhoneNumberProps> = ({
  value,
  setValue,
  label,
  helper,
  labelClassName = '',
  ...props
}) => {
  const [id] = useId(1, 'phonenumber');

  return (
    <div className="label-col">
      {label && <Label htmlFor={id} text={label} className={labelClassName} />}
      <div className="grid grid-cols-3 gap-4">
        <TextField
          className={helper ? 'border-error' : ''}
          id={id}
          maxLength={3}
          placeholder="010"
          value={value.slice(0, value.indexOf('-'))}
          onChange={(e) =>
            setValue?.(`${e.target.value}${value.slice(value.indexOf('-'))}`)
          }
          {...props}
        />
        <TextField
          className={helper ? 'border-error' : ''}
          maxLength={4}
          placeholder="0000"
          value={value.slice(value.indexOf('-') + 1, value.lastIndexOf('-'))}
          onChange={(e) =>
            setValue?.(
              `${value.slice(0, value.indexOf('-'))}-${
                e.target.value
              }${value.slice(value.lastIndexOf('-'))}`
            )
          }
          {...props}
        />
        <TextField
          className={helper ? 'border-error' : ''}
          maxLength={4}
          placeholder="0000"
          value={value.slice(value.lastIndexOf('-') + 1)}
          onChange={(e) =>
            setValue?.(
              `${value.slice(0, value.lastIndexOf('-'))}-${e.target.value}`
            )
          }
          {...props}
        />
      </div>
      {helper && <p className="text-sm text-error">{helper}</p>}
    </div>
  );
};
