import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Redirect, useHistory, useParams } from 'react-router';
import { BottomBar } from '../../components/BottomBar';
import { Checkbox } from '../../components/Checkbox';
import { H4 } from '../../components/H4';
import { Label } from '../../components/Label';
import { PhoneNumber } from '../../components/PhoneNumber';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Form, FormState, User } from '../../types';

interface FormValues {
  organizationName: string;
  applicantName: string;
  applicantPhone: string;
}

export const FormInfoPage = () => {
  const { push } = useHistory();
  const params: any = useParams();
  const { data: userData } = useQuery<User>('users/me', fetcher);
  const {
    data: formData,
    isFetched,
    isError,
  } = useQuery<Form>(`forms/${params.id}`, fetcher, {
    enabled: params.id !== 'new',
  });
  const [same, setSame] = useState(false);
  const [agree, setAgree] = useState<boolean>(false);
  const [redirect, setRedirect] = useState('');
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { applicantPhone: '--' } });

  useEffect(() => {
    register('applicantPhone', {
      required: '핸드폰 번호를 입력해주세요.',
      pattern: /\d{3}-\d{4}-\d{4}/,
    });
  }, [register]);

  const orgFields = useMemo(() => {
    const { onChange, ...fields } = register('organizationName', {
      required: '연주 단체명을 입력해주세요.',
    });
    return {
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        setSame(false);
      },
      ...fields,
    };
  }, [register]);

  const phone = watch('applicantPhone');
  const setPhone = useCallback(
    (v: string) => setValue('applicantPhone', v, { shouldValidate: true }),
    [setValue]
  );

  const submit = (data: FormValues) => {
    return params.id === 'new'
      ? api.post('/forms', data)
      : api.patch(`/forms/${params.id}`, data);
  };

  useEffect(() => {
    if (isFetched && formData) {
      if (formData.state !== FormState.DRAFT)
        setRedirect(`/form/${formData.id}`);
      setValue('organizationName', formData?.organizationName);
      setValue('applicantName', formData?.applicantName);
      setValue('applicantPhone', formData?.applicantPhone);
      setAgree(true);
    }
  }, [isFetched, formData, setValue]);
  useEffect(() => {
    if (isError) setRedirect('/form-status');
  }, [isError]);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <div className="bg-gray-100 py-10 h-screen">
      <div className="max-w-container white-card mb-20">
        <form className="max-w-slim space-y-5 py-6">
          <H4> 신청자 정보를 입력해주세요!</H4>
          <TextField
            label="*회원명 (단체명)"
            className=""
            disabled
            value={userData ? userData.name : ''}
          />
          <div className="space-y-2">
            <TextField
              label="연주 단체명"
              className=""
              placeholder="연주 단체명을 입력해주세요."
              {...orgFields}
              helper={errors.organizationName?.message}
            />
            <Checkbox
              label="회원명과 동일합니다."
              className=""
              checked={same}
              onChange={(e) => {
                setSame(e.target.checked);
                if (e.target.checked) {
                  setValue('organizationName', userData?.name ?? '', {
                    shouldValidate: true,
                  });
                }
              }}
            />
          </div>

          <TextField
            label="*신청자명"
            placeholder="신청자명을 입력해주세요."
            className=""
            {...register('applicantName', {
              required: '신청자명을 입력해주세요.',
            })}
            helper={errors.applicantName?.message}
          />

          <PhoneNumber
            value={phone}
            setValue={setPhone}
            label="*신청자 핸드폰 번호"
            helper={
              errors.applicantPhone?.message === ''
                ? '올바른 형식을 입력해주세요'
                : errors.applicantPhone?.message
            }
          />

          <div className="space-y-2">
            <div className="flex space-x-2 items-center">
              <Checkbox
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
              />
              <Label>
                뮤직링크{' '}
                <span
                  onClick={() =>
                    window.open('/뮤직링크%20운영정책.pdf', '_blank')
                  }
                  className="font-bold underline cursor-pointer"
                >
                  이용약관
                </span>
                을 동의합니다.
              </Label>
            </div>
            <p className="text-red-500 text-12">
              *저작권 보호에 대한 유효기간 및 출판사 방침에 따라 렌탈 형식으로만
              이용이 가능한 악보가 있으니 확인부탁드립니다.
            </p>
          </div>
        </form>
      </div>
      <BottomBar
        nextText={params.id === 'new' ? undefined : '저장하기'}
        onClickNext={handleSubmit((data) =>
          submit(data).then(({ data }) => push(`/create/${data.id}/form-list`))
        )}
        nextDisabled={!agree}
      />
    </div>
  );
};
