import React, { FC, ReactElement, useState } from 'react';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { H4 } from './H4';
import { Icon } from './Icon';

export interface PopUpProps {
  title: ReactElement | string;
  description?: ReactElement | string;
  button1: string;
  button2?: string;
  checkbox?: string;
  onClick1: () => void;
  onClick2?: () => void;
  onClose: () => void;
}

export const PopUp: FC<PopUpProps> = ({
  title,
  description,
  button1,
  button2,
  checkbox,
  onClick1,
  onClick2,
  onClose,
}) => {
  const [checked, setChecked] = useState(false);
  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <div className="flex flex-col w-96 h-72 rounded-xl p-6 bg-white relative items-center">
        <button className="absolute right-3 top-3" onClick={onClose}>
          <Icon.X />
        </button>
        <H4 className="px-2 text-center">{title}</H4>
        <p className="text-center mt-2 text-sm">{description}</p>
        <div className="flex-grow" />

        {checkbox && (
          <Checkbox
            label={checkbox}
            onChange={(e) => setChecked(e.target.checked)}
          />
        )}
        <Button
          className="filled-brand-1 w-full mt-2"
          disabled={checkbox !== undefined && !checked}
          onClick={onClick1}
        >
          {button1}
        </Button>
        {button2 && (
          <Button className="outlined-brand-1 w-full mt-2" onClick={onClick2}>
            {button2}
          </Button>
        )}
      </div>
    </div>
  );
};
