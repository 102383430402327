import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Search } from '../../../components/Search';
import { Table } from '../../../components/Table';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { fetcher } from '../../../plugins/react-query';
import { Paginated, Score } from '../../../types';

export const SheetList = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const page = Number(params.get('page') ?? '1');

  const [keyword, setKeyword] = useState('');
  const { data } = useQuery<Paginated<Score>>(
    `/scores?keyword=${keyword}&popularity=11111&page=${page}`,
    fetcher
  );
  const scores = data?.items ?? [];

  return (
    <>
      <div className="ml-auto w-72">
        <Search onSearch={setKeyword} />
      </div>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>작곡가</Table.Th>
              <Table.Th>출판사/editor 및 Arrange</Table.Th>
              <Table.Th>곡명</Table.Th>
              <Table.Th>작품길이</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {scores.map((score) => (
              <Table.Row key={score.id}>
                <Table.Td>{score.id}</Table.Td>
                <Table.Td>
                  {score.composers.map((composer) => (
                    <p key={composer.id}>{composer.name}</p>
                  ))}
                </Table.Td>
                <Table.Td>{score.publisher}</Table.Td>
                <Table.Td>{score.title}</Table.Td>
                <Table.Td>{score.len}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-blue-500"
                    to={`/admin/sheet/${score.id}/edit`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={scores.length} total={data?.total ?? 0} />
          <Pagination.Nav basePath="/admin/sheet" total={data?.total ?? 0} />
        </Pagination>
      </Card>
    </>
  );
};
