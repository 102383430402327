import React, { FC, useState } from 'react';
import {
  BooleanString,
  BroadcastType,
  ConcertType,
  EditPurpose,
  Formscore,
  FormscoreType,
  formscoreTypeToString,
  LicenseType,
  LiveRecordType,
  PublishType,
  publishTypeToString,
  StreamAmount,
  StreamEdit,
  StreamPeriod,
  streamPeriodToString,
  StreamRegion,
  streamRegionToString,
  StreamType,
} from '../types';
import { H5 } from './H5';
import { Tab } from './Tab';

const licenseTypeToString = (type: LicenseType) => {
  switch (type) {
    case LicenseType.INTERNET:
      return '인터넷 스트리밍';
    case LicenseType.STAGE:
      return '무대공연';
    case LicenseType.PAPER:
      return '논문 및 학술지';
    case LicenseType.PUBLISH:
      return '출판';
    case LicenseType.FILM:
      return '광고 및 영화';
    case LicenseType.ARRANGE:
      return '편곡';
    case LicenseType.OTHER:
      return '기타';
  }
};

const getTableData = (tab: string, formscore: Formscore) => {
  const basicInfo = [
    ['작곡가', formscore.composer],
    ['곡명', formscore.title],
    ['출판사 및 권리사 / Editor 및 Arranger', formscore.publisher],
    ['Remarks', formscore.remarks],
    ['작품 길이', formscore.length],
  ];

  if (tab === '공연정보') {
    const concertInfo = JSON.parse(formscore.concertInfo as any);
    const table = basicInfo.concat([
      ['악보신청 형태', formscoreTypeToString(formscore.type)],
      [
        '공연일자',
        concertInfo.dates.map((v: string) => v.replaceAll('-', '/')).join(', '),
      ],
      ['공연장소', concertInfo.place],
      ['지휘자/협연자 정보', concertInfo.conductor],
      ['요청사항', concertInfo.request],
      ['필요한 악보 개수', concertInfo.neededCount],
      ['공연 횟수', concertInfo.count],
    ]);
    if (concertInfo.mediaPlan) {
      table.push(
        ['녹화', concertInfo.record === BooleanString.TRUE ? '사용' : '미사용'],
        [
          'TV방영',
          concertInfo.tvType === BroadcastType.NONE
            ? '미사용'
            : `${
                concertInfo.tvType === BroadcastType.LIVE
                  ? '생방송'
                  : '녹화방송'
              } (${concertInfo.tvChannel})`,
        ],
        [
          '라디오',
          concertInfo.radioType === BroadcastType.NONE
            ? '미사용'
            : `${
                concertInfo.radioType === BroadcastType.LIVE
                  ? '생방송'
                  : '녹화방송'
              } (${concertInfo.radioChannel})`,
        ],
        [
          '온라인 송출',
          concertInfo.streamType === StreamType.NONE
            ? '미사용'
            : `${concertInfo.streamChannel} / ${
                concertInfo.streamChannel === StreamType.LIVE
                  ? '라이브스트리밍'
                  : '다시보기'
              }`,
        ],
        [
          '새악보 유/무',
          concertInfo.newSheet === BooleanString.TRUE ? '필요함' : '필요없음',
        ],
        [
          'Express배송',
          concertInfo.overseas === BooleanString.TRUE ? '예' : '아니오',
        ]
      );
    }
    return table;
  }

  if (tab === '무대공연') {
    const concertLicenseInfo = JSON.parse(formscore.concertLicenseInfo as any);
    const table = formscore.type === FormscoreType.LICENSING ? basicInfo : [];
    table.push(
      ['무대공연타입', concertLicenseInfo.type],
      ['공연 전체 저작권 보호 곡 수', concertLicenseInfo.copyrightCount]
    );
    if (concertLicenseInfo.type === ConcertType.CONCERT) {
      table.push(['전체 좌석수', concertLicenseInfo.seatsCount]);
      if (concertLicenseInfo.paid === BooleanString.TRUE)
        table.push(
          ['공연형태', '유료공연'],
          ['티켓판매가', concertLicenseInfo.price],
          ['예상 티켓 판매율 %', concertLicenseInfo.sales]
        );
      else table.push(['공연 형태', '무료공연']);
    } else {
      table.push(
        ['예술감독', concertLicenseInfo.artDirector],
        ['Director', concertLicenseInfo.director],
        ['Performance Description', concertLicenseInfo.description]
      );
      if (concertLicenseInfo.type === ConcertType.BALLET) {
        table.push(
          ['The name of choreographer', concertLicenseInfo.choreographer],
          ['Dance Theme, Synopsis', concertLicenseInfo.synopsis]
        );
      } else
        table.push(
          ['Language, Translation', concertLicenseInfo.language],
          ['Principal Cast', concertLicenseInfo.cast]
        );
      if (concertLicenseInfo.type === ConcertType.OPERA) {
        table.push(['실연 연주단체', concertLicenseInfo.live]);
      } else {
        if (concertLicenseInfo.liveRecord === LiveRecordType.LIVE)
          table.push(['공연형태', `실연 (${concertLicenseInfo.live})`]);
        else
          table.push([
            '공연형태',
            `레코딩 음원 (${concertLicenseInfo.record})`,
          ]);
      }
      table.push(
        [
          `Total duraion of ${
            concertLicenseInfo.type === ConcertType.BALLET
              ? 'Dance'
              : 'Performance'
          } (분)`,
          concertLicenseInfo.duration,
        ],
        [
          `Total duraion of ${
            concertLicenseInfo.type === ConcertType.BALLET
              ? 'Dance'
              : 'Performance'
          } (인터미션포함) (분)`,
          concertLicenseInfo.totalDuration,
        ],
        ['Information about Company', concertLicenseInfo.company],
        ['Details of any other music used', concertLicenseInfo.otherMusic]
      );
    }
    if (concertLicenseInfo.type === ConcertType.CONCERT)
      table.push(['특이사항', concertLicenseInfo.remarks]);
    else if (concertLicenseInfo.type === ConcertType.BALLET)
      table.push(['기타참고 사항', concertLicenseInfo.remarks]);
    else table.push(['기타사항', concertLicenseInfo.remarks]);
    if (
      concertLicenseInfo.type !== ConcertType.CONCERT ||
      concertLicenseInfo.paid === BooleanString.TRUE
    )
      table.push([
        '유의사항',
        concertLicenseInfo.noEdit === BooleanString.TRUE ? '동의' : '비동의',
      ]);
    return table;
  }

  if (tab === '악보정보') {
    return basicInfo.concat([
      ['악보신청 형태', formscoreTypeToString(formscore.type)],
    ]);
  }

  if (tab === '인터넷 스트리밍') {
    const streamInfo = JSON.parse(formscore.streamInfo as any);
    return basicInfo.concat([
      [
        '송출지역',
        streamInfo.region
          .map((v: StreamRegion) => streamRegionToString(v))
          .join(' / '),
      ],
      ['사용매체', streamInfo.media],
      [
        '게재기간',
        streamInfo.period
          .map((v: StreamPeriod) => streamPeriodToString(v))
          .join(' / '),
      ],
      [
        '사용분량',
        streamInfo.amount
          .map((amount: StreamAmount) =>
            amount === StreamAmount.WHOLE
              ? '곡전체'
              : `일부사용 (${streamInfo.amountDetail})`
          )
          .join(' / '),
      ],
      [
        '편집여부',
        streamInfo.edit
          .map((edit: StreamEdit) =>
            edit === StreamEdit.NONE
              ? '영상편집 거의 없음'
              : edit === StreamEdit.SOME
              ? `영상편집 다소 포함 (${streamInfo.editSomeDetail})`
              : `영상편집 많음 (${streamInfo.editMuchDetail})`
          )
          .join(' / '),
      ],
      [
        '스트리밍 타입',
        streamInfo.paid === BooleanString.TRUE
          ? '무료스트리밍'
          : `유료스트리밍 (${streamInfo.paidMethod})`,
      ],
      ['추가사항', streamInfo.remarks],
      [
        '유의사항',
        streamInfo.noEdit === BooleanString.TRUE ? '동의' : '비동의',
      ],
    ]);
  }

  if (tab === '논문 및 학술지') {
    const paperInfo = JSON.parse(formscore.paperInfo as any);
    return basicInfo.concat([
      ['논문명', paperInfo.title],
      ['사용하고자하는 부분 (Excerpt Details)', paperInfo.detail],
      ['학교 또는 단체명', paperInfo.org],
      ['특이사항', paperInfo.remarks],
    ]);
  }

  if (tab === '출판') {
    const publishInfo = JSON.parse(formscore.publishInfo as any);
    return basicInfo.concat([
      ['도서명', publishInfo.title],
      [
        '발매형태',
        publishInfo.type
          .map((v: PublishType) => publishTypeToString(v))
          .join(' / '),
      ],
      ['발매영역', publishInfo.region],
      ['총 수록곡', publishInfo.totalScores],
      ['총 수록곡 중 저작권 보호곡 수', publishInfo.copyrightCount],
      ['정가', publishInfo.price],
      ['발행일', publishInfo.date],
      ['특이사항', publishInfo.remarks],
      [
        '사용 부분',
        publishInfo.part === BooleanString.TRUE
          ? `일부사용 (${publishInfo.partDetail})`
          : '곡 전체사용',
      ],
      [
        '유의사항',
        publishInfo.noEdit === BooleanString.TRUE ? '동의' : '비동의',
      ],
    ]);
  }

  if (tab === '광고 및 영화') {
    const filmInfo = JSON.parse(formscore.filmInfo as any);
    return basicInfo.concat([
      ['Film/Program title', filmInfo.title],
      ['Title of film or TV show', filmInfo.showTitle],
      ['Term', filmInfo.term],
      ['Territory', filmInfo.territory],
      ['Media', filmInfo.media],
      ['Synopsis/Description of use', filmInfo.synopsis],
      ['Duration', filmInfo.duration],
      ['기타사항', filmInfo.remarks],
    ]);
  }

  if (tab === '편곡') {
    const editInfo = JSON.parse(formscore.editInfo as any);
    return basicInfo.concat([
      ['편곡자명', editInfo.editor],
      ['편곡에 관한 설명', editInfo.description],
      [
        '편곡용도',
        editInfo.purpose === EditPurpose.CONCERT
          ? '공연용'
          : editInfo.purpose === EditPurpose.PUBLISH
          ? '출판용'
          : `기타 (${editInfo.purposeDescription})`,
      ],
    ]);
  }

  if (tab === '기타') {
    const otherInfo = JSON.parse(formscore.otherInfo as any);
    return basicInfo.concat([['기타사항', otherInfo.remarks]]);
  }

  throw new Error(`Unknown tab value ${tab}`);
};

export interface FormCardProps {
  formscore: Formscore;
}

export const FormCard: FC<FormCardProps> = ({ formscore }) => {
  let tabs;
  if (
    [FormscoreType.RENTAL, FormscoreType.RENTALSALE].includes(formscore.type)
  ) {
    tabs = ['공연정보'];
    if (formscore.concertLicense) tabs.push('무대공연');
  } else if (formscore.type === FormscoreType.SALE) tabs = ['악보정보'];
  else tabs = formscore.licenseType.map(licenseTypeToString);
  const [tab1, setTab1] = useState(tabs[0]);

  return (
    <div className="mt-8">
      <H5>No.{formscore.countId} 신청</H5>

      <div className="flex space-x-4 mb-4">
        {tabs.map((t) => (
          <Tab
            key={t}
            title={t}
            selected={t === tab1}
            onClick={() => setTab1(t)}
          />
        ))}
      </div>

      <div className="rounded-md overflow-hidden border border-gray-100">
        {getTableData(tab1, formscore).map(([col1, col2]) => (
          <div key={col1} className="flex border-b border-gray-100">
            <p className="w-48 bg-gray-100 p-3">{col1}</p>
            <p className="font-bold p-3">{col2}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
