import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useParams } from 'react-router-dom';
import { FormTitle } from '../../components/FormTitle';
import { H5 } from '../../components/H5';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { Formscore, LicenseType } from '../../types';
import { LicenseBottomBar, useLicenseData } from './licenseComponents';

interface FormValues {
  title: string;
  showTitle: string;
  term: string;
  territory: string;
  media: string;
  synopsis: string;
  duration: string;
  remarks: string;
}

export const LicenseMediaPage = () => {
  const params: any = useParams();
  const form = useForm<FormValues>();
  const {
    register,
    setValue,
    formState: { errors },
  } = form;

  const submitData = (data: any, savedPage: number) =>
    api.patch(`/formscores/${params.scoreId}`, {
      filmInfo: JSON.stringify(data),
      savedPage,
    });

  const setValues = useCallback(
    (data: Formscore) => {
      const json = data.filmInfo;
      if (json) {
        const obj = JSON.parse(json as any);
        const keys: (keyof FormValues)[] = [
          'title',
          'showTitle',
          'term',
          'territory',
          'media',
          'synopsis',
          'duration',
          'remarks',
        ];
        keys.forEach((key: keyof FormValues) => setValue(key, obj[key]));
      }
    },
    [setValue]
  );
  const { redirect, countId, licenseType } = useLicenseData(setValues);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <div className="max-w-container white-card mt-10 mb-20">
        <FormTitle count={countId} type="라이센스" />

        <form className="space-y-6">
          <H5 className="pb-6">
            광고 및 영화 등 미디어 사용정보를 입력해주세요!
          </H5>

          <TextField
            label="Film/Program Title"
            placeholder="Film/Program title을 입력해주세요."
            helper={errors.title?.message}
            {...register('title', {
              required: 'Film/Program title을 입력해주세요.',
            })}
          />

          <TextField
            label="Title of Film or TV Show"
            placeholder="Title of Film or TV Show을 입력해주세요."
            helper={errors.showTitle?.message}
            {...register('showTitle', {
              required: 'Title of Film or TV Show을 입력해주세요.',
            })}
          />
          <TextField
            label="Term"
            placeholder="Term을 입력해주세요."
            helper={errors.term?.message}
            {...register('term')}
          />
          <TextField
            label="Territory"
            placeholder="Territory을 입력해주세요."
            helper={errors.territory?.message}
            {...register('territory')}
          />
          <TextField
            label="Media"
            placeholder="Media을 입력해주세요."
            helper={errors.media?.message}
            {...register('media', {
              required: 'Media을 입력해주세요.',
            })}
          />
          <TextField
            label="Synopsis/Description of Use"
            placeholder="Synopsis/Description of Use을 입력해주세요."
            helper={errors.synopsis?.message}
            {...register('synopsis', {
              required: 'Synopsis/Description of Use을 입력해주세요.',
            })}
          />
          <TextField
            label="Duration"
            placeholder="Duration을 입력해주세요."
            helper={errors.duration?.message}
            {...register('duration', {
              required: 'Duration을 입력해주세요.',
            })}
          />

          <TextArea
            label="기타사항"
            placeholder="기타사항을 입력해주세요."
            helper={errors.remarks?.message}
            {...register('remarks')}
          />
        </form>
      </div>

      <LicenseBottomBar
        form={form}
        type={LicenseType.FILM}
        licenseType={licenseType}
        title="광고 및 영화 등 미디어 사용"
        submitData={submitData}
      />
    </>
  );
};
