import React, { FC, useState } from 'react';
import { H3 } from './H3';
import { Icon } from './Icon';
import { Section } from './Section';
import { Button } from './Button';
import { UploadPopUp } from './UploadPopUp';
import { Estimate, FormscoreType, User } from '../types';
import { QueryObserverResult, useQuery } from 'react-query';
import { fetcher, useQueriesConsistent } from '../plugins/react-query';
import { PopUp } from './PopUp';
import { addEnuNeun } from '../utils';
import { api } from '../plugins/axios';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { Pdf } from './Pdf';

const types = [
  { text: '대여용 악보 견적서', type: FormscoreType.RENTAL },
  { text: '판매용 악보 견적서', type: FormscoreType.SALE },
  { text: '라이센스 견적서', type: FormscoreType.LICENSING },
];

export const EstimateSheet = () => {
  const params: any = useParams();
  const { data: estimateData, refetch } = useQuery<Estimate[]>(
    `estimates/form/${params.id}`,
    fetcher
  );
  const estimates = estimateData ?? [];
  const urlData = useQueriesConsistent<string>(
    estimates.map(
      (estimate) => `/s3/presignedUrl?key=${estimate.signature ?? estimate.key}`
    )
  );
  const urls = urlData.map(({ data }) => data ?? '');

  const filterEstimate = (
    estimates: Estimate[],
    urls: string[],
    type: FormscoreType
  ) => {
    const e = [];
    const u = [];
    for (let i = 0; i < estimates.length; i++) {
      if (estimates[i].type === type) {
        e.push(estimates[i]);
        u.push(urls[i]);
      }
    }
    if (e.length > 1 && e[0].heavy) return { estimates: [e[1], e[0]], urls: u };
    return { estimates: e, urls: u };
  };

  return (
    <>
      <div className="grid place-content-end pt-6">
        <button
          className="flex items-center bg-gray-100 px-8 py-4 rounded-md space-x-2 mb-4"
          onClick={() => urls.forEach((url) => saveAs(url))}
        >
          <span>전체 견적서 PDF파일 다운</span> <Icon.Download />
        </button>
      </div>

      {types.map((type) => {
        const { estimates: filteredEstimates, urls: filteredUrls } =
          filterEstimate(estimates, urls, type.type);
        return estimates.length === 0
          ? null
          : [
              <H3 key={`${type.type} h3`} className="mt-6">
                {type.text}
              </H3>,
              ...filteredEstimates.map((estimate, idx) => (
                <EstimateCard
                  key={estimate.id}
                  estimate={estimate}
                  url={filteredUrls[idx]}
                  refetch={refetch}
                />
              )),
            ];
      })}
    </>
  );
};

interface EstimateCardProps {
  estimate: Estimate;
  url: string;
  refetch: () => Promise<QueryObserverResult<Estimate[], unknown>>;
}

export const EstimateCard: FC<EstimateCardProps> = ({
  estimate,
  url,
  refetch,
}) => {
  const [popup, setPopup] = useState('');
  const { data: user } = useQuery<User>('users/me', fetcher);

  return (
    <>
      <UploadPopUp
        estimateId={estimate.id}
        open={popup === 'upload'}
        onClose={() => setPopup('')}
        onDone={() => setPopup('done')}
        refetch={refetch}
      />

      {popup === 'no' && (
        <PopUp
          onClose={() => setPopup('')}
          title="정말로 계약하지 않으시겠어요?"
          description="계약하지 않기를 진행하실 경우 계약하기버튼이 비활성화 되며 다시 계약을 원하실 경우 다시 신청해주시길 바랍니다."
          button1="계약하지 않기"
          button2="이전으로"
          onClick1={async () => {
            await api.patch(`estimates/${estimate.id}`, { contract: false });
            refetch();
            setPopup('');
          }}
          onClick2={() => setPopup('')}
        />
      )}
      {popup === 'yes' && (
        <PopUp
          onClose={() => setPopup('')}
          title="신청서 계약을 진행하시겠습니까?"
          description={`${addEnuNeun(user?.name ?? '')} ${
            estimate.type === FormscoreType.LICENSING
              ? '상기 조건 내용을 충분히 이해하였고 동의함을 확인하며, 라이선스를 신청합니다.'
              : '상기 임대악보 공급조건 내용을 충분히 이해하였고 동의함을 확인하며, 상기 임대내역/출판사와 같은 악보임대를 신청합니다.'
          }`}
          button1="계약하기"
          button2="이전으로"
          checkbox="상기 내용에 동의합니다."
          onClick1={async () => {
            await api.patch(`estimates/${estimate.id}`, { contract: true });
            refetch();
            setPopup('done');
          }}
          onClick2={() => setPopup('')}
        />
      )}
      {popup === 'done' && (
        <PopUp
          onClose={() => setPopup('')}
          title="계약 신청이 완료되었습니다."
          description="관리자 확인 후 주문확인서가 전송 될 예정입니다."
          button1="확인"
          onClick1={() => setPopup('')}
        />
      )}

      <Section className="space-y-4 flex flex-col items-center mb-6">
        <Pdf file={url} className="w-full" />

        {estimate.heavy && estimate.contract === null && (
          <span className="text-14 text-red-500">
            *해당 견적서는 수기서명이 필요합니다. 파일을 다운로드 후 프린트하여
            수기서명 후 업로드해주시길 바랍니다.
          </span>
        )}

        <div className="flex space-x-2">
          {estimate.contract ? (
            <Button text="계약완료" disabled className="filled-gray-200 px-8" />
          ) : estimate.contract === false ? (
            <Button
              text="계약하지 않음"
              disabled
              className="filled-gray-200 px-8"
            />
          ) : (
            <>
              <Button
                text="계약하지않기"
                className="outlined-brand-1"
                onClick={() => setPopup('no')}
              />
              {estimate.heavy ? (
                <Button
                  onClick={() => setPopup('upload')}
                  text="서명한 견적서 업로드"
                  className="filled-gray-800"
                />
              ) : (
                <Button
                  onClick={() => setPopup('yes')}
                  text="계약하기"
                  className="filled-brand-1 px-8"
                />
              )}
            </>
          )}

          <button
            className="grid place-content-center bg-gray-100 rounded-md px-4 py-3"
            onClick={() => saveAs(url)}
          >
            <Icon.Download className="wh-4" />
          </button>
        </div>
      </Section>
    </>
  );
};
