import React from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { fetcher } from '../../../plugins/react-query';
import { Paginated, User } from '../../../types';

export const UserApplyList = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const page = Number(params.get('page') ?? '1');
  const limit = Number(params.get('limit') ?? '10');
  const { data } = useQuery<Paginated<User>>(
    `admin/users?page=${page}&limit=${limit}&filter[role]=REQUEST&sort[updatedAt]=DESC`,
    fetcher
  );

  return data ? (
    <>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>생성일</Table.Th>
              <Table.Th>회원명(단체명)</Table.Th>
              <Table.Th>계정</Table.Th>
              <Table.Th>연락처</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data!.items.map((user) => (
              <Table.Row key={user.id}>
                <Table.Td>{user.id}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    user.createdAt.toLocaleString(),
                    MomentFormat.YYYYMMDDHmm
                  )}
                </Table.Td>
                <Table.Td>{user.name}</Table.Td>
                <Table.Td>{user.account}</Table.Td>
                <Table.Td>{user.phone}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-blue-500"
                    to={`/admin/users/apply/${user.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data!.items.length} total={data!.total} />
          <Pagination.Nav basePath="/admin/users/apply" total={data!.total} />
        </Pagination>
      </Card>
    </>
  ) : (
    <></>
  );
};
