import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button';
import { H3 } from '../../components/H3';
import { PhoneNumber } from '../../components/PhoneNumber';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';

interface FormValues {
  password: string;
  passwordAgain: string;
  name: string;
  organization: string;
  phone: string;
}

export const MyInfoPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormValues>({ defaultValues: { phone: '--' } });
  const [account, setAccount] = useState('');
  const [password, phone] = watch(['password', 'phone']);

  useEffect(() => {
    const f = async () => {
      const { data } = await api.get('users/me');
      setAccount(data.account);
      setValue('name', data.name);
      setValue('phone', data.phone);
      setValue('organization', data.organization);
    };
    f();
  }, [setValue]);

  useEffect(() => {
    register('phone', {
      required: '핸드폰 번호를 입력해주세요.',
      pattern: /\d{3}-\d{4}-\d{4}/,
    });
  }, [register]);
  const setPhone = useCallback(
    (v: string) => {
      setValue('phone', v, { shouldValidate: true });
    },
    [setValue]
  );

  return (
    <>
      <div className="max-w-slim py-6">
        <H3 className="text-center pb-4">회원정보수정</H3>

        <form
          className="p-4 flex flex-col space-y-6"
          onSubmit={handleSubmit(async (data) =>
            api
              .patch('users/me', data)
              .then(() => alert('정보가 수정되었습니다.'))
          )}
        >
          <TextField disabled value={account} label="*아이디" />

          <TextField
            label="*회원명 (단체명)"
            placeholder="회원명 또는 단체명을 입력해주세요."
            helper={errors.name?.message}
            {...register('name', {
              required: '회원명 또는 단체명을 입력해주세요.',
            })}
          />

          <TextField
            type="password"
            label="*비밀번호"
            helper={errors.password?.message}
            placeholder="비밀번호를 입력해주세요."
            {...register('password')}
          />

          <TextField
            type="password"
            label="*비밀번호 재입력"
            helper={
              errors.passwordAgain?.message === ''
                ? '비밀번호가 일치하지 않습니다.'
                : ''
            }
            placeholder="비밀번호를 재입력해주세요."
            {...register('passwordAgain', {
              validate: (v) => v === password,
            })}
          />

          <TextField
            label="*소속 단체명 / 학교명"
            placeholder="소속 단체명 혹은 학교명을 입력해주세요."
            helper={errors.organization?.message}
            {...register('organization', {
              required: '소속 단체명 혹은 학교명을 입력해주세요.',
            })}
          />

          <PhoneNumber
            value={phone}
            setValue={setPhone}
            label="*신청자 핸드폰 번호"
            helper={
              errors.phone?.message === ''
                ? '올바른 형식을 입력해주세요'
                : errors.phone?.message
            }
          />

          <div className="flex flex-col pt-14">
            <Button type="submit" text="수정하기" className="filled-brand-1" />
          </div>
        </form>
      </div>
    </>
  );
};
