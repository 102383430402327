import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Button } from './Button';

interface BottomBarProps {
  nextPage?: string;
  previousPage?: string;
  nextDisabled?: boolean;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
  progress?: string;
  title?: string;
  previousText?: string;
  nextText?: string;
}

export const BottomBar: FC<BottomBarProps> = ({
  nextPage,
  previousPage,
  nextDisabled,
  onClickNext,
  onClickPrevious,
  progress,
  title,
  previousText = '저장 후 이전으로',
  nextText = '저장 후 다음으로',
}) => {
  const { push } = useHistory();
  return (
    <div className="fixed bottom-0 w-full border-t bg-white">
      <div className="flex justify-between items-center  max-w-container">
        <div className="flex flex-1 items-center space-x-3">
          {title && (
            <>
              <span className="text-13 font-bold">{title}</span>

              <div className="h-2 relative bg-gray-200 rounded-full w-52">
                <div
                  style={{
                    width: `${progress}`,
                  }}
                  className={` opacity-100 bg-brand-1 absolute z-10 h-2 rounded-full`}
                />
              </div>
            </>
          )}
        </div>

        <div className="flex space-x-2">
          {(onClickPrevious || previousPage) && (
            <Button
              onClick={() =>
                onClickPrevious ? onClickPrevious() : push(`${previousPage}`)
              }
              text={previousText}
              className="outlined-brand-1 rounded-full"
            />
          )}
          <Button
            onClick={() => (onClickNext ? onClickNext() : push(`${nextPage}`))}
            disabled={nextDisabled}
            text={nextText}
            className="filled-brand-1 rounded-full"
          />
        </div>
      </div>
    </div>
  );
};
