import React from 'react';

export const Footer = () => {
  return (
    <div className="bg-white p-4 py-10">
      <div className="flex justify-between items-center max-w-screen-xl mx-auto">
        <div>
          <h1 className="text-gray-500 text-18 font-medium pb-2">
            Edition Korea
          </h1>
          <p className="text-14 text-gray-400 leading-loose">
            (주)에디션코리아 / 대표자:박정은 / 사업자 번호: 658-47-00406 /
            이메일:music@editionkorea.com <br />
            고객센터 운영시간 10:00 - 17:00
          </p>
        </div>

        <p className="text-gray-400 text-14 pt-2">
          에디션 코리아 이용약관 개인정보처리방침
        </p>
      </div>
    </div>
  );
};
