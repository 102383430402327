import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Sidebar } from '../components/Sidebar';
import { AdminHeader } from './components/AdminHeader';
import { AdminMain } from './components/AdminMain';
import { FormDetail } from './pages/form/FormDetail';
import { FormList } from './pages/form/FormList';
import { SheetDetail } from './pages/SheetDB/SheetDetail';
import { SheetList } from './pages/SheetDB/SheetList';
import { UserAdd } from './pages/User/UserAdd';
import { UserApplyDetail } from './pages/User/UserApplyDetail';
import { UserEdit } from './pages/User/UserEdit';
import { UserMain } from './pages/User/UserMain';

export const Admin = () => (
  <div className="h-screen flex">
    <Sidebar>
      <Sidebar.Title>MusicLink</Sidebar.Title>
      <Sidebar.Menu>
        <Sidebar.Menu.Item text="회원관리" to="/admin/users" />
        <Sidebar.Menu.Item text="신청서관리" to="/admin/form" />
        <Sidebar.Menu.Item text="악보DB관리" to="/admin/sheet" />
      </Sidebar.Menu>
    </Sidebar>

    <div className="flex-1 flex flex-col min-w-0 overflow-hidden">
      <AdminHeader />
      <AdminMain>
        <Switch>
          <Route path="/admin/sheet/:id/edit" component={SheetDetail} />
          <Route path="/admin/sheet" component={SheetList} />
          <Route path="/admin/form/:id" component={FormDetail} />
          <Route path="/admin/form" component={FormList} />
          <Route path="/admin/users/apply/:id" component={UserApplyDetail} />
          <Route path="/admin/users/add" component={UserAdd} />
          <Route path="/admin/users/:id/edit" component={UserEdit} />
          <Route path="/admin/users" component={UserMain} />
          <Route path="/">
            <Redirect to="/admin/users" />
          </Route>
        </Switch>
      </AdminMain>
    </div>
  </div>
);
