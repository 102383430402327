import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Redirect } from 'react-router-dom';
import { H5 } from '../../components/H5';
import { Section } from '../../components/Section';
import { ReactComponent as AllIcon } from '../../assets/svg/menu/all.svg';
import { ReactComponent as AllIconActive } from '../../assets/svg/menu/all-active.svg';
import { ReactComponent as FileIcon } from '../../assets/svg/menu/file.svg';
import { ReactComponent as FileIconActive } from '../../assets/svg/menu/file-active.svg';
import { ReactComponent as CompleteIcon } from '../../assets/svg/menu/complete.svg';
import { ReactComponent as CompleteIconActive } from '../../assets/svg/menu/complete-active.svg';
import { ReactComponent as EditIcon } from '../../assets/svg/menu/edit.svg';
import { ReactComponent as EditIconActive } from '../../assets/svg/menu/edit-active.svg';
import { ReactComponent as BookMarkIcon } from '../../assets/svg/menu/bookmark.svg';
import { ReactComponent as BookMarkIconActive } from '../../assets/svg/menu/bookmark-active.svg';
import { Route, Switch, useLocation } from 'react-router';
import { Button } from '../../components/Button';
import { FormStatusCardList } from './FormStatusCardList';
import { Icon } from '../../components/Icon';
import { MyInfoPage } from './MyInfoPage';
import { Footer } from '../../components/Footer';
import { fetcher } from '../../plugins/react-query';
import { Form, FormState } from '../../types';

const sidemenu = [
  {
    icon: <AllIcon />,
    iconActive: <AllIconActive />,
    pathname: '/form-status/all',
    title: '전체',
    filter: (form: Form) => true,
  },
  {
    icon: <FileIcon />,
    iconActive: <FileIconActive />,
    pathname: '/form-status/in-progress',
    title: '진행중',
    filter: (form: Form) =>
      [FormState.BEFORE_CONTRACT, FormState.DURING_CONTRACT].includes(
        form.state
      ),
  },
  {
    icon: <CompleteIcon />,
    iconActive: <CompleteIconActive />,
    pathname: '/form-status/done',
    title: '완료',
    filter: (form: Form) => form.state === FormState.FINISHED,
  },
  {
    icon: <EditIcon />,
    iconActive: <EditIconActive />,
    pathname: '/form-status/edit',
    title: '수정',
    filter: (form: Form) => form.state === FormState.EDITTED,
  },
  {
    icon: <BookMarkIcon />,
    iconActive: <BookMarkIconActive />,
    pathname: '/form-status/draft',
    title: '임시저장',
    filter: (form: Form) => form.state === FormState.DRAFT,
  },
];

interface NewNumberProps {
  forms: Form[];
  filter: (form: Form) => boolean;
  isCurrentPath: boolean;
}

const NewNumber: FC<NewNumberProps> = ({ forms, filter, isCurrentPath }) => {
  const newCount = forms.filter(
    (form) => filter(form) && form.newCount > 0
  ).length;
  return newCount > 0 ? (
    <div
      className={`wh-5 rounded-full text-12 text-white font-normal grid place-content-center ${
        isCurrentPath ? 'bg-brand-1' : 'bg-gray-400'
      }`}
    >
      {newCount}
    </div>
  ) : (
    <></>
  );
};

export const FormStatusPage = () => {
  const { pathname } = useLocation();
  const { data } = useQuery<Form[]>('/forms', fetcher);
  return (
    <>
      <div className="bg-gray-100 min-h-screen-4.5 flex flex-col">
        <div className="max-w-container flex space-x-8">
          <Section className="white-card w-1/4 p-6 h-full">
            <div className="space-y-3">
              {sidemenu.map((menu) => (
                <Button
                  to={`${menu.pathname}`}
                  className="flex justify-between items-center px-0 h-10 w-full "
                  key={menu.pathname}
                >
                  <div className="flex space-x-3 items-center flex-1">
                    {pathname === menu.pathname ? (
                      <>{menu.iconActive}</>
                    ) : (
                      <>{menu.icon}</>
                    )}

                    <H5
                      className={`${
                        pathname === menu.pathname
                          ? 'text-brand-1 '
                          : 'text-gray-500 font-medium'
                      }`}
                    >
                      {menu.title}
                    </H5>
                  </div>
                  <NewNumber
                    forms={data ? data : []}
                    filter={menu.filter}
                    isCurrentPath={pathname === menu.pathname}
                  />
                </Button>
              ))}

              <div className="border-t pt-3">
                <Button
                  to="/form-status/my-info"
                  className="flex space-x-3 px-0 h-7"
                >
                  <Icon.User
                    className={`${
                      pathname === '/form-status/my-info'
                        ? 'text-brand-1'
                        : 'text-gray-500'
                    } stroke-current wh-6`}
                  />
                  <H5
                    className={`${
                      pathname === '/form-status/my-info'
                        ? 'text-brand-1'
                        : 'text-gray-500 font-medium'
                    }`}
                  >
                    내 정보
                  </H5>
                </Button>
              </div>
            </div>
          </Section>

          <Section className="white-card w-3/4 p-6">
            <Switch>
              <Route path="/form-status/my-info" component={MyInfoPage} />
              {sidemenu.map(({ pathname, filter }) => (
                <Route path={pathname} key={pathname}>
                  <FormStatusCardList data={data ? data.filter(filter) : []} />
                </Route>
              ))}
              <Redirect from="/" to="/form-status/all" />
            </Switch>
          </Section>
        </div>
        <div className="flex-grow" />
        <Footer />
      </div>
    </>
  );
};
