import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { BottomBar } from '../../components/BottomBar';
import { Button } from '../../components/Button';
import { FormTitle } from '../../components/FormTitle';
import { H5 } from '../../components/H5';
import { Label } from '../../components/Label';
import { Radio } from '../../components/Radio';
import { Section } from '../../components/Section';
import { Select } from '../../components/Select';
import { TextField } from '../../components/TextField';
import {
  BooleanString,
  BroadcastType,
  Formscore,
  FormState,
  StreamType,
} from '../../types';
import { range } from '../../utils';
import { api } from '../../plugins/axios';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { RadioGroupUseForm } from '../../components/RadioGroupUseForm';
import { useQuery } from 'react-query';
import { fetcher } from '../../plugins/react-query';

interface NestedDate {
  value: string;
}

interface FormValues {
  dates: NestedDate[];
  count: number;
  place: string;
  conductor: string;
  request: string;
  neededCount: string;
  mediaPlan: boolean;
  record: BooleanString;
  tvType: BroadcastType;
  tvChannel: string;
  radioType: BroadcastType;
  radioChannel: string;
  streamType: StreamType;
  streamChannel: string;
  newSheet: BooleanString;
  overseas: BooleanString;
}

export const ScoreTwoPage = () => {
  const { push } = useHistory();
  const params: any = useParams();
  const [redirect, setRedirect] = useState('');
  const [countId, setCountId] = useState<number>();
  const { data, isError } = useQuery<Formscore>(
    `formscores/${params.scoreId}`,
    fetcher
  );

  const form = useForm<FormValues>({
    defaultValues: {
      dates: [{ value: 'yyyy-mm-dd' }],
      count: 0,
    },
  });
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = form;

  const {
    fields: dates,
    append,
    remove,
  } = useFieldArray({
    name: 'dates',
    control,
  });

  const mediaPlan = watch('mediaPlan');
  useEffect(() => {
    register('mediaPlan', {
      validate: (v) => v !== undefined || '예 또는 아니요를 선택해주세요.',
    });
  }, [register]);

  const requiredIfMedia = (helper: string) => ({
    validate: (v: any) => !!v || !mediaPlan || helper,
  });

  useEffect(() => {
    if (data) {
      if (data.form.id !== +params.formId) setRedirect('/form-status');
      else if (data.form.state !== FormState.DRAFT)
        setRedirect(`/forms/${params.formId}`);
      else {
        setCountId(data.countId);
        const json = data.concertInfo;
        if (json) {
          const obj = JSON.parse(json as any);
          setValue(
            'dates',
            obj.dates?.map((s: string) => ({ value: s }))
          );
          const keys: (keyof FormValues)[] = [
            'count',
            'place',
            'conductor',
            'request',
            'neededCount',
            'mediaPlan',
            'record',
            'tvType',
            'tvChannel',
            'radioType',
            'radioChannel',
            'streamType',
            'streamChannel',
            'newSheet',
            'overseas',
          ];
          keys.forEach((key: keyof FormValues) => setValue(key, obj[key]));
        }
      }
    }
  }, [params, data, setValue]);

  useEffect(() => {
    if (isError) setRedirect('/form-status');
  }, [isError]);

  const submitData = (data: any, savedPage: number) =>
    api.patch(`/formscores/${params.scoreId}`, {
      concertInfo: JSON.stringify({
        ...data,
        dates: data.dates.map((v: any) => v.value),
      }),
      savedPage,
    });

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <div className="max-w-container white-card mt-10 mb-28">
        <FormTitle count={countId} type="악보" />

        <H5 className="pb-6">공연정보를 입력해주세요!</H5>

        <form className="space-y-4">
          <div className="grid grid-cols-4 gap-4">
            {dates?.map((date, idx) => (
              <div key={date.id} className="flex items-start space-x-3">
                <div className="flex flex-col space-y-2 ">
                  <Label text="공연일자" />

                  <div>
                    <input
                      className="border-gray-200 rounded-md w-full"
                      type="date"
                      id="start"
                      {...register(`dates.${idx}.value` as const, {
                        validate: (v) =>
                          /^\d{4}-\d{2}-\d{2}$/.test(v) ||
                          '올바른 일자를 입력해주세요.',
                      })}
                    />
                    <p className="text-sm text-error">
                      {errors.dates?.[idx]?.value?.message}
                    </p>
                  </div>
                </div>
                {dates.length > 1 && (
                  <button
                    type="button"
                    className="text-red-500 mt-9"
                    onClick={() => remove(idx)}
                  >
                    <p>x</p>
                  </button>
                )}
              </div>
            ))}
            <Button
              type="button"
              onClick={() => append({ value: 'yyyy-mm-dd' })}
              text="+ 공연날짜 추가하기"
              className="bg-gray-200 font-normal h-11 mt-7"
            />
          </div>

          <Select
            label="공연횟수"
            helper={errors.count?.message}
            {...register('count', {
              valueAsNumber: true,
              validate: (v) => v > 0 || '공연횟수를 선택해주세요.',
            })}
          >
            <option disabled hidden value="0">
              공연횟수를 선택해주세요.
            </option>

            {range(10, 1).map((i) => (
              <option key={i} value={i}>
                {i}회
              </option>
            ))}
          </Select>

          <TextField
            label="공연 장소"
            placeholder="공연장소를 입력해주세요."
            helper={errors.place?.message}
            {...register('place')}
          />
          <TextField
            label="지휘자 / 협연자정보"
            placeholder="지휘자 / 협연자 정보를 입력해주세요."
            helper={errors.conductor?.message}
            {...register('conductor')}
          />
          <TextField
            label="요청사항"
            placeholder="요청사항을 입력해주세요."
            helper={errors.request?.message}
            {...register('request')}
          />
          <TextField
            label="필요한 악보 개수"
            placeholder="필요한 악보 개수를 입력해주세요."
            helper={errors.neededCount?.message}
            {...register('neededCount', {
              required: '필요한 악보 개수를 입력해주세요.',
            })}
          />

          <div>
            <div className="flex space-x-4 items-center pt-6">
              <H5>
                기록용 녹화, TV방영 / 라디오 / 온라인 송출을 사용할 계획이
                있으신가요?
              </H5>

              <div className="space-x-3">
                <Button
                  type="button"
                  onClick={() =>
                    setValue('mediaPlan', true, { shouldValidate: true })
                  }
                  text="예"
                  className={`${
                    mediaPlan === true ? 'filled-gray-800' : 'bg-gray-100'
                  } `}
                />
                <Button
                  type="button"
                  onClick={() =>
                    setValue('mediaPlan', false, { shouldValidate: true })
                  }
                  text="아니요"
                  className={`${
                    mediaPlan === false ? 'filled-gray-800' : 'bg-gray-100'
                  } `}
                />
              </div>
            </div>
            <p className="text-sm text-error">{errors.mediaPlan?.message}</p>
          </div>

          {mediaPlan === true && (
            <Section className="space-y-7">
              <RadioGroupUseForm
                label="기록용 녹화"
                labelClassname="font-bold text-16"
                className="flex items-center space-x-4"
                hookForm={form}
                name="record"
                rules={requiredIfMedia('기록용 녹화 여부를 입력해주세요.')}
              >
                <Radio label="해당없음" value={BooleanString.FALSE} />
                <Radio label="있음" value={BooleanString.TRUE} />
              </RadioGroupUseForm>

              <div className="space-y-2">
                <RadioGroupUseForm
                  label="TV 방영"
                  labelClassname="font-bold text-16"
                  className="flex items-center space-x-4"
                  hookForm={form}
                  name="tvType"
                  rules={requiredIfMedia('TV 방영 계획을 선택해주세요.')}
                >
                  <Radio label="해당없음" value={BroadcastType.NONE} />
                  <Radio label="생방송" value={BroadcastType.LIVE} />
                  <Radio label="녹화방송" value={BroadcastType.RECORD} />
                </RadioGroupUseForm>
                <TextField
                  placeholder="채널명을 입력해주세요."
                  className=""
                  {...register('tvChannel')}
                />
              </div>

              <div className="space-y-2">
                <RadioGroupUseForm
                  label="라디오"
                  labelClassname="font-bold text-16"
                  className="flex items-center space-x-4"
                  hookForm={form}
                  name="radioType"
                  rules={requiredIfMedia('라디오 송출 계획을 선택해주세요.')}
                >
                  <Radio label="해당없음" value={BroadcastType.NONE} />
                  <Radio label="생방송" value={BroadcastType.LIVE} />
                  <Radio label="녹화방송" value={BroadcastType.RECORD} />
                </RadioGroupUseForm>
                <TextField
                  placeholder="채널명을 입력해주세요."
                  className=""
                  {...register('radioChannel')}
                />
              </div>

              <div className="space-y-2">
                <RadioGroupUseForm
                  label="온라인 송출"
                  labelClassname="font-bold text-16"
                  className="flex items-center space-x-4"
                  hookForm={form}
                  name="streamType"
                  rules={requiredIfMedia('온라인 송출 계획을 선택해주세요.')}
                >
                  <Radio label="해당없음" value={StreamType.NONE} />
                  <Radio label="라이브스트리밍" value={StreamType.LIVE} />
                  <Radio label="다시보기" value={StreamType.REPLAY} />
                </RadioGroupUseForm>
                <TextField
                  placeholder="채널명을 입력해주세요."
                  className=""
                  {...register('streamChannel')}
                />
              </div>

              <RadioGroupUseForm
                label="새악보가 필요하신가요? 추가금액이 발생할 수 있습니다."
                labelClassname="font-bold text-16 "
                className="flex items-center space-x-4"
                hookForm={form}
                name="newSheet"
                rules={requiredIfMedia('예 또는 아니요를 선택해주세요.')}
              >
                <Radio label="예" value={BooleanString.TRUE} />
                <Radio label="아니요" value={BooleanString.FALSE} />
              </RadioGroupUseForm>

              <RadioGroupUseForm
                label={`재고가 없을 시 해외현지에서 수급이 진행되며, 해외배송은 평균 10일~14일 이상 소요될 수 있습니다. 
이 경우 해외배송 진행을 하시겠습니까?`}
                labelClassname="font-bold text-16"
                className="flex items-center space-x-4"
                hookForm={form}
                name="overseas"
                rules={requiredIfMedia('예 또는 아니요를 선택해주세요.')}
              >
                <Radio label="예" value={BooleanString.TRUE} />
                <Radio label="아니요" value={BooleanString.FALSE} />
              </RadioGroupUseForm>
            </Section>
          )}
        </form>
      </div>

      <BottomBar
        title="STEP 2. 공연정보"
        progress={`66%`}
        onClickNext={handleSubmit((data) =>
          submitData(data, 3).then(() =>
            push(`/create/${params.formId}/form/${params.scoreId}/type/score/3`)
          )
        )}
        onClickPrevious={() => {
          submitData(getValues(), 1).then(() =>
            push(`/create/${params.formId}/form/${params.scoreId}/type/score/1`)
          );
        }}
      />
    </>
  );
};
