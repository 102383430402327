import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useParams } from 'react-router-dom';
import { FormTitle } from '../../components/FormTitle';
import { H5 } from '../../components/H5';
import { LicenseBottomBar, useLicenseData } from './licenseComponents';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { Formscore, LicenseType } from '../../types';

interface FormValues {
  title: string;
  detail: string;
  org: string;
  remarks: string;
}

export const LicenseThesisPage = () => {
  const params: any = useParams();
  const form = useForm<FormValues>();
  const {
    register,
    setValue,
    formState: { errors },
  } = form;

  const setValues = useCallback(
    (data: Formscore) => {
      const json = data.paperInfo;
      if (json) {
        const obj = JSON.parse(json as any);
        const keys: (keyof FormValues)[] = [
          'title',
          'detail',
          'org',
          'remarks',
        ];
        keys.forEach((key: keyof FormValues) => setValue(key, obj[key]));
      }
    },
    [setValue]
  );
  const { redirect, countId, licenseType } = useLicenseData(setValues);

  const submitData = (data: any, savedPage: number) =>
    api.patch(`/formscores/${params.scoreId}`, {
      paperInfo: JSON.stringify(data),
      savedPage,
    });

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <div className="max-w-container white-card mt-10 mb-20">
        <FormTitle count={countId} type="라이센스" />

        <form className="space-y-6">
          <H5 className="pb-6">논문 및 학술지를 입력해주세요!</H5>

          <TextField
            label="논문명"
            placeholder="논문명을 입력해주세요."
            helper={errors.title?.message}
            {...register('title', {
              required: '논문명을 입력해주세요.',
            })}
          />
          <TextField
            label="사용하고자하는 부분 (Excerpt Details)"
            placeholder="사용하고자하는 부분 (Excerpt Details)을 입력해주세요."
            helper={errors.detail?.message}
            {...register('detail')}
          />
          <TextField
            label="학교 또는 단체명  "
            placeholder="학교 또는 단체명을 입력해주세요."
            helper={errors.org?.message}
            {...register('org')}
          />

          <TextArea
            label="특이사항"
            placeholder="특이사항을 입력해주세요."
            helper={errors.remarks?.message}
            {...register('remarks')}
          />
        </form>
      </div>

      <LicenseBottomBar
        form={form}
        type={LicenseType.PAPER}
        licenseType={licenseType}
        title="논문 및 학술지"
        submitData={submitData}
      />
    </>
  );
};
