import React, { FC, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { UseFormReturn } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
  Formscore,
  FormState,
  LicenseType,
  licenseTypeToRoute,
} from '../../types';
import { BottomBar } from '../../components/BottomBar';
import { useQuery } from 'react-query';
import { fetcher } from '../../plugins/react-query';

interface LicenseBottomBarProps {
  form: UseFormReturn<any>;
  type: LicenseType;
  licenseType: LicenseType[];
  title: string;
  submitData: (data: any, savedPage: number) => Promise<AxiosResponse<any>>;
}

export const LicenseBottomBar: FC<LicenseBottomBarProps> = ({
  form: { handleSubmit, getValues },
  type,
  licenseType,
  title,
  submitData,
}) => {
  const { push } = useHistory();
  const params: any = useParams();

  const haveNext = licenseType.length === 2 && licenseType[0] === type;
  const havePrevious = licenseType.length === 2 && licenseType[1] === type;

  return (
    <BottomBar
      title={`STEP ${licenseType[0] === type ? 3 : 4}. ${title}`}
      progress={haveNext ? `66%` : `100%`}
      onClickNext={handleSubmit((data) =>
        haveNext
          ? submitData(data, 3).then(() =>
              push(
                `/create/${params.formId}/form/${
                  params.scoreId
                }/type/license/${licenseTypeToRoute(licenseType[1])}`
              )
            )
          : submitData(data, 4).then(() =>
              push(`/create/${params.formId}/form-list`)
            )
      )}
      onClickPrevious={() => {
        havePrevious
          ? submitData(getValues(), 2).then(() =>
              push(
                `/create/${params.formId}/form/${
                  params.scoreId
                }/type/license/${licenseTypeToRoute(licenseType[0])}`
              )
            )
          : submitData(getValues(), 1).then(() =>
              push(
                `/create/${params.formId}/form/${params.scoreId}/type/license`
              )
            );
      }}
    />
  );
};

export const useLicenseData = (setValues: (data: Formscore) => void) => {
  const params: any = useParams();
  const [redirect, setRedirect] = useState('');
  const [countId, setCountId] = useState<number>();
  const [licenseType, setLicenseType] = useState<LicenseType[]>([]);
  const { data, isError } = useQuery<Formscore>(
    `formscores/${params.scoreId}`,
    fetcher
  );

  useEffect(() => {
    if (data) {
      if (data.form.id !== +params.formId) setRedirect('/form-status');
      else if (data.form.state !== FormState.DRAFT)
        setRedirect(`/forms/${params.formId}`);
      else {
        setCountId(data.countId);
        setLicenseType(data.licenseType ?? []);
        setValues(data);
      }
    }
  }, [params.formId, data, setValues]);

  useEffect(() => {
    if (isError) setRedirect('/form-status');
  }, [isError]);

  return { redirect, countId, licenseType };
};
