import React, { FC, useMemo, useState } from 'react';
import axios from 'axios';
import { useId } from 'react-id-generator';
import { useParams } from 'react-router-dom';
import { Tab } from '../../components/Tab';
import { api } from '../../plugins/axios';
import { Confirmation, FormscoreType } from '../../types';
import { Pdf } from '../../components/Pdf';
import { useQuery } from 'react-query';
import { fetcher } from '../../plugins/react-query';

export interface ConfirmationSheetProps {
  confirmations: Confirmation[];
  refetch: () => Promise<any>;
  uploadable: boolean;
}

export const ConfirmationSheet: FC<ConfirmationSheetProps> = ({
  confirmations,
  refetch,
  uploadable,
}) => {
  const params: any = useParams();
  const [tab, setTab] = useState(0);
  const [id] = useId(1, 'pdf');

  const tabs = useMemo(
    () => [
      { text: '대여', type: FormscoreType.RENTAL },
      { text: '판매', type: FormscoreType.SALE },
      { text: '라이센스', type: FormscoreType.LICENSING },
    ],
    []
  );

  const confirmation = useMemo(
    () =>
      confirmations.find(
        (confirmation) => confirmation.type === tabs[tab].type
      ),
    [confirmations, tabs, tab]
  );

  const { data: url } = useQuery<string>(
    `/s3/presignedUrl?key=${confirmation?.key}`,
    fetcher,
    { enabled: confirmation !== undefined }
  );

  const onUpload = async (file: File) => {
    const { data: post } = await api.get(
      `/s3/presignedPost?filename=${file!.name}`
    );
    const formData = new FormData();
    Object.entries(post.fields).forEach(([name, value]) =>
      formData.append(name, value as any)
    );
    formData.append('file', file!);

    await axios.post(post.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (confirmation)
      await api.patch(`/admin/confirmations/${confirmation.id}`, {
        key: post.fields.key,
      });
    else
      await api.post(`/admin/confirmations`, {
        key: post.fields.key,
        formId: params.id,
        type: tabs[tab].type,
      });
    refetch();
  };

  return (
    <div>
      <div className="flex space-x-5 mb-5">
        {tabs.map(({ text }, idx) => (
          <Tab
            key={text}
            title={text}
            selected={tab === idx}
            onClick={() => setTab(idx)}
          />
        ))}
      </div>
      <div className="space-y-5">
        <div className="text-lg font-semibold">주문확인서</div>
        {uploadable && (
          <>
            <div className="flex flex-col space-y-2">
              <label
                className="outlined-black rounded-md h-10 px-4 text-sm flex items-center w-max cursor-pointer"
                htmlFor={id}
              >
                주문확인서 파일업로드
                <input
                  id={id}
                  type="file"
                  accept="application/pdf"
                  className="sr-only"
                  onChange={(e) => {
                    const file = e.target.files?.item(0);
                    if (!file || !e.target.validity.valid) return;
                    if (file.size >= 10485760)
                      alert('파일 크기가 너무 큽니다.');
                    onUpload(file);
                  }}
                />
              </label>
            </div>
          </>
        )}
        {confirmation && <Pdf file={url} />}
      </div>
    </div>
  );
};
