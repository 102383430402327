import React, { useRef, useState } from 'react';
import mainVideo1 from '../assets/video/main-video1.mp4';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';
import Main1A from '../assets/img/main-1a.png';
import Main1C from '../assets/img/main-1c.png';
import Main2 from '../assets/img/main-2.png';
import { Button } from '../components/Button';
import { Footer } from '../components/Footer';

export const HomePage = () => {
  const videos = [mainVideo1];
  const [videoIdx, setVideoIdx] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <>
      <div className="text-center">
        <div className="w-full h-96 md:h-screen-5 bg-center bg-cover bg-no-repeat relative">
          <video
            src={videos[videoIdx]}
            ref={videoRef}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            autoPlay
            muted
            onEnded={() => {
              setVideoIdx((idx) => (idx + 1) % videos.length);
              videoRef.current?.play();
            }}
          />
        </div>

        <Section className="py-16 space-y-6">
          <H1>
            Get permission to use music
            <br />
            쉽고 빠른 음악사용의 시작
          </H1>

          <p className="">
            악보, 유튜브, 출판, 스트리밍, 광고, 무대공연, 음악을 어떻게 사용하고
            싶으신가요?
            <br /> 아직 회원이 아니시라면 회원신청을 통해 뮤직링크의 다양한
            서비스를 이용해보세요!
          </p>
        </Section>

        <Section className="flex flex-col items-center py-16">
          <H1>무엇이 필요하신가요?</H1>

          <div className="flex space-x-44 mt-6">
            <div className="space-y-7">
              <img src={Main1A} alt="" className="" />
              <p className="font-bold">SHEET MUSIC & SCORES</p>
            </div>
            <div className="space-y-4">
              <img src={Main1C} alt="" className="" />
              <p className="font-bold ">COPYRIGHT & PERMISSIONS</p>
            </div>
          </div>
        </Section>

        <Section className="flex flex-col items-center py-16">
          <H1>손쉽게 신청하고 사용허가를 받아보세요.</H1>
          <p className="pt-6">
            신청서를 작성하면 사용허가 유무와 견적서를 보내드립니다.
          </p>
          <img src={Main2} alt="" className="pt-14 w-1/2" />
        </Section>

        <Section className="flex flex-col items-center py-16 space-y-12">
          <H1>지금 뮤직링크에서 원하는 음악을 신청해보세요!</H1>
          <Button
            to="/create/new/form-info"
            text="신청하러가기"
            className="filled-brand-1 px-8"
          />
        </Section>
      </div>

      <Footer />
    </>
  );
};
